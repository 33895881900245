import { Fragment, useEffect, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { ImageDto } from "../../api/rentMyApi";
import { useEnv } from "../../context/env.context";

interface DraggableImageItem {
  id: number | string;
}

interface MultiSelectImagePreviewProps {
  imagesPreviewUrls: ImageDto[];
  setImagePreviewUrls: (urls: ImageDto[]) => void;
  setSelectedImageIndex: (index: number) => void;
  selectedImageIndex: number;
  setUploadedImages: (images: ImageDto[]) => void; // Add this line to include the prop
}

const MultiSelectImagePreview = ({
  imagesPreviewUrls,
  setImagePreviewUrls,
  setSelectedImageIndex,
  setUploadedImages, // Add this line to receive the prop
}: MultiSelectImagePreviewProps) => {
  const { apiServerUrl } = useEnv();
  const [localImagePreviewUrls, setLocalImagePreviewUrls] = useState<
    ImageDto[]
  >([]);

  useEffect(() => {
    if (imagesPreviewUrls.length !== localImagePreviewUrls.length)
      setLocalImagePreviewUrls(imagesPreviewUrls);
  }, [localImagePreviewUrls, imagesPreviewUrls]);

  const deleteImage = (selectedItemId: string) => {
    const newImages = localImagePreviewUrls.filter(
      (item) => item.id !== selectedItemId
    );
    setLocalImagePreviewUrls(newImages);
    setImagePreviewUrls(newImages);
  };

  const moveImage = (dragId: string, hoverId: string) => {
    const dragImageIndex = localImagePreviewUrls.findIndex(
      (image) => image.id.toString() === dragId
    );
    const hoverImageIndex = localImagePreviewUrls.findIndex(
      (image) => image.id.toString() === hoverId
    );

    if (dragImageIndex === hoverImageIndex) {
      return;
    }

    const arr = [...localImagePreviewUrls];
    const f = arr.splice(dragImageIndex, 1)[0];
    arr.splice(hoverImageIndex, 0, f);

    setLocalImagePreviewUrls(arr);
    setImagePreviewUrls(arr);
    setUploadedImages(arr); // Add this line to update the uploadedImages state
  };

  interface ImagePreviewProps {
    element: ImageDto;
    isFirst: boolean;
    index: number;
  }
  const ImagePreview = ({ element, isFirst, index }: ImagePreviewProps) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: "image",
      item: { id: element.id },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }));

    const [, drop] = useDrop<DraggableImageItem>(() => ({
      accept: "image",
      drop: (item) => moveImage(item.id.toString(), element.id),
    }));

    return (
      <div
        ref={(node) => drag(drop(node))}
        id={element.id.toString()}
        className={isDragging ? "gallery dragging" : "gallery not-dragging"}
        onClick={() => setSelectedImageIndex(index)}
      >
        {isFirst && (
          <div className="gallery-cover-photo">
            <FontAwesomeIcon icon={faCamera} className="mr-2" />
            <span>Cover Photo</span>
          </div>
        )}
        <img
          className={"preview-image"}
          src={element.compressedPath}
          alt={element.name}
        />

        <div className="desc">
          <div className="image-order">
            <FontAwesomeIcon
              className="delete-icon close-icon height-22"
              onClick={() => deleteImage(element.id)}
              icon={faTimesCircle}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderPreview = () => {
    return (
      <Fragment>
        {localImagePreviewUrls.map((element, index) => (
          // eslint-disable-next-line react/prop-types
          <ImagePreview
            key={element.id}
            element={element}
            isFirst={index === 0}
            index={index}
          />
        ))}
      </Fragment>
    );
  };

  return <div className="gallery-wrapper">{renderPreview()}</div>;
};

export default MultiSelectImagePreview;
