import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { BookingClient, BookingStatus, IConfig, ImageDto } from '../../../api/rentMyApi';
import { Button, Modal } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import UploadProductImage from '../../item/UploadProductImage';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { isAfter } from 'date-fns';

interface HandoverProps {
  isOpen: boolean;
  onClose: () => void;
  isSharerView: boolean;
  bookingId: string;
  hasInsurance: boolean;
  endDate?: Date;
}

export const HandoverModal = ({ isOpen, onClose, isSharerView, bookingId, endDate }: HandoverProps) => {
  const [dataUri, setDataUri] = useState('');
  const [uploadedImages, setUploadedImages] = useState<ImageDto[]>([]);
  const [isProcessingImages, setIsProcessingImages] = useState(false);
  const [isProcessingConfirm, setIsProcessingConfirm] = useState(false);
  const [bookingClient, setBookingClient] = useState(new BookingClient(new IConfig('notoken')));
  const [isClientLoaded, setIsClientLoaded] = useState(false);
  const navigate = useNavigate();
  const enableInsurance = process.env.REACT_APP_SHOW_INSURANCE && /true/.test(process.env.REACT_APP_SHOW_INSURANCE);

  const { getAccessTokenSilently } = useAuth0();

  const bookingStatus = isSharerView ? BookingStatus.Completed : BookingStatus.Ongoing;

  useEffect(() => {
    async function initClient() {
      const token = await getAccessTokenSilently();

      const client = new BookingClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT);

      setBookingClient(client);
    }

    initClient().then(() => {
      setIsClientLoaded(true);
    });
  }, [getAccessTokenSilently]);

  const onModalClose = () => {
    setUploadedImages([]);
    setDataUri('');
    onClose();
  };

  const updateHandoverStatus = async () => {
    setIsProcessingConfirm(true);
    try {
      if (uploadedImages.length) {
        try {
          const imageIds = uploadedImages.map((image) => image.id);

          await bookingClient.addImages(bookingId, imageIds, isSharerView);
        } catch (e) {
          // console.log("Error uploading images")
          // console.log(e)
        }
      }
      await bookingClient.updateStatus(bookingId, bookingStatus);
      onModalClose();
      toast.success(isSharerView ? 'Drop-off has been confirmed' : 'Handover has been completed');
      window.location.reload();
    } catch (e) {
      toast.error('Something went wrong, please contact support');
    }
    setIsProcessingConfirm(false);
  };

  const renterDisclaimer = () => (
    <>
      <h3>Confirm the collection of your rental</h3>
      <ul>
        <li>Please upload pictures of your rental before using it</li>
        <li>
          If the item has any visible damage or is different to the description or listing photos, please upload close
          ups. Then press report
        </li>
      </ul>
      <p>By confirming you are happy to proceed with the rental</p>
    </>
  );

  const sharerDisclaimer = () => (
    <>
      <h3>Confirm the return of your item</h3>
      <ul>
        <li>Please upload pictures of your item to show its safe return</li>
        <li>
          In the unfortunate event your item is damaged please upload both close ups of damage and the item in full.
          Then press report
        </li>
      </ul>
      <p>By confirming you are happy to complete the rental</p>
    </>
  );

  const imageSelected = Boolean(dataUri);
  const bookingIsCompleted = bookingStatus === BookingStatus['Completed'];
  const endOfBooking = new Date(endDate || '');

  const bookingPeriodHasEnded = isAfter(new Date(), endOfBooking);

  return (
    <React.Fragment key={`${bookingStatus}-${imageSelected}`}>
      <Modal isOpen={isOpen} centered size="sm" toggle={onClose} className="modal-with-image">
        <button className="modal-with-image-close-button" onClick={onClose}>
          <FontAwesomeIcon icon={faXmark} />
        </button>
        <div className="container-fluid">
          <div className="row">
            <UploadProductImage
              isProcessing={isProcessingImages}
              uploadedImages={uploadedImages}
              setUploadedImages={setUploadedImages}
              setIsProcessing={setIsProcessingImages}
              newImagesOnly={true}
            />
          </div>
          <div className="row">
            <div className="col modal-with-image-div pl-3">
              <div className="pl-3">{isSharerView ? sharerDisclaimer() : renterDisclaimer()}</div>
              <div className="flex-display-row padding-top-10">
                {/*<Button color="secondary" onClick={onModalClose} className="modal-button">*/}
                {/*  Close*/}
                {/*</Button>*/}
                {enableInsurance && (bookingIsCompleted || bookingPeriodHasEnded) && isSharerView && (
                  <Button
                    color="primary"
                    onClick={() => {
                      navigate(`/insurance-claim/${bookingId}`);
                    }}
                    className="modal-button"
                  >
                    Make a Claim
                  </Button>
                )}
                <Button color="primary" onClick={updateHandoverStatus} className="modal-button">
                  {isProcessingConfirm ? <div className="loading-spinner-small"></div> : <>Confirm</>}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};
