import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { IConfig, UserClient } from "../../../api/rentMyApi";
import { toast } from "react-toastify";

export function EmailVerificationRequired() {
  const { user, getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function handleResize() {
    setIsMobile(window.innerWidth <= 768);
  }

  window.addEventListener("resize", handleResize);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth]);

  const sendVerificationLink = async () => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    const userClient = new UserClient(
      new IConfig(token),
      process.env.REACT_APP_API_ENDPOINT
    );

    try {
      await userClient.requestEmailVerification();
      toast.success("Email sent!");
    } catch (error) {
      toast.error("There was an error. Please try again later.");
    }
    setIsLoading(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "1.2rem",
        backgroundColor: "white",
        textAlign: "center",
        maxWidth: "540px",
        borderRadius: "8px",
        margin: "auto",
        marginTop: "3rem",
      }}
    >
      <Typography variant={"h5"} component="h1" marginBottom={"1rem"}>
        {t("email_verification")}
      </Typography>
      <Typography>{t("email_verification_almost_there")}</Typography>
      <Typography sx={{ fontWeight: 600, marginBottom: "1rem" }}>
        {user?.email}
      </Typography>
      <Typography>{t("email_verification_click_link")}</Typography>
      <Typography marginBottom={"2rem"}>
        {t("email_verification_check_spam")}
      </Typography>
      <Typography sx={{ fontSize: ".8rem", marginBottom: ".5rem" }}>
        {t("email_verification_no_email")}
      </Typography>
      <button
        className="btn btn-primary"
        disabled={isLoading}
        onClick={sendVerificationLink}
      >
        {isLoading ? (
          <div className="loading-spinner-small"></div>
        ) : (
          t("email_verification_resend_email")
        )}
      </button>
    </Box>
  );
}
