import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Card, CardBody, Collapse } from "reactstrap";
import {
  BookingPricingDto,
  InsurancePlanDto,
  ItemPublicDto,
} from "../../api/rentMyApi";
import { Range } from "react-date-range";
import { useTranslation } from "react-i18next";
import { differenceInDays } from "date-fns";
import moment from "moment";
import PriceBreakdown from "./PriceBreakdown";
import DateRangePickerPricePeriodDisabling from "../common/DateRangePickerPricePeriodDisabling";
import { getPriceDataFromPriceInfo } from "../../utils/pricePeriodUtils";

interface Props {
  itemDetails: ItemPublicDto;
  dateRangeState: Range;
  setDateRangeState: React.Dispatch<Range>;
  wantDateSelectionAndMultiDayDiscount: boolean;
  wantPriceBreakdown: boolean;
  onTotalChange?: (newTotal: number) => void;
  showDiscounts: boolean;
  addReferral?: (code: string) => void; // Update the type of addReferral prop
    discounts?: string[];
  discountPrice?: number;
  discountTotal?: number;
  showInsuranceTotal?: boolean;
  isInsured?: boolean;
  hasInsurancePlan?: boolean;
  insuranceRequired?: boolean;
  insuranceAccepted?: boolean;
  onInsuranceAcceptedChange?: (value: boolean) => void;
  showInsurance?: boolean;
  showIPT?: boolean;
  insuranceApplied?: boolean;
  setInsuranceApplied?: (value: boolean) => void;
  confirmationPage?: boolean;
  bookingPriceDetails?: BookingPricingDto | undefined;
  itemDetailedPage?: boolean;
}

export const showCurrencyRoundedDown = (num: number): string => {
  return num.toFixed(2);
};

export function DisplayPriceComponents({
  itemDetails,
  dateRangeState,
  setDateRangeState,
  wantDateSelectionAndMultiDayDiscount,
  wantPriceBreakdown,
    discounts = [],
  discountTotal,
  discountPrice,
  onTotalChange,
  isInsured = true,
  hasInsurancePlan,
  insuranceRequired,
  insuranceAccepted,
  onInsuranceAcceptedChange,
  showInsurance,
  showIPT = false,
  showInsuranceTotal,
  insuranceApplied,
  setInsuranceApplied,
  confirmationPage = false,
  bookingPriceDetails,
  itemDetailedPage = false,
}: Props) {
  const [isCalendarExpanded, setIsCalendarExpanded] = useState<boolean>(true);
  const [insurancePremiumTax, setInsurancePremiumTax] = useState<number>(0);
  const [totalInsurancePremium, setTotalInsurancePremium] = useState<number>(0);
  const enableInsurance =
    process.env.REACT_APP_SHOW_INSURANCE &&
    /true/.test(process.env.REACT_APP_SHOW_INSURANCE);

  const { t } = useTranslation();

  const { allDayPrices, deposit } = getPriceDataFromPriceInfo(
    itemDetails?.priceInfo
  );

  if (discountTotal && onTotalChange) {
    onTotalChange(discountTotal);
  }

  const daysBetween =
    dateRangeState.startDate && dateRangeState.endDate
      ? Math.abs(
          differenceInDays(dateRangeState.startDate, dateRangeState.endDate)
        )
      : 0;

  const startMoment = moment(dateRangeState.startDate);
  const endMoment = moment(dateRangeState.endDate);

  const totalCostRawWithoutFee = bookingPriceDetails?.paymentAmount ?? 0;
  const dailyPrice = showCurrencyRoundedDown(
    totalCostRawWithoutFee / daysBetween
  );

  const getTotal = (totalCostRaw: number): number => {
    if (discountPrice === 0 || discountPrice === undefined) {
      return totalCostRaw;
    } else {
      return discountPrice;
    }
  };

  const total = bookingPriceDetails
    ? showCurrencyRoundedDown(getTotal(bookingPriceDetails!.total))
    : "0.0";

    useEffect(() => {
        if (total && onTotalChange) {
            onTotalChange(parseFloat(total));
        }
    }, [total, onTotalChange]);

  const isDateRangeValid = () => {
    return (
      dateRangeState.startDate != null &&
      dateRangeState.endDate != null &&
      dateRangeState.startDate < dateRangeState.endDate
    );
  };

  const [insuranceDetails, setInsuranceDetails] = useState<
    InsurancePlanDto | undefined
  >(undefined);

  let activePremium, minimumPremium, dailyRate;

  const handleChangeDatesClick = () => {
    setIsCalendarExpanded(true);
  };

  useEffect(() => {
    if (enableInsurance && itemDetails?.insurancePlans?.[0]) {
      const _insuranceDetails = itemDetails?.insurancePlans?.[0];

      const _insurancePrices = itemDetails?.insurancePrices?.[0];

      if (_insuranceDetails && _insurancePrices) {
        activePremium = _insurancePrices.activePremium;
        minimumPremium = _insuranceDetails.minimumPremium;
        dailyRate = _insurancePrices.dailyRate;
        const insurancePremiumTaxPercentage =
          (_insuranceDetails.ipt ?? 0) / 100;

        const netPremium =
          (activePremium ?? minimumPremium ?? 0) +
          (dailyRate ?? 0) * (daysBetween - 1);

        const netPremiumRounded = Math.round(netPremium * 100) / 100;

        const insurancePremiumTax =
          netPremiumRounded * insurancePremiumTaxPercentage;
        const insurancePremiumTaxRounded =
          Math.round(insurancePremiumTax * 100) / 100;
        setInsuranceDetails(_insuranceDetails);
        setInsurancePremiumTax(insurancePremiumTaxRounded);
        setTotalInsurancePremium(
          netPremiumRounded + insurancePremiumTaxRounded
        );
      }
    }
  }, [daysBetween]);

  useEffect(() => {
    if (isCalendarExpanded && isDateRangeValid()) {
      setIsCalendarExpanded(false);
    }
  }, [isDateRangeValid()]);

  return (
    <>
      {wantDateSelectionAndMultiDayDiscount && (
        // Expanded Calendar
        <Collapse isOpen={isCalendarExpanded}>
          <Card>
            <CardBody>
              <h2>{t("item_detailed_add_dates_for_prices")}</h2>
              <DateRangePickerPricePeriodDisabling
                pricePeriods={itemDetails?.priceInfo.periods ?? []}
                dateRangeState={dateRangeState}
                setDateRangeState={setDateRangeState}
                months={1}
                showClearButton={false}
                itemId={itemDetails.id}
              />
            </CardBody>
          </Card>
        </Collapse>
      )}
      {wantDateSelectionAndMultiDayDiscount && (
        // Collapsed Calendar
        <Collapse isOpen={!isCalendarExpanded}>
          <Card>
            <CardBody>
              <h2>{t("item_detailed_selected_dates")}</h2>
              <div className="flexy justify-content-space-between">
                <p
                  style={{
                    flexGrow: 1,
                    border: "1px solid #EA554A",
                    textAlign: "center",
                    borderRadius: ".25rem",
                    marginRight: "1rem",
                  }}
                >
                  {startMoment.format("ll")}
                </p>
                <p
                  style={{
                    flexGrow: 1,
                    border: "1px solid #EA554A",
                    textAlign: "center",
                    borderRadius: ".25rem",
                  }}
                >
                  {endMoment.format("ll")}
                </p>
              </div>
              <Button
                className="btn-block btn-outline-primary"
                color="secondary"
                onClick={handleChangeDatesClick}
              >
                {t("item_booking_confirmation_change_dates")}
              </Button>
              <p className="padding-top-10" style={{ fontSize: "0.7em" }}>
                {"Unless otherwise agreed with the Sharer, you must return the item by 9am on " +
                  endMoment.format("ll")}
              </p>
            </CardBody>
          </Card>
        </Collapse>
      )}
      {wantPriceBreakdown && isDateRangeValid() && bookingPriceDetails && (
        <PriceBreakdown
          dailyPrice={dailyPrice}
          // dailyPrices={dayPrices}
          daysBetween={daysBetween}
          totalRangePriceWithoutFee={showCurrencyRoundedDown(
            totalCostRawWithoutFee
          )}
          serviceFeeAmount={showCurrencyRoundedDown(
            bookingPriceDetails?.serviceFeeAmount ?? 0
          )}
          discountTotal={discountTotal ?? 0}
          // discountTotal={discountTotal?.toString() ?? ""}
          total={`${bookingPriceDetails.total}`}
          discounts={[]}
          insuranceTotal={totalInsurancePremium}
          insurancePremiumTax={insurancePremiumTax}
          isInsured={isInsured}
          hasInsurancePlan={hasInsurancePlan}
          insuranceRequired={insuranceRequired}
          insuranceAccepted={insuranceAccepted}
          insuranceDetails={insuranceDetails}
          onInsuranceAcceptedChange={onInsuranceAcceptedChange}
          showInsurance={showInsurance}
          showInsuranceTotal={showInsuranceTotal}
          showIPT={showIPT}
          insuranceApplied={insuranceApplied}
          setInsuranceApplied={setInsuranceApplied}
          confirmationPage={confirmationPage}
          itemDetailedPage={itemDetailedPage}
        />
      )}
    </>
  );
}
