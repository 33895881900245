import { useEffect, useState } from 'react';
import { Col, Container, Nav, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useUserContext } from '../../../context/UserContext';
import { BookingDetails } from './BookingDetails';
import AccountPagesSidebar from '../AccountPagesSidebar';

import {
  BookingClient,
  BookingDetailedDto,
  BookingOrderBy,
  BookingSimpleDto,
  BookingSimpleDtoPaginationDto,
  BookingStatus,
  IConfig,
  StripeCheckoutRedirects,
} from '../../../api/rentMyApi';

import BookingsList from './BookingList';
import { cloneDeep } from 'lodash';
import ModalWithImage from '../../common/ModalWithImage';
import { format, isSameMonth } from 'date-fns';
import { itemPurchasedAnalytics, sendPurchaseEvent } from '../../../api/analytics';
import { toast } from 'react-toastify';
import { HandoverModal } from './HandoverModal';
import SweetAlert from 'react-bootstrap-sweetalert';

interface AccountBookingsProps {
  isSharerView: boolean;
}

export function AccountBookings({ isSharerView }: AccountBookingsProps) {
  const location = useLocation();

  const [selectedBookingId, setSelectedBookingId] = useState('');
  const [isProcessing, setIsProcessing] = useState(true);
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const [cancellationModalTitle, setCancellationModalTitle] = useState<string>('');
  const [cancellationModalButtonText, setCancellationModalButtonText] = useState<string>('');
  const [cancellationModalBookingId, setCancellationModalBookingId] = useState<string>('');
  const [isConfirmedBooking, setIsConfirmedBooking] = useState(false);
  const [confirmedBooking, setConfirmedBooking] = useState('');
  const [bookingClient, setBookingClient] = useState(new BookingClient(new IConfig('notoken'), process.env.REACT_APP_API_ENDPOINT),);

  const [isClientLoaded, setIsClientLoaded] = useState(false);
  const [view, setView] = useState('booking');
  const [handoverModalOpen, setHandoverModalOpen] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const pageSize = 150;

  const [bookings, setBookings] = useState(new BookingSimpleDtoPaginationDto());

  const [handoverBookingId, setHandoverBookingId] = useState('');
  const [showHandoverModal, setShowHandoverModal] = useState(false);
  const { user: currentlyLoggedInUser } = useUserContext();

  const [activeTab, setActiveTab] = useState('upcoming');  // Default to 'upcoming'

  useEffect(() => {
    async function initClient() {
      const token = await getAccessTokenSilently();

      const client = new BookingClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT);

      setBookingClient(client);
      setIsClientLoaded(true);
    }

    initClient();
  }, [getAccessTokenSilently]);

  const fetchBookings = () => {
    if (isClientLoaded) {
      bookingClient
        .booking2(true, false, isSharerView, undefined, false, pageSize, bookings.page, BookingOrderBy.StartDate)
        .then((res) => {
          setBookings(res);
          setIsProcessing(false);
        })
        .catch((e) => {
          toast.error(t('account_booking_err_notifications'));
        });
    }
  };

  useEffect(() => {
    fetchBookings();
  }, [isClientLoaded, bookingClient]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('payment') === 'success') {
      const itemId = urlParams.get('itemId');
      const total = urlParams.get('total');
      const accountID = currentlyLoggedInUser.id;
      sendPurchaseEvent(accountID, itemId, total);
    }
  }, [currentlyLoggedInUser]);

  useEffect(() => {
    if (location.state && Object.keys(location.state).length > 0) {
      setIsConfirmedBooking(true);
      itemPurchasedAnalytics({});
      const startOfBooking = new Date(location.state.detail.startDate);
      const endOfBooking = new Date(location.state.detail.endDate);
      const sameMonth = isSameMonth(startOfBooking, endOfBooking);
      const endDateFormat = sameMonth ? 'dd, yyyy' : 'MMM dd, yyyy';
      const dateStr = `Time period: ${format(startOfBooking, 'MMM dd')}-${format(endOfBooking, endDateFormat)}`;
      setConfirmedBooking(dateStr);
    }
  }, [location]);

  const { t } = useTranslation();
  const { pathname, search } = useLocation();

  const openHandoverModal = (bookingId: string) => {
    setHandoverBookingId(bookingId);
    setShowHandoverModal(true);
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    const _initialView = params.get('view');
    if (_initialView) setView(_initialView);

    const _handoverModalOpen = params.get('handover');
    if (_handoverModalOpen && _handoverModalOpen === 'true') setHandoverModalOpen(true);

    const id = params.get('id');
    if (id) {
      setIsProcessing(true);
      setSelectedBookingId(id);
    }
  }, [search]);

  const cancelBooking = async (bookingId: string) => {
    setIsProcessing(true);
    setShowCancellationModal(false);
    console.log(bookingId);
    try {
      await bookingClient.cancel(bookingId);
      setIsProcessing(false);
      setShowCancellationModal(false);
      toast.success('This booking has been cancelled');
      fetchBookings();
    } catch (error) {
      toast.error('Something went wrong. Try again or contact support.');
      setShowCancellationModal(false);
      console.log(error);
      setIsProcessing(false);
    }
  };

  const handleCancellationRequest = (bookingId: string, title = 'Confirm booking cancellation', buttonText = 'Confirm') => {
    setCancellationModalBookingId(bookingId);
    setCancellationModalButtonText(buttonText);
    setCancellationModalTitle(title);
    setShowCancellationModal(true);
  };


  const onBookingClick = (id: string, view = '', handover = false) => {
    setSelectedBookingId(id);
    setIsProcessing(true);
    const newUrl = `${pathname}?id=${id}&view=${view}&handover=${handover}`;

    window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl);
    setView(view);
  };

  const onBookingDetailsClose = (updatedBooking?: BookingDetailedDto) => {
    if (updatedBooking && bookings.data) {
      const index = bookings.data.findIndex((booking) => booking.id === updatedBooking.id);

      if (index !== -1) {
        const copy = cloneDeep(bookings);
        copy.data![index].bookingStatus = updatedBooking.bookingStatus;

        setBookings(copy);
      }
    }
    setSelectedBookingId('');
    const newUrl = pathname;

    window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl);
  };

  if (selectedBookingId) {
    return (
      <BookingDetails
        bookingId={selectedBookingId}
        onClose={onBookingDetailsClose}
        bookingClient={bookingClient}
        isClientLoaded={isProcessing}
        isSharerView={isSharerView}
        initialView={view}
        handoverModalOpen={handoverModalOpen}
      />
    );
  }

  const pendingBookingStatuses = [
    BookingStatus.Request,
    BookingStatus.Proposal,
    BookingStatus.AcceptBuyerProposal,
    BookingStatus.AcceptBuyerProposal,
    BookingStatus.Disputed,
  ];

  const upcomingBookingsStatuses = [BookingStatus.BookingAccepted];

  const activeBookingsStatuses = [BookingStatus.Ongoing, BookingStatus.HandoverAccepted];

  const pastBookingsStatuses = [BookingStatus.Completed];

  const cancelledBookingStatuses = [BookingStatus.Cancelled];

  const filterBookingsByStatuses = (statuses: BookingStatus[]) =>
    (bookings.data ?? []).filter((booking) => statuses.includes(booking.bookingStatus));

  const pendingBookings = filterBookingsByStatuses(pendingBookingStatuses);
  const upcomingBookings = filterBookingsByStatuses(upcomingBookingsStatuses);
  const activeBookings = filterBookingsByStatuses(activeBookingsStatuses);
  const pastBookings = filterBookingsByStatuses(pastBookingsStatuses);
  const cancelledBookings = filterBookingsByStatuses(cancelledBookingStatuses);
  const bookingsToReview = pastBookings.filter(
    (booking) => !booking.isReviewed && booking.bookingStatus === BookingStatus.Completed && !isSharerView,
  );

  const bookingGroups = [
    {
      bookings: bookingsToReview,
      sharerTitle: '',
      renterTitle: t('rentals_to_review'),
      tabTitle: t('to_review'),
      tabId: 'to_review',
      onTabClick: () => setActiveTab('to_review'),
      notificationNumber: 0,
    },
    {
      bookings: pendingBookings,
      sharerTitle: t('pending_bookings'),
      renterTitle: t('pending_rentals'),
      tabTitle: t('pending'),
      tabId: 'pending',
      onTabClick: () => setActiveTab('pending'),
      notificationNumber: 0,
    },
    {
      bookings: upcomingBookings,
      sharerTitle: t('upcoming_bookings'),
      renterTitle: t('upcoming_rentals'),
      tabTitle: t('upcoming'),
      tabId: 'upcoming',
      onTabClick: () => setActiveTab('upcoming'),
      notificationNumber: 0,
    },
    {
      bookings: activeBookings,
      sharerTitle: t('active_bookings'),
      renterTitle: t('active_rentals'),
      tabTitle: t('active'),
      tabId: 'active',
      onTabClick: () => setActiveTab('active'),
      notificationNumber: 0,
    },
    {
      bookings: pastBookings,
      sharerTitle: t('past_bookings'),
      renterTitle: t('past_rentals'),
      tabTitle: t('past'),
      tabId: 'past',
      onTabClick: () => setActiveTab('past'),
      notificationNumber: 0,
    },
    {
      bookings: cancelledBookings,
      sharerTitle: t('cancelled_bookings'),
      renterTitle: t('cancelled_rentals'),
      tabTitle: t('cancelled'),
      tabId: 'cancelled',
      onTabClick: () => setActiveTab('cancelled'),
      notificationNumber: 0,
    },
  ];

  const payForItem = (bookingId: string) => {
    const rentalLocationUrl = '/account/my-rentals';
    const successResponse = window.location.origin + rentalLocationUrl;
    const cancelResponse = window.location.href + '&cancel-id=' + bookingId;
    const stripeCheckoutRedirects = new StripeCheckoutRedirects({
      cancel: cancelResponse,
      success: successResponse,
    });
    bookingClient
      .checkout(bookingId, undefined, stripeCheckoutRedirects)
      .then((response) => {
        window.location.href = response;
        setIsProcessing(false);
      })
      .catch(async () => {
        setIsProcessing(false);
        toast.error('There was an error making this booking, please contact support');
      });
  };

  const filteredBookings = bookingGroups.filter(
    (bookingGroup) =>
      !!bookingGroup.bookings.length ||
      bookingGroup.renterTitle === t('upcoming_rentals') ||
      bookingGroup.renterTitle === t('active_rentals') ||
      bookingGroup.renterTitle === t('past_rentals'),
  );

  const getBookingTable = (bookings: BookingSimpleDto[], sharerTitle: string, renterTitle: string, tabId: string) => {
    return (
      <TabPane tabId={tabId}>
        <BookingsList
          key={isSharerView ? sharerTitle : renterTitle}
          bookings={bookings}
          onBookingDetailsClick={onBookingClick}
          title={isSharerView ? sharerTitle : renterTitle}
          isSharerView={isSharerView}
          isLoading={isProcessing}
          bookingClient={bookingClient}
          openHandoverModal={openHandoverModal}
          handleCancellationRequest={handleCancellationRequest}
        />
      </TabPane>
    );
  };

  return (
    <Container className="mt-4 account-settings-container">
      <Row>
        <Col md={4} lg={4} xl={3} className="hide-on-mobile hide-on-tablet">
          <AccountPagesSidebar />
        </Col>

        <Col md={8} lg={8} xl={9} className="auto-margin">
          <Nav className="tab-list">
            {filteredBookings.map((bookingGroup) => (
              <NavLink
                key={bookingGroup.tabId}
                className={activeTab === bookingGroup.tabId ? 'active-tab' : ''}
                onClick={bookingGroup.onTabClick}
              >
                {bookingGroup.tabTitle}{' '}
                {bookingGroup.notificationNumber > 0 && (
                  <span className="notification-circle">{bookingGroup.notificationNumber}</span>
                )}
              </NavLink>
            ))}
          </Nav>
          <div className="account-booking-list">
            <TabContent activeTab={activeTab}>
              {filteredBookings.map((bookingGroup) =>
                getBookingTable(
                  bookingGroup.bookings,
                  bookingGroup.sharerTitle,
                  bookingGroup.renterTitle,
                  bookingGroup.tabId,
                ),
              )}
            </TabContent>
          </div>
        </Col>
      </Row>
      <ModalWithImage
        isOpen={isConfirmedBooking}
        onClose={() => setIsConfirmedBooking(false)}
        isDefault={true}
        image={'assets/img/modal-placeholder-image.webp'}
        title={t('item_booking_confirmation_title')}
        description={confirmedBooking}
        primaryButtonText={t('close')}
        onPrimaryButtonClick={() => {
          setIsConfirmedBooking(false);
        }}
      />
      <HandoverModal
        isOpen={showHandoverModal}
        onClose={() => setShowHandoverModal(false)}
        isSharerView={isSharerView}
        bookingId={handoverBookingId}
        hasInsurance={!!bookings.data?.filter((booking) => booking.id === handoverBookingId)[0]?.insuranceBreakdown}
        endDate={bookings.data?.filter((booking) => booking.id === handoverBookingId)[0]?.endDate}
      />

      {showCancellationModal && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText={cancellationModalButtonText}
          cancelBtnText={'Close'}
          confirmBtnBsStyle="danger"
          title={cancellationModalTitle}
          onConfirm={() => cancelBooking(cancellationModalBookingId)}
          onCancel={() => setShowCancellationModal(false)}
        ></SweetAlert>
      )}
    </Container>
  );
}