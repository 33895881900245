import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";

import { FileClient, IConfig, ImageDto } from "../../api/rentMyApi";
import { useEnv } from "../../context/env.context";

import ImageSelector, { Capture } from "../common/ImageSelector";
import { ImagePromise } from "../../models/interfaces/Image";
import PlaceholderIcon from "../../icons/placeholder-icon";
import { ItalicDescription } from "./Shared";

interface LocalImageUploadManagementProps {
  setUploadedImages: (images: ImageDto[]) => void;
  uploadedImages: ImageDto[];
  setIsProcessing: (isProcessing: boolean) => void;
  selectedImageIndex: number;
  isProcessing: boolean;
  newImagesOnly?: boolean;
}

function LocalImageUploadManagement({
  setUploadedImages,
  uploadedImages,
  setIsProcessing,
  selectedImageIndex,
  isProcessing,
  newImagesOnly,
}: LocalImageUploadManagementProps) {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);
  const { getAccessTokenSilently } = useAuth0();
  const [apiFileClient, setApiFileClient] = useState(
    new FileClient(new IConfig("notoken"), process.env.REACT_APP_API_ENDPOINT)
  );

  const inputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();
  const { apiServerUrl } = useEnv();

  useEffect(() => {
    async function initClient() {
      const token = await getAccessTokenSilently();
      setApiFileClient(
        new FileClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT)
      );
    }

    initClient();
  }, []);

  const onNewImagesUploaded = (newImages: File[]) => {
    // console.log(newImages)
    setIsProcessing(true);
    const promises = newImages.map((image) => {
      // console.log(image)
      const updated_image: File = new File([image], image.name);
      return apiFileClient.upload(".jpeg", {
        fileName: updated_image.name,
        data: image,
      });
    });

    Promise.allSettled(promises).then((data: ImagePromise[]) => {
      const totalImages = data.length;
      const fulfilled = data.filter(
        (promise: ImagePromise) => promise.status === "fulfilled"
      );
      const images: ImageDto[] = fulfilled.map(
        (promise: ImagePromise) => promise.value
      ) as ImageDto[];

      if (totalImages === fulfilled.length) {
        toast.success(t("item_listing_upload_succes"));
      } else {
        toast.warn(
          `${images} ${t("item_listing_out_of")} ${totalImages} ${t(
            "item_listing_partial_upload"
          )}`
        );
      }

      setIsProcessing(false);
      setShowUploadModal(false);
      setUploadedImages([...uploadedImages, ...images]);
    });
  };

  return (
    <div className="container">
      <ImageSelector
        onNewImagesUploaded={onNewImagesUploaded}
        ref={inputRef}
        accept={"image/png, image/jpg, image/jpeg, image/webp"}
        sizeLimitMB={100}
        capture={newImagesOnly ? Capture.Environment : undefined}
      />
      <div className="row">
        <div className="col">
          <div className="row">
            {uploadedImages.length ? (
              <div className="full-width full-height">
                <img
                  key={
                    uploadedImages[selectedImageIndex % uploadedImages.length]
                      .id
                  }
                  src={
                    uploadedImages[selectedImageIndex % uploadedImages.length]
                      .compressedPath
                  }
                  className="image-upload"
                  alt={t("item_listing_uploaded_image")}
                />
              </div>
            ) : (
              <PlaceholderIcon />
            )}

            <Button
              onClick={() => {
                inputRef?.current?.click();
              }}
              color="primary"
              className="image-upload-button image-upload-button-variant"
            >
              {isProcessing ? (
                <div className="loading-spinner-small"></div>
              ) : (
                t("item_listing_upload_images")
              )}
            </Button>
          </div>
        </div>
      </div>
      <ItalicDescription text="We recommend 3 or more images" />
      {uploadedImages.length > 1 && (
        <ItalicDescription text="Drag images into your preferred order" />
      )}

      <Modal
        isOpen={showUploadModal}
        centered
        size="sm"
        toggle={() => setShowUploadModal(false)}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="row">
                <img
                  src="/assets/img/modal-placeholder-image.webp"
                  alt={t("image_placeholder")}
                  className="image-upload-modal"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col image-upload-div">
              <h3>{t("item_listing_add_images")}</h3>
              <br />
              <div className="row">
                <div className="col">
                  <Button
                    onClick={() => setShowUploadModal(false)}
                    outline
                    color="primary"
                    className="image-upload-button"
                  >
                    {t("item_listing_cancel")}
                  </Button>
                </div>
                <div className="col">
                  <Button
                    color="primary"
                    onClick={() => {
                      inputRef?.current?.click();
                    }}
                    className="image-upload-button"
                  >
                    {t("item_listing_upload_images")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default LocalImageUploadManagement;
