import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Container } from "reactstrap";

export function ExploreTopCategories() {
  const { t } = useTranslation();

  const [textTitle, setTextTitle] = useState("");
  const [images, setImages] = useState<
    { path: string; caption: string; alt: string; url: string }[]
  >([]);

  useEffect(() => {
    const cmsUrl = process.env.REACT_APP_CMS_URL!;
    const fourImageBlockPath = process.env.REACT_APP_CMS_FOURIMAGE_PATH!;
    const absPath = cmsUrl + fourImageBlockPath;

    axios
      .get(absPath)
      .then((res) => {
        const { titleTextColor, sectionBackgroundColor } = res.data.fields;

        document.documentElement.style.setProperty(
          "--categories-background-color",
          "#" + sectionBackgroundColor
        );

        document.documentElement.style.setProperty(
          "--topCategoriesTitleTextColour",
          "#" + titleTextColor
        );

        setTextTitle(res.data.fields.title);

        const imageData = [
          {
            path: "/assets/img/frontpage/category_img1.webp",
            caption: res.data.fields.image1Caption,
            alt: res.data.fields.image1Alt,
            url: res.data.fields.image1Link,
          },
          {
            path: "/assets/img/frontpage/category_img2.webp",
            caption: res.data.fields.image2Caption,
            alt: res.data.fields.image2Alt,
            url: res.data.fields.image2Link,
          },
          {
            path: "/assets/img/frontpage/category_img3.webp",
            caption: res.data.fields.image3Caption,
            alt: res.data.fields.image3Alt,
            url: res.data.fields.image3Link,
          },
          {
            path: "/assets/img/frontpage/category_img4.webp",
            caption: res.data.fields.image4Caption,
            alt: res.data.fields.image4Alt,
            url: res.data.fields.image4Link,
          },
        ];

        setImages(imageData);
      })
      .catch((err) => {});
  }, []);

  const routeChange = (url: string) => {
    if (url !== "") {
      window.location.replace(url);
    }
  };

  return (
    <section className="four-image-block">
      <Container>
        <div className="col-12">
          <h3>{textTitle}</h3>
          <div className="row">
            {images.map((image, index) => (
              <div key={index} className="col-6 col-md-3">
                <a
                  href={image.url}
                  title=""
                  onClick={(e) => {
                    e.preventDefault();
                    routeChange(image.url);
                  }}
                >
                  <img src={image.path} alt={image.alt} />
                  <h3>{image.caption}</h3>
                </a>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
}