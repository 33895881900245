import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { Box, InputLabel, TextField } from "@mui/material";
import Autocomplete from "react-google-autocomplete";
import { Collapse } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useUserContext } from "../../../context/UserContext";
import { Location } from "../../../api/rentMyApi";
import { UserAddressFormErrors } from "../../../models/interfaces/UserAddress";

const inputStyle = {
  marginBottom: "10px",
  ".MuiOutlinedInput-input": {
    padding: "10px 12px",
    lineHeight: "24px",
  },
  ".MuiOutlinedInput-root": {
    backgroundColor: "white",
    border: "1px solid lightgrey",
    "&.Mui-focused": {
      border: "1px solid red",
    },
    "&.Mui-error": {
      border: "1px solid red",
    },
    ".MuiOutlinedInput-notchedOutline": {
      borderStyle: "none",
    },
  },
  ".MuiFormHelperText-root": {
    "&.Mui-error": {
      color: "red",
      fontSize: "small",
      margin: "0",
    },
  },
};

type UserAddressProps = {
  locationData: Location;
  setLocationData: Dispatch<SetStateAction<Location>>;
  formErrors: UserAddressFormErrors;
};

export const UserAddressInput = ({
  locationData,
  setLocationData,
  formErrors,
}: UserAddressProps) => {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const [isFormOpen, setIsFormOpen] = useState(false);

  useEffect(() => {
    if (user.location) {
      const { addressLine1, addressLine2, city, country, postcode } =
        user.location;
      if (addressLine1) {
        setLocationData(
          (previousValues) =>
            ({
              ...previousValues,
              addressLine1,
            } as Location)
        );
        setIsFormOpen(true);
      }
      if (addressLine2) {
        setLocationData(
          (previousValues) =>
            ({
              ...previousValues,
              addressLine2,
            } as Location)
        );
        setIsFormOpen(true);
      }
      if (city) {
        setLocationData(
          (previousValues) =>
            ({
              ...previousValues,
              city,
            } as Location)
        );
        setIsFormOpen(true);
      }
      if (country) {
        setLocationData(
          (previousValues) =>
            ({
              ...previousValues,
              country,
            } as Location)
        );
        setIsFormOpen(true);
      }
      if (postcode) {
        setLocationData(
          (previousValues) =>
            ({
              ...previousValues,
              postcode,
            } as Location)
        );
        setIsFormOpen(true);
      }
    }
  }, [user]);

  useEffect(() => {
    const {
      addressLine1: addressError,
      city: cityError,
      country: countryError,
      postcode: postcodeError,
    } = formErrors;
    const { addressLine1, city, country, postcode } = locationData;
    if (
      addressError ||
      cityError ||
      countryError ||
      postcodeError ||
      addressLine1 ||
      city ||
      country ||
      postcode
    ) {
      setIsFormOpen(true);
    }
  }, [formErrors, locationData]);

  // Get Address from Google Autocomplete
  const getStreetAddress = (
    addressArray: google.maps.GeocoderAddressComponent[]
  ) => {
    let streetAddress = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "street_number" === addressArray[i].types[0]
      ) {
        streetAddress += addressArray[i].long_name + " ";
      }
      if (addressArray[i].types[0] && "route" === addressArray[i].types[0]) {
        streetAddress += addressArray[i].long_name + " ";
      }
    }
    return streetAddress;
  };

  //   Get city from Google Autocomplete
  const getCity = (addressArray: google.maps.GeocoderAddressComponent[]) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "postal_town" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  //   Get country from Google Autocomplete
  const getCountry = (addressArray: google.maps.GeocoderAddressComponent[]) => {
    let country = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && "country" === addressArray[i].types[0]) {
        country = addressArray[i].long_name;
        return country;
      }
    }
  };

  //   Get postcode from Google Autocomplete
  const getPostCode = (
    addressArray: google.maps.GeocoderAddressComponent[]
  ) => {
    let postcode = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "postal_code" === addressArray[i].types[0]
      ) {
        postcode = addressArray[i].long_name;
        return postcode;
      }
    }
  };

  const onAutoComplete = (newLocationData: Location) => {
    setLocationData(newLocationData);
  };

  const handlePlaceAutocomplete = (
    placeData: google.maps.places.PlaceResult
  ) => {
    const address = placeData.formatted_address,
      addressArray = placeData.address_components,
      addressLine = getStreetAddress(addressArray ?? []),
      city = getCity(addressArray ?? []),
      country = getCountry(addressArray ?? []),
      postcode = getPostCode(addressArray ?? []),
      latValue = placeData.geometry!.location!.lat() ?? 0,
      lngValue = placeData.geometry!.location!.lng() ?? 0;

    const newLocationData: Location = {
      ...locationData,
    } as Location;

    newLocationData.name = address;
    newLocationData.addressLine1 = addressLine;
    newLocationData.city = city;
    newLocationData.country = country;
    newLocationData.postcode = postcode;
    newLocationData.latitude = latValue;
    newLocationData.longitude = lngValue;

    onAutoComplete(newLocationData);
  };

  function locationInputChanges(e: React.ChangeEvent<HTMLInputElement>): void {
    const newLocationData: Location = new Location({
      ...locationData,
      latitude: undefined,
      longitude: undefined,
      [e.target.name]: e.target.value,
    });
    setLocationData(newLocationData);
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <InputLabel id="addressLookUp">{t("address_lookup")}</InputLabel>
      <Autocomplete
        id="addressLookUp"
        onPlaceSelected={(place) => handlePlaceAutocomplete(place)}
        className="form-control  input-field"
        style={{ borderColor: "rgb(211, 211, 211", marginBottom: "15px" }}
        aria-placeholder={"Start typing your address..."}
        options={{
          componentRestrictions: { country: "uk" },
          types: ["geocode", "establishment"],
        }}
      />
      <Collapse isOpen={isFormOpen}>
        <InputLabel id="addressLine1">{t("address_line_1")}</InputLabel>
        <TextField
          id="addressLine1"
          name="addressLine1"
          fullWidth
          sx={inputStyle}
          value={locationData.addressLine1 ?? ""}
          onChange={locationInputChanges}
          error={!!formErrors.addressLine1}
          helperText={formErrors.addressLine1}
        />
        <InputLabel id="addressLine2">{t("address_line_2")}</InputLabel>
        <TextField
          id="addressLine2"
          name="addressLine2"
          fullWidth
          sx={inputStyle}
          value={locationData.addressLine2 ?? ""}
          onChange={locationInputChanges}
          error={!!formErrors.addressLine2}
          helperText={formErrors.addressLine2}
        />
        <InputLabel id="city">{t("city")}</InputLabel>
        <TextField
          id="city"
          name="city"
          fullWidth
          sx={inputStyle}
          value={locationData.city ?? ""}
          onChange={locationInputChanges}
          error={!!formErrors.city}
          helperText={formErrors.city}
        />
        <InputLabel id="country">{t("country")}</InputLabel>
        <TextField
          id="country"
          name="country"
          fullWidth
          sx={inputStyle}
          value={locationData.country ?? ""}
          onChange={locationInputChanges}
          error={!!formErrors.country}
          helperText={formErrors.country}
        />
        <InputLabel id="postcode">{t("postcode")}</InputLabel>
        <TextField
          id="postcode"
          name="postcode"
          fullWidth
          sx={inputStyle}
          value={locationData.postcode ?? ""}
          onChange={locationInputChanges}
          error={!!formErrors.postcode}
          helperText={formErrors.postcode}
        />
      </Collapse>
    </Box>
  );
};
