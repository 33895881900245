import { useEffect, useState } from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography, SxProps, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../context/UserContext';
import { ItemOrderBy } from '../../api/rentMyApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { ChosenPlace, setSearchTerm, setSelectedPlace, setSortByOption } from '../../redux/searchSlice';
import PlaceResult = google.maps.places.PlaceResult;

type OnboardingCompletedStepProps = {
  headingStyle: SxProps<Theme>;
  stepSummaryStyle: SxProps<Theme>;
  setOnboardingCompleted: React.Dispatch<React.SetStateAction<boolean>>;
};

export function OnboardingCompletedStep({
  headingStyle,
  stepSummaryStyle,
  setOnboardingCompleted,
}: OnboardingCompletedStepProps) {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [localSelectedPlace, setLocalSelectedPlace] = useState<PlaceResult | undefined>();
  const localSearchTerm = 'all';
  const localSortByOption = {
    value: ItemOrderBy.Relevance,
    label: t('search_results_sort_relevance'),
  };

  useEffect(() => {
    const awaitGeocodeAddress = async () => {
      if (user.location.name) {
        await geocodeAddress(user.location.name);
      }
    };
    awaitGeocodeAddress();
  }, []);

  const geocodeAddress = async (address: string) => {
    try {
      const geocoder = new google.maps.Geocoder();
      const { results } = await geocoder.geocode({ address: address });
      if (results && results.length > 0) {
        const result = results[0];
        setLocalSelectedPlace(result);
        dispatch(
          setSelectedPlace({
            formatted_address: result.formatted_address,
            place_id: result.place_id,
            lat: result.geometry.location.lat(),
            lng: result.geometry.location.lng(),
          }),
        );
        return;
      } else {
        return;
      }
    } catch (error) {
      console.error('Error geocoding address:', error);
      return;
    }
  };

  const getSearchResults = async () => {
    setIsLoading(true);
    let basePath = `${window.location.origin}/rent/${localSearchTerm}`;
    // Check if selectedPlace is valid before attempting to access its properties
    const newSelectedPlace: ChosenPlace = {
      formatted_address: undefined,
      place_id: undefined,
      lat: undefined,
      lng: undefined,
    };
    if (
      localSelectedPlace &&
      localSelectedPlace.place_id &&
      localSelectedPlace.geometry &&
      localSelectedPlace.geometry.location
    ) {
      const lat = localSelectedPlace.geometry.location.lat();
      const lng = localSelectedPlace.geometry.location.lng();
      newSelectedPlace.lng = lng;
      newSelectedPlace.lat = lat;
      newSelectedPlace.place_id = localSelectedPlace.place_id;
      newSelectedPlace.formatted_address = localSelectedPlace.formatted_address;
      basePath += `/${localSelectedPlace.place_id}?lat=${lat}&lng=${lng}`;
    }
    dispatch(setSearchTerm(localSearchTerm));
    dispatch(setSelectedPlace(newSelectedPlace));
    dispatch(setSortByOption(localSortByOption));
    setIsLoading(false);
    window.history.pushState({}, '', basePath);
    if (pathname.split('/')[1] !== 'search') window.location.reload();
  };

  const handleButtonClick = async () => {
    if (user.canBeSharer) {
      navigate('/item-listing');
    } else {
      await getSearchResults();
    }
    // After clicking the button the onboardingCompleted state should switch back to false so that the user can navigate to the rest of the app (handled by the OnboardingRequired component)
    setOnboardingCompleted(false);
  };

  return (
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        <Typography component="h1" sx={headingStyle}>
          {t('onboarding_completed')}
        </Typography>
        <Typography sx={stepSummaryStyle}>{t('onboarding_completed_summary')}</Typography>
        {/*<button*/}
        {/*    type="button"*/}
        {/*    disabled={isLoading}*/}
        {/*    onClick={handleButtonClick}*/}
        {/*    className="btn btn-primary onboarding-button"*/}
        {/*>*/}
        {/*  {isLoading && (*/}
        {/*      <span className="btn-inner--icon">*/}
        {/*    <FontAwesomeIcon icon={faSpinner} spin/>*/}
        {/*  </span>*/}
        {/*  )}*/}
        {/*  {user.canBeSharer ? t('onboarding_create_listing') : 'Search for items near you'}*/}
        {/*</button>*/}
        <button
            type="button"
            onClick={() => window.location.reload()}
            className="btn btn-primary onboarding-button"
        >
         Continue
        </button>
      </Box>
  );
}
