import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

export default function StarRating({
  overallAverage,
  reviewCount = 0,
  relativePositioning = false,
}: {
  overallAverage: number | undefined;
  reviewCount: number | undefined;
  relativePositioning?: boolean | undefined;
}) {
  const reviewString = `(${reviewCount ?? 0} review${
    reviewCount > 1 || reviewCount === 0 ? "s" : ""
  })`;

  return (
    <span
      className={
        relativePositioning
          ? "star-rating position-relative-no-padding flex align-items-center"
          : "font-grey flex align-items-center"
      }
    >
      <FontAwesomeIcon color="#F9B048" icon={faStar} />
      <span
        style={{ verticalAlign: "middle" }}
        className="review padding-left-4 item-highlight-rating"
      >
        {reviewCount ? Math.round((overallAverage ?? 5) * 10) / 10 : 5}
      </span>{" "}
      {reviewCount ? (
        <span className="number-of-reviews padding-left-4">{reviewString}</span>
      ) : (
        <></>
      )}
    </span>
  );
}
