import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";

interface StarRatingProps {
  rating: number;
}

const StarRating: React.FC<StarRatingProps> = ({ rating }) => {
  const starIcons: JSX.Element[] = [];

  for (let i = 1; i <= 5; i++) {
    starIcons.push(
      <FontAwesomeIcon
        style={{ fontSize: "12px" }}
        key={i}
        icon={i <= rating ? solidStar : regularStar}
        className={`mr-1 ${i <= rating ? "gold" : "grey"}`}
      />
    );
  }

  return <div>{starIcons}</div>;
};

export default StarRating;
