import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  SearchPageClient,
  ItemSearchResultDto,
  ItemSearchDto,
  ItemOrderBy,
  IConfig,
} from "../../api/rentMyApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faStar,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Container } from "reactstrap";

// Initialize SearchPageClient outside the component
const searchPageClient = new SearchPageClient(
  new IConfig("notoken"),
  process.env.REACT_APP_API_ENDPOINT || "http://localhost:3000" // Provide a default or ensure the environment variable is set
);

export function PopularSlider() {
  const [items, setItems] = useState<ItemSearchDto[]>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Reference to the slider container
  const sliderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response: ItemSearchResultDto = await searchPageClient.searchPage(
          51.5072178, // latitude: number
          -0.1275862, // longitude: number
          undefined, // minLatitude
          undefined, // maxLatitude
          undefined, // minLongitude
          undefined, // maxLongitude
          undefined, // distanceInKm
          undefined, // searchRadiusInKm
          undefined, // minPrice
          undefined, // maxPrice
          undefined, // startDate
          undefined, // endDate
          undefined, // includeUnavailableItems
          undefined, // categoryIds
          undefined, // searchTerm (set to undefined to search all)
          undefined, // itemIds
          undefined, // ownerIds
          ItemOrderBy.Relevance, // sortByOption
          undefined, // rating
          undefined, // minRating
          undefined, // maxRating
          12, // pageSize
          1, // pageNumber
          undefined // options
        );

        if (response && response.paginatedData && response.paginatedData.data) {
          setItems(response.paginatedData.data);
        } else {
          setError("Invalid response structure.");
          console.error("Invalid response structure:", response);
        }
      } catch (error) {
        setError(
          "Looks like this slider has already been rented out, it'll be back soon!"
        );
        console.error("Error fetching items:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, []);

  // Determine if "See More" button should be added
  const hasSeeMore = items.length >= 12;

  // Append a dummy item for "See More" button if applicable
  const displayItems = hasSeeMore
    ? [
        ...items,
        {
          id: "see-more",
          itemName: "See More",
          image: { compressedPath: "" },
          ownerName: "",
          url: "",
          dayRate: 0,
          locationName: "",
          ownerProfilePicture: { compressedPath: "" },
        } as ItemSearchDto,
      ]
    : items;

  // Handler to scroll slider
  const scroll = (direction: "left" | "right") => {
    if (sliderRef.current) {
      const scrollAmount = sliderRef.current.clientWidth / 2; // Scroll half the width
      sliderRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <section className="item-slider">
      <Container>
        <h3>Popular Items</h3>
      </Container>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : displayItems.length > 0 ? (
        <div className="slider-container">
          {/* Navigation Arrows */}
          <button
            className="nav-arrow left"
            onClick={() => scroll("left")}
            aria-label="Scroll Left"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <div className="slider" ref={sliderRef}>
            {displayItems.map((item) => (
              <div
                key={item.id}
                className={`slider-item ${
                  item.id === "see-more" ? "see-more-item" : ""
                }`}
                onClick={
                  item.id === "see-more"
                    ? () => navigate("/rent/all")
                    : undefined
                }
              >
                {item.id === "see-more" ? (
                  <button className="see-more-button">See More</button>
                ) : (
                  <div className="item-card">
                    <div className="owner-info">
                      <img
                        src={
                          item.ownerProfilePicture &&
                          item.ownerProfilePicture.compressedPath
                            ? item.ownerProfilePicture.compressedPath
                            : "/assets/img/profile-pic-placeholder.webp" // Ensure path is correct
                        }
                        alt={item.ownerName || "Owner"}
                        className="owner-avatar"
                        loading="lazy"
                      />
                      <span className="owner-name">
                        {item.ownerName || "Unknown Owner"}
                      </span>
                    </div>
                    <a href={`/item/${item.url}`} className="item-link">
                      <img
                        className="listing-image" // Use the listing-image class
                        src={
                          item.image && item.image.compressedPath
                            ? item.image.compressedPath
                            : "/placeholder.jpg"
                        }
                        alt={item.itemName || "Item"}
                        loading="lazy" // Enable lazy loading
                      />
                      <div className="item-name">
                        {item.itemName || "Untitled Item"}
                      </div>
                      <div className="item-details">
                        <div className="item-price">
                          £
                          {item.dayRate ? Math.round(item.dayRate) : "N/A"}/Day
                        </div>
                        <div className="item-location">
                          <FontAwesomeIcon icon={faMapMarkerAlt} />
                          <span>{item.locationName || "Unknown"}</span>
                        </div>
                      </div>
                    </a>
                  </div>
                )}
              </div>
            ))}
          </div>
          <button
            className="nav-arrow right"
            onClick={() => scroll("right")}
            aria-label="Scroll Right"
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      ) : (
        <p>Looks like this slider has been rented out!</p>
      )}
      </section>
  );
}