import { useAuth0 } from "@auth0/auth0-react";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
} from "reactstrap";
import { notificationCounter } from "./NotificationCounter";

import { useUserContext } from "../../../context/UserContext";
import { useEffect, useState } from "react";
import { NavbarMenuOptions } from "../../common/Navigation";

interface NavMenuUserLoggedInDropdownProps {
  username: string;
  userProfilePic: string;
  isMobile: boolean;
  setHamburgerOpen: (isOpen: boolean) => void;
  isHamburgerOpen: boolean;
}

export default function NavMenuUserLoggedInDropdown({
  username,
  userProfilePic,
  isMobile,
  setHamburgerOpen,
  isHamburgerOpen,
}: NavMenuUserLoggedInDropdownProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { logout } = useAuth0();

  const {
    unreadMessageCount,
    user,
    bookingNotifications,
    rentalNotifications,
  } = useUserContext();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleMobileBurgerClick = () => {
    setHamburgerOpen(!isHamburgerOpen);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!(event.target as Element).closest(".navmenu-dropdown")) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const closeDropdown = () => {
    if (!isMobile) {
      setIsDropdownOpen(false);
    }
  };

  const bookingCount = bookingNotifications ? 1 : 0;
  const rentalCount = rentalNotifications ? 1 : 0;

  const totalNotifications = unreadMessageCount + bookingCount + rentalCount;

  return (
    <Dropdown
      toggle={() => {
        if (isMobile) {
          handleMobileBurgerClick();
        }
      }}
      isOpen={isDropdownOpen}
      className="navmenu-dropdown"
    >
      <div className="clickable">
        <DropdownToggle className="nav-link pr-0 no-padding" color="" tag="a">
          <div
            onClick={() => {
              if (!isMobile) {
                setIsDropdownOpen(!isDropdownOpen);
              }
            }}
          >
            <Media className="align-items-center">
              {notificationCounter(totalNotifications, false, isMobile)}
              <span className="avatar avatar-navbar rounded-circle">
                <img
                  className="uploaded-image-background"
                  alt={t("nav_prof_pic")}
                  src={userProfilePic}
                />
              </span>
              <Media className="ml-2 d-none d-lg-block"></Media>
            </Media>
          </div>
        </DropdownToggle>
        {isDropdownOpen && (
          <DropdownMenu end className="dropdown-menu-width">
            <div className="noti-title dropdown-header">
              <h6 className="text-overflow m-0">{username}</h6>
            </div>
            {NavbarMenuOptions().map((item, index) => {
              console.log(item.indent);
              return (
                <DropdownItem
                  key={item.text + item.destination}
                  onClick={() => {
                    navigate(item.destination);
                    closeDropdown();
                  }}
                  style={item.indent ? { paddingLeft: "40px" } : {}}
                >
                  <FontAwesomeIcon
                    className={"navigation-dropdown-item-icon"}
                    icon={item.icon}
                  />
                  <span>{item.text}</span>
                  {index === 1 &&
                    notificationCounter(
                      unreadMessageCount,
                      true,
                      isMobile,
                      true
                    )}
                  {index === 2 &&
                    rentalNotifications &&
                    notificationCounter(1, true, isMobile, true)}
                  {index === 4 &&
                    bookingNotifications &&
                    notificationCounter(1, true, isMobile, true)}
                </DropdownItem>
              );
            })}
            <DropdownItem divider />
            <DropdownItem
              onClick={() => {
                logout({
                  returnTo: window.location.origin,
                });
                closeDropdown();
              }}
            >
              <FontAwesomeIcon icon={faSignOut} />
              <span>Logout</span>
            </DropdownItem>
          </DropdownMenu>
        )}
      </div>
    </Dropdown>
  );
}
