import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CategoryResultsDto,
  ItemOrderBy,
  ItemSearchDto,
  ItemSearchDtoPaginationDto,
  ItemSearchResultDto,
  SearchPageClient
} from "../api/rentMyApi";

export interface ChosenPlace {
  formatted_address: string | undefined;
  lat: number | undefined;
  lng: number | undefined;
  place_id: string | undefined;
}

interface SearchState {
  searchTerm: string;
  selectedCategoryId: string | undefined;
  selectedPlace: ChosenPlace;
  sortByOption: {
    value: ItemOrderBy;
    label: string;
  };
  itemSearchPaginated: ItemSearchDtoPaginationDto;
  topLevelCategories: CategoryResultsDto[];
  isLoading: boolean;
  isLoadingNextPage: boolean;
  isItemsLoading: boolean;
  error: string | null;
}

export interface UseItemSearchParams {
  pageNumber: number;
  pageSize: number;
  searchQuery: string | undefined;
  categoryId: string | undefined;
  sortByOption: ItemOrderBy;
  lat?: number | undefined;
  lng?: number | undefined;
}

export interface SearchPageParams extends UseItemSearchParams {
  searchPageClient: SearchPageClient;
}

const PAGE_SIZE = 12;
const initialItemSearchPaginated =
  {
    page: 1,
    pageSize: PAGE_SIZE,
    data: [],
    totalDataCount: 0,
    dataCount: 0,
    pageCount: 0,
    previousPage: undefined,
    nextPage: undefined,
    parameters: undefined
  };

interface ErrorResponse {
  message: string;
}

const searchPage = async ({
                            lat,
                            lng,
                            searchQuery,
                            sortByOption,
                            pageNumber,
                            categoryId,
                            pageSize,
                            searchPageClient
                          }: SearchPageParams): Promise<ItemSearchResultDto> => {
  return await searchPageClient.searchPage(
    lat,
    lng,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    categoryId ? [categoryId] : undefined,
    searchQuery === "all" ? undefined : searchQuery,
    undefined,
    undefined,
    (sortByOption === undefined) ? undefined : sortByOption,
    undefined,
    undefined,
    undefined,
    pageSize,
    pageNumber,
    undefined
  );
};

export const fetchInitialSearchResults = createAsyncThunk<ItemSearchResultDto, UseItemSearchParams, {
  rejectValue: ErrorResponse,
  extra: {
    searchPageClient: SearchPageClient;
  }
}>(
  "search/fetchInitialSearchResults",
  async (params, { rejectWithValue, extra: { searchPageClient } }) => {
    try {
      const {
        lat, lng, searchQuery, sortByOption, pageNumber, categoryId, pageSize
      } = params;
      return await searchPage({
        lat,
        lng,
        searchQuery,
        sortByOption,
        pageNumber,
        pageSize,
        categoryId,
        searchPageClient
      });
    } catch (error: any) {
      return error.response;
    }
  }
);

export const fetchNextPage = createAsyncThunk<ItemSearchResultDto, UseItemSearchParams, {
  rejectValue: ErrorResponse,
  extra: {
    searchPageClient: SearchPageClient;
  }
}>(
  "search/fetchNextPage",
  async (params, { rejectWithValue, extra: { searchPageClient } }) => {
    try {
      return await searchPage({ ...params, searchPageClient });
    } catch (error: any) {
      return error.response;
    }
  });

export const fetchSearchResults = createAsyncThunk<ItemSearchResultDto, UseItemSearchParams, {
  rejectValue: ErrorResponse,
  extra: {
    searchPageClient: SearchPageClient;
  }
}>(
  "search/fetchSearchResults",
  async (params, { rejectWithValue, extra: { searchPageClient } }) => {
    try {
      return await searchPage({ ...params, searchPageClient });
    } catch (error: any) {
      return error.response;
    }
  }
);

const initialState: SearchState = {
  searchTerm: "",
  selectedCategoryId: "",
  selectedPlace: {
    formatted_address: undefined,
    place_id: undefined,
    lat: undefined, lng: undefined
  },
  sortByOption: { value: ItemOrderBy.Relevance, label: "Relevance" },
  itemSearchPaginated: new ItemSearchDtoPaginationDto(initialItemSearchPaginated),
  topLevelCategories: [],
  isLoading: true,
  isLoadingNextPage: false,
  isItemsLoading: false,
  error: null
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    // Define reducers to handle updating each of these pieces of state
    setSearchTerm(state, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
    },
    setSelectedCategoryId(state, action: PayloadAction<string | undefined>) {
      state.selectedCategoryId = action.payload;
    },
    setSortByOption(state, action: PayloadAction<{ value: ItemOrderBy; label: string }>) {
      state.sortByOption = action.payload;
      state.itemSearchPaginated.page = 1;
    },
    setItemsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setSelectedPlace(state, action: PayloadAction<ChosenPlace>) {
      state.selectedPlace = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInitialSearchResults.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchInitialSearchResults.fulfilled, (state, action) => {
        state.itemSearchPaginated = new ItemSearchDtoPaginationDto(action.payload.paginatedData); // Assuming the data comes in the payload directly
        state.topLevelCategories = action.payload.topLevelCategories ?? [];
        state.isLoading = false;
      })
      .addCase(fetchInitialSearchResults.rejected, (state, action) => {
        state.error = action.payload?.message ?? ""; // Assuming there's an error message in the payload
        state.isLoading = false;
      })
      .addCase(fetchNextPage.pending, (state) => {
        state.isLoadingNextPage = true;
      })
      .addCase(fetchNextPage.fulfilled, (state, action) => {
        state.isLoadingNextPage = false;
        const newItemSearchPaginated = new ItemSearchDtoPaginationDto(action.payload.paginatedData);
        const currentData: ItemSearchDto[] = [...state.itemSearchPaginated.data || []];
        state.itemSearchPaginated = new ItemSearchDtoPaginationDto({
          ...state.itemSearchPaginated,
          ...newItemSearchPaginated,
          data: [...currentData, ...newItemSearchPaginated.data ?? []]
        });
      })
      .addCase(fetchNextPage.rejected, (state, action) => {
        state.isLoadingNextPage = false;
        state.error = action.payload?.message ?? ""; // Assuming there's an error message in the payload
      })
      .addCase(fetchSearchResults.pending, (state) => {
        state.isItemsLoading = true;
      })
      .addCase(fetchSearchResults.fulfilled, (state, action) => {
        state.isItemsLoading = false;
        state.itemSearchPaginated = new ItemSearchDtoPaginationDto(action.payload.paginatedData); // Assuming the data comes in the payload directly
      })
      .addCase(fetchSearchResults.rejected, (state, action) => {
        state.isItemsLoading = false;
        state.error = action.payload?.message ?? ""; // Assuming there's an error message in the payload
      });
  }
});

export const {
  setSearchTerm,
  setSelectedCategoryId,
  setSortByOption,
  setItemsLoading,
  setSelectedPlace
} = searchSlice.actions;
export default searchSlice.reducer;