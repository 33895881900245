export const categoryFileNames: { [key: string]: string } = {
  "DVDs, Films & TV": "dvd-film-tv-pin-1.svg",
  "Cameras & Photography": "camera-photography-pin-1.svg",
  "Baby": "kids-and-baby-1.svg",
  "Parent & Baby": "kids-and-baby-1.svg",
  "Books, Comics & Magazines": "books-magazine-comic-pin-1.svg",
  "Musical Instruments": "instrument-pin-1.svg",
  "Pottery, Porcelain & Glass": "pottery-glass-ceramic-pin-1.svg",
  "Business, Office & Industrial": "business-office-&-industrial-pin-1.svg",
  "Computers/Tablets & Networking": "computer-tablet-networking-pin-1.svg",
  "Cars, Motorcycles & Vehicles": "cars-motorcycles-&-vehicles-pin-1.svg",
  "Clothes, Shoes & Accessories": "clothes-shoes-and-accessories-1.svg",
  "Holidays & Travel": "holiday-and-travel-1.svg",
  "Toys & Games": "toys-games-pin-1.svg",
  "Jewellery & Watches": "jewellery-watches-pin-1.svg",
  "Sound & Vision": "vision-sound-pin-1.svg",
  "Garden & Patio": "garden-patio-pin-1.svg",
  "Music": "music-pin-1.svg",
  "Home, Furniture & DIY": "home-furniture-diy-pin-1.svg",
  "Sporting Goods": "sporting-goods-pin-1.svg",
  "Crafts": "crafts-pin-1.svg",
  "Mobile Phones & Communication": "mobile-phone-communication-pin-1.svg",
  "Video Games & Consoles": "video-game-consoles-pin-1.svg",
  "Pet Supplies": "pet-supplies-pin-1.svg",
  "Pets & Livestock": "pet-supplies-pin-1.svg",
  "Everything Else": "everything-1.svg",
  "Health & Beauty": "health-beauty-pin-1.svg",
  "Power Tools": "power-tools-1.svg"
};