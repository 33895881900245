import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Form } from "reactstrap";
import { toast } from "react-toastify";

import MultiLayeredCategoriesSelectorModal from "../components/common/MultiLayeredCategoriesSelector";
import {
  AspectSimpleDto,
  CancellationPolicy,
  CategoryClient,
  IConfig,
  ImageDto,
  InsurancePlanDto,
  Item,
  ItemClient,
  ItemCreateDto,
  Location,
  Price,
  PricePeriod,
  TermsClient,
  UserClient,
} from "../api/rentMyApi";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import CancellationPolicySelector from "../components/item/CancellationPolicySelector";
import { CategoryInfo } from "../models/interfaces/CategoryInfo";
import ItemSnippet from "../components/common/ItemSnippet";
import GetCoordinatesFromAddress from "../components/common/GetCoordinatesFromAddress";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShieldHalved } from "@fortawesome/free-solid-svg-icons";
import { AccordionState } from "../models/interfaces/AccordionState";
import ListingCancellationSelector from "../components/item/ListingCancellationSelector";
import {
  availabilityValuesDefault,
  DefaultErrors,
  getCancellationPolicyDefault,
  getDefaultErrors,
  getTopError,
  ItemValueDefault,
  STANDARD_PRICE_PERIOD_NAME,
} from "../components/item/ItemListingConstantsAndDefaults";
import { Toggle } from "../models/interfaces/toggle";
import { cloneDeep } from "lodash";
import { useUserContext } from "../context/UserContext";
import SharersGuarantee from "../components/item/SharersGuarantee";
import BasicModal from "../components/common/BasicModal";
import { Terms } from "../components/terms/Terms";
import AboutYourListing from "../components/item/AboutYourListing";
import SaveOptions from "../components/item/SaveOptions";
import AspectSectionGeneration from "../components/item/AspectSectionGeneration";
import ItemListingTitle from "../components/item/ItemListingTitle";
import UploadProductImage from "../components/item/UploadProductImage";
import ItemCostAndValue from "../components/item/ItemCostAndValue";
import { ItemValueProperties } from "../models/interfaces/ItemValueProperties";
import {
  createListingAnalytics,
  sendCreateListingEvent,
} from "../api/analytics";
import { DateRangesInBackendFormat } from "../components/common/DateFormatting";
import { termsOfServiceType } from "../components/terms/TermsRedirect";
import ItemAddress from "../components/item/ItemAddress";
import { PricePeriodError } from "../models/interfaces/PricePeriodEnhanced";
export function ItemListingPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const isEditing = Boolean(id);
  const { user } = useUserContext();

    const [weeklyDiscount,
        setWeeklyDiscount] = useState<Number | undefined>(0);

    const [monthlyDiscount,
        setMonthlyDiscount] = useState<Number | undefined>(0);



  const [modalState, setModalState] = useState(false);
  const [modalStateTerms, setModalStateTerms] = useState(false);
  const [modalCancellation, setModalCancellation] = useState(false);
  const [modalInsurance, setModalInsurance] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const toggleModalTerms = () => {
    setModalStateTerms(!modalStateTerms);
  };

  const [itemCreatePayload, setItemCreatePayload] = useState<ItemCreateDto>();

  const [isPublished, setIsPublished] = useState<Item["active"]>(true);
  const [name, setName] = useState<ItemCreateDto["name"]>("");
  const [description, setDescription] = useState<string | undefined>("");
  const [quantity, setQuantity] = useState<ItemCreateDto["quantity"]>(1);
  const [extensionLimitDays, setExtensionLimitDays] =
    useState<ItemCreateDto["extensionLimitDays"]>(undefined);
  const [extensionLimitHours, setExtensionLimitHours] =
    useState<ItemCreateDto["extensionLimitHours"]>(undefined);

  const [category, setCategory] = useState<CategoryInfo>();
  const [uploadedImages, setUploadedImages] = useState<ImageDto[]>([]);

  // Aspects
  const [aspectsInItemFormat, setAspectsInItemFormat] = useState<{
    [key: string]: string[];
  }>({});
  const [showMoreAspects, setShowMoreAspects] = useState<boolean>(false);
  const lengthBeforeAspectCutOff = 4;

  const [imagesCopy, setImagesCopy] = useState<string[]>([]);
  const [categoryCopy, setCategoryCopy] = useState<string>();
  const [locationCopy, setLocationCopy] = useState<Location>(new Location());

  const [categoriesModalState, setCategoriesModalState] = useState(false);

  const [standardPricePeriod, setStandardPricePeriod] = useState<PricePeriod>(
    new PricePeriod()
  );

  const [locationData, setLocationData] = useState<Location>(new Location());

  const [isProcessing, setIsProcessing] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const [itemClient, setItemClient] = useState<ItemClient>(
    new ItemClient(new IConfig("notoken"), process.env.REACT_APP_API_ENDPOINT)
  );
  const [categoryClient, setCategoryClient] = useState(
    new CategoryClient(
      new IConfig("notoken"),
      process.env.REACT_APP_API_ENDPOINT
    )
  );
  const [userClient, setUserClient] = useState<UserClient>(
    new UserClient(new IConfig("notoken"), process.env.REACT_APP_API_ENDPOINT)
  );
  const [clientInitialised, setClientInitialised] = useState(false);
  const [insuranceRequired, setInsuranceRequired] = useState(false);

  const [errors, setErrors] = useState<DefaultErrors>(getDefaultErrors());

  const [wantWeekdays, setWantWeekdays] = useState(true);
  const [wantWeekends, setWantWeekends] = useState(true);
  const [wantInstantBooking, setWantInstantBooking] = useState(true);

  // Insurance
  const [insurancePlans, setInsurancePlans] = useState<
    InsurancePlanDto[] | null
  >(null);
  const [ChosenInsuranceId, setChosenInsuranceId] = useState<
    string | undefined
  >(undefined);

  // Availability
  const [availabilityValues, setAvailabilityValues] = useState<Toggle[]>(
    availabilityValuesDefault
  );

  // Cancellation
  const [accordionState, setAccordionState] = useState<AccordionState[]>(
    getCancellationPolicyDefault(CancellationPolicy.Flexible)
  );

  const [rootCategories, setRootCategories] = useState<CategoryInfo[]>([]);

  const [itemValueProperties, setItemValueProperties] =
    useState<ItemValueProperties>(ItemValueDefault());

  // Aspects
  const defaultOptionValue = t("item_listing_default_aspect_value");
  const [allAspectsFromCategory, setAllAspectsFromCategory] =
    useState<AspectSimpleDto[]>();

  const [hasDeposit, setHasDeposit] = useState<boolean>(false);
  const [termsClient, setTermsClient] = useState<TermsClient>();

  const [triggerKey, setTriggerKey] = useState("");

  const [typing, setTyping] = useState(false);

    const title = location.state?.title || 'Item';

  const restartTypingEffect = (text: string) => {
    setDescription(text);
    setTyping(true);
    };

    useEffect(() => {
        document.title = `RentMy ${title}`;
    }, [title])

  useEffect(() => {
    async function initClient() {
      const token = await getAccessTokenSilently();
      setItemClient(
        new ItemClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT)
      );
      setTermsClient(
        new TermsClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT)
      );
      setCategoryClient(
        new CategoryClient(
          new IConfig(token),
          process.env.REACT_APP_API_ENDPOINT
        )
      );
      const authenticatedUserClient = new UserClient(
        new IConfig(token),
        process.env.REACT_APP_API_ENDPOINT
      );
      setUserClient(authenticatedUserClient);
      setClientInitialised(true);
    }

    initClient();
  }, []);

  useEffect(() => {
    if (category) {
      aspectSection();
    }
  }, [category]);

  useEffect(() => {
    if (clientInitialised && user.id) {
      // console.log("initialised")
      const fetchItem = async () => {
        // console.log("Fetching Item")
        const categoriesData = await categoryClient
          .category2(
            false,
            true,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            100
          )
          .then((response) =>
            (response.data || []).map((category) => ({
              id: category.id,
              name: category.name || "",
            }))
          )
          .catch((e) => {
            return [];
          });
        setRootCategories(categoriesData);
        if (isEditing) {
          try {
            const item = await itemClient.detailed23(id!);
            if (item.aspects) {
              setAspectsInItemFormat(item.aspects);
            }

            if (item.user.id !== user.id) {
              toast.error("Unauthorised content");
              navigate("/");
            }

            if (
              item &&
              item.priceInfo &&
              item.priceInfo.periods &&
              item.priceInfo.periods[0]
            )
              setStandardPricePeriod(item.priceInfo.periods[0]);

            let wantWeekdays = true;
            let wantWeekends = true;

            if (standardPricePeriod) {
              const allDayOverrides: string[] = [];
              let weekendOverride = new Price();
              standardPricePeriod.dayOverrides?.forEach(({ day, price }) => {
                if (day === "Sunday") {
                  weekendOverride = new Price(price);
                }
                allDayOverrides.push(day);
              });

              const hasSeparateWeekendPrice =
                allDayOverrides.length === 2 && Boolean(weekendOverride.daily);
              wantWeekdays =
                allDayOverrides.length === 0 || allDayOverrides.length === 2;
              wantWeekends =
                allDayOverrides.length === 0 ||
                allDayOverrides.length === 5 ||
                hasSeparateWeekendPrice;
            }

            const { receipt, originalValue, itemValue, purchaseDate } = item;
            setInsuranceRequired(item.requireInsurance);
            setItemValueProperties({
              receipt,
              originalValue: originalValue || 0,
              itemValue: itemValue || 0,
              purchaseDate: purchaseDate || new Date(),
            });
            // console.log("Item Status", item.isPublished)
            setIsPublished(item.isPublished);
            setName(item.name);
            setDescription(item.description);
            setQuantity(item.quantity);
            setExtensionLimitDays(item.extensionLimitDays);
            setExtensionLimitHours(item.extensionLimitHours);

            let currentCategories: CategoryInfo | undefined;

            if (item.categories && item.categories.length > 0) {
              currentCategories = {
                id: item.categories[0].id,
                name: item.categories[0].name!,
                noChildren: false,
              };
              setCategory(currentCategories);
            }

            setUploadedImages(item.images || []);
            setWantWeekdays(wantWeekdays);
            setWantWeekends(wantWeekends);

            setLocationData(new Location(item.location));
            setWantInstantBooking(item.instantBooking);
            setAccordionState(
              getCancellationPolicyDefault(
                CancellationPolicy[item.cancellationPolicy]
              )
            );

            if (item.categories && item.categories.length > 0) {
              setCategoryCopy(item.categories[0].id);
            }
            setImagesCopy((item.images || []).map((image) => image.id));
            setLocationCopy(new Location(item.location));
          } catch (error: any) {
            console.log(error);
            toast.error("Cannot Find Item");
          }
        } else {
          setStandardPricePeriod({
            ...standardPricePeriod,
            name: STANDARD_PRICE_PERIOD_NAME,
            dateRanges: DateRangesInBackendFormat,
          } as PricePeriod);
        }
      };
      fetchItem();
    }
  }, [clientInitialised, user.id]);

  const postListing = async (isActive: boolean) => {
    setIsProcessing(true);
    const shouldChangePublishedState = isActive !== isPublished;

    if (!isSubmitting) {
      setIsSubmitting(true);
      const newErrors = getDefaultErrors();
      if (!uploadedImages.length) {
        newErrors.images = true;
      }
      if (!name) {
        newErrors.name = true;
      }
      if (!description) {
        newErrors.description = true;
      }
      if (!locationData.addressLine1) {
        newErrors.location.addressLine1 = true;
      }
      if (!locationData.city) {
        newErrors.location.city = true;
      }
      if (!locationData.country) {
        newErrors.location.country = true;
      }
      if (!locationData.postcode) {
        newErrors.location.postcode = true;
      }
      const { itemValue, originalValue, purchaseDate } = itemValueProperties;
      if (!itemValue) {
        newErrors.itemValueProperties.itemValue = true;
      }
      if (!originalValue) {
        newErrors.itemValueProperties.originalValue = true;
      }
      if (!purchaseDate) {
        newErrors.itemValueProperties.purchaseDate = true;
      }
      if (!category) {
        newErrors.category = true;
      }
      if (!availabilityValues.some((availability) => availability.isSelected)) {
        newErrors.availability = true;
      }
      if (hasDeposit && !standardPricePeriod.price.deposit) {
        newErrors.bookingTerms.deposit = true;
      }
      newErrors.standardPeriodsErrors = [
        {
          minimumAndMaximumDateOverlap: !!(
            standardPricePeriod.maximumBookingDays &&
            standardPricePeriod.minimumBookingDays &&
            Number(standardPricePeriod.minimumBookingDays) >
              Number(standardPricePeriod.maximumBookingDays ?? undefined)
          ),
          pricePeriodDailyPriceIsIncorrect: !(
            standardPricePeriod &&
            standardPricePeriod.price &&
            standardPricePeriod.price.daily
          ),
        },
      ];
      setErrors(newErrors);
      const firstError = getTopError(newErrors);
      if (firstError) {
        setIsSubmitting(false);
        setIsProcessing(false);
        return scrollToMostTopInvalidField(firstError);
      }

      let locationEnhanced = new Location({
        name: locationData.name,
        addressLine1: locationData.addressLine1,
        addressLine2: locationData.addressLine2,
        addressLine3: locationData.addressLine3,
        city: locationData.city,
        country: locationData.country,
        county: locationData.county,
        postcode: locationData.postcode,
        longitude: locationData.longitude,
        latitude: locationData.latitude,
      });
      // if no latitude or no longitude
      // try to fetch them using
      if (!locationData.latitude || !locationData.longitude) {
        const query = `${locationData.postcode} ${locationData.addressLine1} ${locationData.city} ${locationData.country}`;
        const coordinates = await GetCoordinatesFromAddress(query);
        locationEnhanced = new Location({
          ...locationEnhanced,
          ...coordinates,
        });
      }
      const imageIds = uploadedImages.map((image) => image.id);
      const cancellationPolicyKey =
        accordionState.find((policy: AccordionState) => policy.toggleSelected)
          ?.value || CancellationPolicy.Flexible;
      const policyValue =
        CancellationPolicy[
          cancellationPolicyKey as keyof typeof CancellationPolicy
        ];
      const enableInsurance =
        process.env.REACT_APP_SHOW_INSURANCE &&
        /true/.test(process.env.REACT_APP_SHOW_INSURANCE);
      if (id) {
        try {
          // console.log("Insurance required", insuranceRequired)
          // check if item has insurance available
          if (
            enableInsurance &&
            insurancePlans &&
            insurancePlans[0] &&
            insurancePlans[0].id
          ) {
            if (insuranceRequired) {
              itemClient
                .addInsurance2(id, insurancePlans[0].id)
                .then(() => {
                  if (ChosenInsuranceId) {
                    return itemClient.changeRequireInsurance(id);
                  }
                })
                .then(() => {
                  if (ChosenInsuranceId) {
                    toast.success("Insurance Successfully Added");
                  }
                })
                .catch((error) => {
                  toast.error("Insurance not successfully Added");
                  console.error(error);
                });
            } else {
              itemClient
                .changeRequireInsurance(id)
                .then(() => {
                  toast.success("Insurance Successfully Removed");
                })
                .catch((error) => {
                  toast.error("Insurance not successfully Added");
                  console.error(error);
                });
            }
          }
          const imageIdsToDelete: string[] = imagesCopy.filter(
            (id) => !imageIds.includes(id)
          );
          const imageIdsToUpload: string[] = imageIds.filter(
            (id) => !imagesCopy.includes(id)
          );
          if (imageIdsToDelete.length) {
            await itemClient.removeImages(id, imageIdsToDelete);
          }
          if (imageIdsToUpload.length) {
            await itemClient.addImages2(id, imageIdsToUpload);
          }
          if (categoryCopy && categoryCopy !== category!.id) {
            await itemClient.removeCategories(id, [categoryCopy]);
            await itemClient.addCategories(id, [category!.id]);
          }
          await itemClient.changeAspects(id, aspectsInItemFormat);
          const isSame = Object.keys(locationEnhanced).every((key) => {
            const castedKey = key as keyof Location;
            return locationCopy[castedKey] === locationEnhanced[castedKey];
          });
          if (!isSame) {
            try {
              await itemClient.removeLocations(id, [locationCopy]);
            } catch (error) {
              console.log("ERROR", error);
            }
            try {
              await itemClient.addLocations(id, [locationEnhanced]);
            } catch (error) {
              toast.error("Please provide a valid address");
              return;
            }
          }
          await itemClient.pricing(id, [standardPricePeriod]);
          const { itemValue, originalValue, purchaseDate, receipt } =
            itemValueProperties;
          if (id && uploadedImages.length) {
            await itemClient.changeFrontImage(
              id,
              uploadedImages.map((image) => image.id)[0]
            );
          }
          const _payload = {
            imageIds: uploadedImages.map((image) => image.id), // Assuming you want to send this as a comma-separated string
            name: name!,
            description: description!,
            quantity: quantity.toString(),
            extensionLimitDays: extensionLimitDays,
            extensionLimitHours: extensionLimitHours,
            instantBooking: wantInstantBooking.toString(),
            cancellationPolicy: policyValue,
            originalValue: originalValue?.toString() ?? "",
            itemValue: itemValue?.toString() ?? "",
            purchaseDate: purchaseDate.toUTCString(),
            receipt: receipt.toString(),
            requireInsurance: insuranceRequired.toString(), // Assuming insuranceRequired is a boolean
          };

          const payload: { [key: string]: string } = {};

          for (const [key, value] of Object.entries(_payload)) {
            if (value) {
              if (typeof value === "string") {
                payload[key] = value;
              }
            }
          }
          await itemClient.update12(id!, payload);
          try {
            if (shouldChangePublishedState) {
              await itemClient.publish(id!);
            }
          } catch (error: any) {
            console.log(error.detail);
            toast.error(
              t(
                "The Item you are trying to unpublish has either an ongoing booking or upcoming bookings, please cancel them first and try again."
              )
            );
            throw error;
          }
          const item = await itemClient.detailed23(id!);
          setIsPublished(item.isPublished);
          toast.success(t("item_listing_updated_successfully"));
          navigate("/account/my-items");
          setIsProcessing(false);
          setIsSubmitting(false);
        } catch (error) {
          console.log(error);
          // console.log(error);
          toast.error(t("item_listing_update_error"));
          setIsProcessing(false);
          setIsSubmitting(false);
        }
      } else {
        const { originalValue, itemValue, purchaseDate, receipt } =
          itemValueProperties;
        const payload = new ItemCreateDto({
          tenantId: undefined,
          isPublished: isActive,
          active: true,
          name,
          description,
          quantity,
          pricePeriods: [standardPricePeriod],
          extensionLimitDays,
          extensionLimitHours,
          categoryIds: [category!.id],
          collectionIds: [],
          aspects: aspectsInItemFormat,
          instantBooking: wantInstantBooking,
          locations: [locationEnhanced],
          imageIds: uploadedImages.map((image) => image.id),
          videoIds: undefined,
          cancellationPolicy: policyValue,
          originalValue,
          itemValue,
          purchaseDate,
          receipt,
          minimumHandOverImages: 1,
          insurancePlanIds: ChosenInsuranceId ? [ChosenInsuranceId] : [],
          requireInsurance: insuranceRequired,
        });

        setItemCreatePayload(payload);

        termsClient!
          .accepted2(termsOfServiceType)
          .then((x) => {
            if (!x.terms.active) {
              setModalStateTerms(true);
              setIsProcessing(false);
              return;
            }
            itemClient!
              .createPOST11(user.id, payload)
              .then((response) => {
                const itemId = response.id;
                console.log("itemid", itemId);
                console.log("Insurance id", ChosenInsuranceId);
                console.log("adding insurance");

                if (
                  enableInsurance &&
                  insurancePlans &&
                  insurancePlans[0] &&
                  insurancePlans[0].id
                ) {
                  itemClient
                    .addInsurance2(itemId, insurancePlans[0].id)
                    .then(() => {
                      if (ChosenInsuranceId) {
                        itemClient
                          .changeRequireInsurance(itemId)
                          .then((response) => {
                            console.log("Response", response);
                            toast.success(
                              "Insurance Plan successfully required and is added"
                            );
                          });
                      } else {
                        toast.success("Insurance Plan successfully Added");
                      }
                    })

                    .catch((error) => {
                      toast.error("Insurance not successfully Added");
                      console.error(error);
                    });
                }

                // console.log("create listing event")
                const itemName = payload.name;
                const itemID = "";
                const accountID = user.id;
                const location = payload.locations;
                const isMobile = /Mobile/i.test(navigator.userAgent);
                const device = isMobile ? "Mobile" : "Desktop";
                sendCreateListingEvent(
                  itemName,
                  itemID,
                  accountID,
                  location,
                  device
                );
                createListingAnalytics({}); //old pixel
                setIsProcessing(false);
                toast.success(
                  payload.isPublished
                    ? t("item_listing_created_successfully_publish")
                    : t("item_listing_created_successfully_save")
                );
                navigate("/account/my-items");
              })
              .catch((error: any) => {
                setIsProcessing(false);
                if (payload.isPublished) {
                  toast.error(t("publish_error"));
                } else {
                  toast.error(t("save_error"));
                }
              });
          })
          .catch((ex) => {
            setIsProcessing(false);
            setIsSubmitting(false);
            setModalStateTerms(true);
          });
      }
    }
  };

  const scrollToMostTopInvalidField = (field: string): void => {
    let elementId;
    if (field === "name") {
      elementId = "title-section";
    } else if (field === "images") {
      toast.error("You must provide at least one image for your item");
      elementId = "top-of-page";
      //    scroll to top
    } else if (field === "description") {
      elementId = "description-section";
    } else if (field === "location") {
      elementId = "location-section";
    } else if (field === "category") {
      toast.error("You must provide a category for your item");
      elementId = "title-section";
    } else if (field === "availability") {
      elementId = "availability-section";
    } else if (field === "standardPeriodsErrors") {
      elementId = "standard-price-period-section";
    } else if (field === "customPeriodErrors") {
      elementId = "price-period-section";
    } else if (field === "terms") {
      elementId = "booking-terms-section";
    } else if (field === "itemValueProperties") {
      elementId = "item-cost-and-value-section";
    }

    document.getElementById(elementId ?? "")?.scrollIntoView();
    setIsSubmitting(false);
    setIsProcessing(false);
  };

  const resetPricePeriodError = (
    index: number,
    field: keyof PricePeriodError,
    periodType: "standardPeriodsErrors" | "customPeriodErrors"
  ) => {
    if (errors[periodType][index]) {
      const clone = cloneDeep(errors);
      // no field means period is removed
      if (!field) {
        clone[periodType].splice(index, 1);
      } else {
        // @ts-ignore
        clone[periodType][index][field] = false;
      }

      setErrors(clone);
    }
  };

  const updateItemValuePropertiesError = (
    field: keyof DefaultErrors["itemValueProperties"]
  ) => {
    if (errors.itemValueProperties[field]) {
      const clone = cloneDeep(errors);
      clone.itemValueProperties[field] = false; // Update the specific field
      setErrors(clone);
    }
  };

  const aspectSection = (): void => {
    if (category) {
      categoryClient
        .detailed3(category.id)
        .then((cat) => {
          if (cat.aspects) {
            setAllAspectsFromCategory(
              cat.aspects.length > 0 ? cat.aspects : undefined
            );
            setInsurancePlans(cat.category.insurancePlans ?? null);
          }
        })
        .catch((e) => {
          // console.error("Problem getting aspects for category with id: " + category.id); // here as well, category selector been changed.
        });
    }
  };

  function setSelectedCategories() {
    return (value: CategoryInfo[]) => {
      setCategory(value[0]);
      setAspectsInItemFormat({});
      setErrors({
        ...errors,
        category: false,
      });
    };
  }

  function termsModal() {
    return Terms({
      withAcceptButton: true,
      itemListingPayload: itemCreatePayload,
      itemBookingPayload: undefined,
      isLogin: undefined,
    });
  }

  return (
    <>
      <Container className="mt-4">
        <Form className="item-listing-form" id="top-of-page">
          <ItemListingTitle isEditing={isEditing} t={t} />
          <UploadProductImage
            uploadedImages={uploadedImages}
            setUploadedImages={setUploadedImages}
            setIsProcessing={setIsProcessing}
            isProcessing={isProcessing}
          />

          <AboutYourListing
            t={t}
            name={name ?? ""}
            setName={setName}
            errors={errors}
            setErrors={setErrors}
            setDescription={setDescription}
            description={description ?? ""}
            category={category ? [category] : []}
            setCategoryModalState={setCategoriesModalState}
            triggerKey={triggerKey}
            typing={typing}
            setTyping={setTyping}
          />

          {isEditing && allAspectsFromCategory && (
            <AspectSectionGeneration
              defaultOptionValue={defaultOptionValue}
              showMoreAspects={showMoreAspects}
              allAspectsFromCategory={allAspectsFromCategory}
              lengthBeforeAspectCutOff={lengthBeforeAspectCutOff}
              setShowMoreAspects={setShowMoreAspects}
              t={t}
              aspectsInItemFormat={aspectsInItemFormat}
              setAspectsInItemFormat={setAspectsInItemFormat}
              category={category}
            />
          )}

          <ItemAddress
            errors={errors}
            setErrors={setErrors}
            locationData={locationData}
            setLocationData={setLocationData}
          />

            <ItemCostAndValue
                      weeklyDiscount={weeklyDiscount }
                      setWeeklyDiscount={setWeeklyDiscount}
                      monthlyDiscount={monthlyDiscount}
                      setMonthlyDiscount={setMonthlyDiscount }

            disabled={!category}
            isEditing={isEditing}
            updateErrors={(index: number, field: any) =>
              updateItemValuePropertiesError(field)
            }
            setItemValueProperties={setItemValueProperties}
            itemValueProperties={itemValueProperties}
            fieldsValidation={errors.itemValueProperties}
            pricePeriodFormValidation={errors.standardPeriodsErrors}
            pricePeriod={standardPricePeriod}
            updatePricePeriodErrors={(index: number, field: any) =>
              resetPricePeriodError(index, field, "standardPeriodsErrors")
            }
            setPricePeriod={setStandardPricePeriod}
            hasDeposit={hasDeposit}
            setHasDeposit={setHasDeposit}
            insurancePlans={insurancePlans}
            ChosenInsuranceId={ChosenInsuranceId}
            setChosenInsuranceId={setChosenInsuranceId}
            insuranceRequired={insuranceRequired}
            setInsuranceRequired={setInsuranceRequired}
          />

          <ItemSnippet
            wantInCard={true}
            text={{
              title: t("item_listing_instant"),
              text: "",
              secondaryText:
                "Allows renters to book your item instantly, without you having to confirm first.",
            }}
            toggleSetup={{
              value: wantInstantBooking,
              setValue: setWantInstantBooking,
              disabled: false,
            }}
          />

          <CancellationPolicySelector
            toggleModal={setModalCancellation}
            setAccordionState={setAccordionState}
            accordionState={accordionState}
          />

          {isEditing && (
            <ItemSnippet
              wantInCard={true}
              icon={{
                iconElement: <FontAwesomeIcon icon={faShieldHalved} />,
                wantIconToBeCircle: true,
              }}
              text={{
                title: t("item_listing_sharers_guarantee"),
                text: "",
                secondaryText: "",
              }}
              buttonSetup={{
                text: t("view"),
                onClick: () => setModalState(!modalState),
                buttonOutline: true,
              }}
            />
          )}

          {isEditing && (
            <SharersGuarantee
              modalState={modalState}
              setModalState={setModalState}
            />
          )}

          <SaveOptions
            isProcessing={isProcessing}
            setIsProcessing={setIsProcessing}
            isEditing={isEditing}
            postListing={postListing}
            isPublished={isPublished}
            t={t}
          />
        </Form>

        <BasicModal
          modalBody={termsModal()}
          modalState={modalStateTerms}
          toggleModal={toggleModalTerms}
        />
        <BasicModal
          modalBody={
            <ListingCancellationSelector accordionState={accordionState} />
          }
          modalState={modalCancellation}
          toggleModal={() => setModalCancellation(false)}
        />
        <BasicModal
          modalBody={<div>Example Insurance </div>}
          modalState={modalInsurance}
          toggleModal={() => setModalInsurance(false)}
        />

        <MultiLayeredCategoriesSelectorModal
          categoryClient={categoryClient}
          rootCategories={rootCategories}
          justOneSelectedCategoryAllowed={true}
          selectedCategories={category ? [category] : []}
          setSelectedCategories={setSelectedCategories()}
          modalState={categoriesModalState}
          toggleModal={() => setCategoriesModalState(!categoriesModalState)}
        />
      </Container>
    </>
  );
}
