import * as React from "react";
import { useEffect, useState } from "react";
import {
  BookingClient,
  IConfig,
  PricePeriod,
  UpcomingBookingsDto,
} from "../../api/rentMyApi";
import DateRangePicker from "./DateRangePicker";
import { Range } from "react-date-range";
import { DateRangePickerDirectionEnum } from "./DateRangePickerDirectionEnum";
import { getAllDaysInclusiveOfTwoDates } from "./DateFormatting";
import { useLocation } from "react-router-dom";

const tomorrow = new Date(Date.now() + 24 * 60 * 60 * 1000); // Add one day to today's date

const defaultMonths = 2;

export default function DateRangePickerPricePeriodDisabling({
  dateRangeState,
  setDateRangeState,

  removeInputSection = false,
  months = defaultMonths,
  direction = DateRangePickerDirectionEnum.VERTICAL,
  minDate = tomorrow,
  startDatePlaceholder = "Start Date",
  endDatePlaceholder = "End Date",
  showClearButton = true,
  setRemoveDateRangeInput,
  pricePeriods,
  itemId,
}: {
  dateRangeState: Range;
  setDateRangeState: React.Dispatch<any>;
  removeInputSection?: boolean;
  months?: number;
  direction?: DateRangePickerDirectionEnum;
  minDate?: Date;
  disabledDates?: Date[];
  startDatePlaceholder?: string;
  endDatePlaceholder?: string;
  showClearButton?: boolean;
  setRemoveDateRangeInput?: React.Dispatch<React.SetStateAction<boolean>>;
  pricePeriods: PricePeriod[];
  itemId: string;
}) {
  const { search } = useLocation();

  const [bookingClient] = useState<BookingClient>(
    new BookingClient(
      new IConfig("notoken"),
      process.env.REACT_APP_API_ENDPOINT
    )
  );

  const [disabledDates, setDisabledDates] = useState<Date[]>([]);

  useEffect(() => {
    generateDisabledDates();
  }, [pricePeriods]);

  const generateDisabledDates = async () => {
    let disabledDatesClone: Date[] = [];

    const datesOnBookings =
      await updateDisabledDatesBasedOnUpcomingBookingsAsync(itemId);
    if (datesOnBookings) {
      disabledDatesClone = disabledDatesClone.concat(datesOnBookings);
    }

    setDisabledDates(disabledDatesClone);
  };

  const updateDisabledDatesBasedOnUpcomingBookingsAsync = async (
    itemId: string
  ): Promise<Date[]> => {
    try {
      const itemIdStr = itemId;

      if (!itemIdStr) {
        return [] as Date[];
      }

      return bookingClient
        .upcoming(itemIdStr)
        .then((upcomingBookings: UpcomingBookingsDto) => {
          if (!upcomingBookings || !upcomingBookings.upcomingBookings) {
            return [] as Date[];
          }

          let additionalDates: Date[] = [];

          for (const booking of upcomingBookings.upcomingBookings) {
            const allDatesInRangeInclusive = getAllDaysInclusiveOfTwoDates(
              booking.startingDate,
              booking.endingDate
            );
            additionalDates = additionalDates.concat(allDatesInRangeInclusive);
          }

          return additionalDates;
        })
        .catch((e) => {
          // console.error("Can't get upcoming bookings")
          return [] as Date[];
        });
    } catch (e) {
      return [] as Date[];
    }
  };

  return (
    <DateRangePicker
      dateRangeState={dateRangeState}
      setDateRangeState={setDateRangeState}
      removeInputSection={removeInputSection}
      months={months}
      direction={direction}
      minDate={minDate}
      disabledDates={disabledDates}
      startDatePlaceholder={startDatePlaceholder}
      endDatePlaceholder={endDatePlaceholder}
      showClearButton={showClearButton}
      setRemoveDateRangeInput={setRemoveDateRangeInput}
      maxNumberOfDays={pricePeriods.length > 0 ? pricePeriods[0].maximumBookingDays : undefined}
      minNumberOfDays={pricePeriods.length > 0 ? pricePeriods[0].minimumBookingDays : undefined}
    />
  );
}
