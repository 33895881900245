import moment from "moment";

export const messageUrlConst = "/account/messages";
export const subjectSearchParamsConst = "subject";
export const recipientSearchParamsConst = "recipient";
export const messageSearchParamsConst = "message";
export const bookingTypeSearchParamsConst = "booking";
export const itemTypeSearchParamsConst = "item";
const subjectIdSeperatorCharSearchParamsConst = "_";

const prettyDateMomentFormat = "Do [of] MMMM";
const dateMiddleAddition = " to the ";

// BookingId makes this conversation unique between two users
export function gotoMessagesFromBookingDetailsPage(
  bookingName: string,
  bookingId: string,
  bookingStartDate: Date,
  bookingEndDate: Date
) {
  return `${bookingName.replace("/", "")} - ${moment(bookingStartDate).format(
    prettyDateMomentFormat
  )}${dateMiddleAddition}${moment(bookingEndDate).format(
    prettyDateMomentFormat
  )}${subjectIdSeperatorCharSearchParamsConst}${bookingId}${subjectIdSeperatorCharSearchParamsConst}${bookingTypeSearchParamsConst}`;
}

// Combination of itemId and currentlyLoggedInUserId makes this conversation unique between two users
export function gotoMessagesFromItemDetailedPage(
  itemId: string,
  itemName: string,
  currentlyLoggedInUser: string
) {
  return `${itemName.replace("/", "")}${subjectIdSeperatorCharSearchParamsConst}${currentlyLoggedInUser}${subjectIdSeperatorCharSearchParamsConst}${itemId}${subjectIdSeperatorCharSearchParamsConst}${itemTypeSearchParamsConst}`;
}

export function getBookingIdFromSubject(subject: string): string {
  const bookingIdIndexFromEnd = 2;

  const subjectSplit = subject.split(subjectIdSeperatorCharSearchParamsConst);
  const bookingId = subjectSplit[subjectSplit.length - bookingIdIndexFromEnd];

  return `/account/my-bookings?id=${bookingId}`;
}

export function getItemUrlFromSubject(subject: string): string {
  const bookingIdIndexFromEnd = 2;

  const subjectSplit = subject.split(subjectIdSeperatorCharSearchParamsConst);
  const itemId = subjectSplit[subjectSplit.length - bookingIdIndexFromEnd];

  return `/item/${itemId}`;
}

export function getConversationTypeFromSubject(subject: string): string {
  if (subject) {
    return subject.slice(
      subject.lastIndexOf(subjectIdSeperatorCharSearchParamsConst) + 1
    );
  }
  return "";
}

export function subjectInUserReadableFormat(
  subject: string,
  username?: string | undefined
): string {
  const itemExtraNonReadableSectionsInSubject = 3;
  const bookingExtraNonReadableSectionsInSubject = 2;
  let extraNonReadableSectionsInSubject = 0;

  const type = subject.substring(
    subject.lastIndexOf(subjectIdSeperatorCharSearchParamsConst) + 1
  );
  if (type === bookingTypeSearchParamsConst) {
    extraNonReadableSectionsInSubject =
      bookingExtraNonReadableSectionsInSubject;
  } else if (type === itemTypeSearchParamsConst) {
    extraNonReadableSectionsInSubject = itemExtraNonReadableSectionsInSubject;
  }

  let disolvingTitle = subject;
  for (let i = 0; i < extraNonReadableSectionsInSubject; i++) {
    disolvingTitle = disolvingTitle.substring(
      0,
      disolvingTitle.lastIndexOf(subjectIdSeperatorCharSearchParamsConst)
    );
  }

  if (username) {
    return username + " - " + disolvingTitle;
  } else {
    return disolvingTitle;
  }
}
