import { Button, Modal } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

interface MakeClaimModalProps {
  isOpen: boolean;
  onClose: () => void;
  onProceedWithClaim: () => void;
  onGoBack: () => void;
}

const MakeClaimModal = ({
  isOpen,
  onClose,
  onProceedWithClaim,
  onGoBack,
}: MakeClaimModalProps) => {
  const makeClaimDisclaimer = () => (
    <>
      <h3>Making an Insurance Claim</h3>
      <p>
        Before you make a claim, please ensure you have:
        <ul>
          <li>Evidence of any damage;</li>
          <li>A crime reference in cases of theft;</li>
          <li>Quote for the items repair or replacement;</li>
        </ul>
      </p>
      <p>
        By proceeding you agree to the policy terms and conditions. Making a
        claim that is fraudulent false or exaggerated may result in us rejecting
        your claim, recovering our costs from you and passing your details to
        fraud prevention and law enforcement agencies. Other insurers may also
        access this information.
      </p>
    </>
  );

  return (
    <Modal
      isOpen={isOpen}
      centered
      size="sm"
      toggle={onClose}
      className="modal-with-image"
    >
      <button className="modal-with-image-close-button" onClick={onClose}>
        <FontAwesomeIcon icon={faXmark} />
      </button>
      <div className="container-fluid">
        <div className="row">
          <div className="col modal-with-image-div">
            {makeClaimDisclaimer()}
            <div className="flex-display-row padding-top-10">
              <Button
                color="primary"
                onClick={onGoBack}
                className="modal-button"
              >
                Go Back
              </Button>
              <Button
                color="primary"
                onClick={onProceedWithClaim}
                className="modal-button"
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MakeClaimModal;
