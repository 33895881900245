import { Col, Row } from 'reactstrap';
import { QuotallClaimDto } from '../../../api/rentMyApi';
import { LoadingFullSide } from '../../common/LoadingFullScreen';
import { CustomClaimCard } from './CustomClaimCard';

interface ClaimsListProps {
  claims: QuotallClaimDto[];
  title: string;
  isLoading: boolean;
}
const ClaimsList = ({ claims, title, isLoading }: ClaimsListProps) => {
  return (
    <>
      <h2 className="booking-title">{title}</h2>
      {isLoading && <LoadingFullSide />}
      {!isLoading && claims && claims.length == 0 && (
        <p className="text-align-center">{`You have not made or started any insurance claims`}</p>
      )}
      {!isLoading && claims && claims.length > 0 && (
        <Row>
          {claims.map((claim: QuotallClaimDto) => (
            <Col lg={'6'} md={'12'} sm="12" key={claim.id}>
              <CustomClaimCard claim={claim} />
            </Col>
          ))}
        </Row>
      )}
      <hr className="mt-3 mb-3" />
    </>
  );
};

export default ClaimsList;
