import { useState, useEffect } from "react";
import { Box, MobileStepper } from "@mui/material";
import { PersonalInformationStep } from "./PersonalInformationStep";
import { AddressInformationStep } from "./AddressInformationStep";
import { StripeIdVerificationStep } from "./StripeIdVerificationStep";
import { StripeAddressVerificationStep } from "./StripeAddressVerificationStep";
import { BankDetailsStep } from "./BankDetailsStep";
import { OnboardingCompletedStep } from "./OnboardingCompletedStep";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useUserContext } from "../../context/UserContext";
import { UserClient, IConfig, UserIntention } from "../../api/rentMyApi";
import { useEnv } from "../../context/env.context";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {LoadingFullScreen} from "../common/LoadingFullScreen";

const stepSummaryStyle = {
  textAlign: "center",
  color: "grey",
};

const inputSelectStyle = {
  backgroundColor: "white",
  lineHeight: "24px",
  marginBottom: "10px",
  "&.Mui-focused": {
    border: "1px solid red",
  },
  "&.Mui-error": {
    border: "1px solid red",
    marginBottom: "0px",
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderStyle: "none",
  },
  "& .MuiSelect-select": {
    padding: "10px 12px",
  },
};

const inputStyle = {
  marginBottom: "10px",
  ".MuiOutlinedInput-input": {
    padding: "10px 12px",
    lineHeight: "24px",
  },
  ".MuiOutlinedInput-root": {
    backgroundColor: "white",
    border: "1px solid lightgrey",
    "&.Mui-focused": {
      border: "1px solid red",
    },
    "&.Mui-error": {
      border: "1px solid red",
    },
    ".MuiOutlinedInput-notchedOutline": {
      borderStyle: "none",
    },
  },
  ".MuiFormHelperText-root": {
    "&.Mui-error": {
      color: "red",
      fontSize: "small",
      margin: "0",
    },
  },
};

const errorTextStyle = {
  fontSize: "small",
  color: "red",
  marginBottom: "10px",
};

export enum OnboardingStep {
  PersonalInformation,
  AddressInformation,
  StripeIdVerification,
  StripeAddressVerification,
  BankDetails,
  OnboardingCompleted,
}

type OnboardingStepperProps = {
  showSharerOnboarding?: boolean;
  setOnboardingCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  initialActiveStep: OnboardingStep;
};

export function OnboardingStepper({
  showSharerOnboarding,
  setOnboardingCompleted,
    initialActiveStep,
}: OnboardingStepperProps) {
  const { getAccessTokenSilently } = useAuth0();
  const { user, updateUser, hasAcceptedTerms } = useUserContext();
  const [activeStep, setActiveStep] = useState(
      initialActiveStep
  );
  const [isSharer, setIsSharer] = useState( false);
  const [isMobile, setIsMobile] = useState(false);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const redirectFromStripeVerification = params.get(
    "redirectFromStripeVerification"
  );
  const redirectFromStripeAddressVerification = params.get(
    "redirectFromStripeAddressVerification"
  );

  const headingStyle = {
    textAlign: "center",
    fontSize: isMobile ? "1.3rem" : "2rem",
    fontWeight: "600",
    marginY: "20px",
  };

  const progressBarStyle = {
    "& .MuiMobileStepper-progress": {
      width: isMobile ? "300px" : "380px",
      borderRadius: "2rem",
      backgroundColor: "rgba(234, 85, 74, 0.3)",
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "rgb(234, 85, 74)",
      },
    },
  };

  function handleResize() {
    setIsMobile(window.innerWidth <= 768);
  }

  window.addEventListener("resize", handleResize);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    getLatestUserData();
    setActiveStep(determineActiveStep());
  }, []);

  const checkPersonalInformation = () => (
      !user.firstName ||
      !user.lastName ||
      !user.phoneNumber ||
      !user.gender ||
      !user.intention
      // !hasAcceptedTerms
  );

  const checkAddressInformation = () => (
      !user.location?.addressLine1 ||
      !user.location?.city ||
      !user.location?.country ||
      !user.location?.postcode
  );
  const determineActiveStep = () => {
    if (checkPersonalInformation()) return OnboardingStep.PersonalInformation;
    if (checkAddressInformation()) return OnboardingStep.AddressInformation;
    if (!user.isVerified || !hasAcceptedTerms) return OnboardingStep.StripeIdVerification;
    if (!showSharerOnboarding && user.isVerified) return OnboardingStep.OnboardingCompleted;
    if (showSharerOnboarding) {
      if (user.canBeSharer) return OnboardingStep.OnboardingCompleted;
      if (user.stripeOnboarded) return OnboardingStep.BankDetails;
      if (user.isVerified) return OnboardingStep.StripeAddressVerification;
    }
    return OnboardingStep.OnboardingCompleted;
  };


  const getLatestUserData = async () => {
    const token = await getAccessTokenSilently();
    const userClient = new UserClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT);
    const user = await userClient.current(true, true);
    updateUser(user);
    if (redirectFromStripeVerification && !user.isVerified) {
      toast.error(
        "We were unable to verify your account with Stripe, please try again. If the error persists, contact support"
      );
    }
    if (redirectFromStripeAddressVerification && !user.stripeOnboarded) {
      toast.error(
        "We were unable to verify your address with Stripe, please try again. If the error persists, contact support"
      );
    }
  };

  const handleNextStep = () => {
    setActiveStep((previousStep) => previousStep + 1);
  };

  const handleBack = () => {
    setActiveStep((previousStep) => previousStep - 1);
  };

  const returnActiveStep = () => {
    switch (activeStep) {
      case OnboardingStep.PersonalInformation:
        return (
          <PersonalInformationStep
            handleNextStep={handleNextStep}
            headingStyle={headingStyle}
            inputSelectStyle={inputSelectStyle}
            inputStyle={inputStyle}
            errorTextStyle={errorTextStyle}
            setIsSharer={setIsSharer}
            showSharerOnboarding={showSharerOnboarding}
          />
        );
      case OnboardingStep.AddressInformation:
        return (
          <AddressInformationStep
            backButton={<BackButton />}
            handleNextStep={handleNextStep}
            headingStyle={headingStyle}
          />
        );
      case OnboardingStep.StripeIdVerification:
        return (
          <StripeIdVerificationStep
            backButton={<BackButton />}
            handleNextStep={handleNextStep}
            headingStyle={headingStyle}
            stepSummaryStyle={stepSummaryStyle}
          />
        );
      case OnboardingStep.StripeAddressVerification:
        return (
          <StripeAddressVerificationStep
            backButton={<BackButton />}
            handleNextStep={handleNextStep}
            headingStyle={headingStyle}
            stepSummaryStyle={stepSummaryStyle}
          />
        );
      case OnboardingStep.BankDetails:
        return (
          <BankDetailsStep
            backButton={<BackButton />}
            headingStyle={headingStyle}
            inputStyle={inputStyle}
            stepSummaryStyle={stepSummaryStyle}
            setOnboardingCompleted={setOnboardingCompleted}
          />
        );
      case OnboardingStep.OnboardingCompleted:
        return (
          <OnboardingCompletedStep
            headingStyle={headingStyle}
            stepSummaryStyle={stepSummaryStyle}
            setOnboardingCompleted={setOnboardingCompleted}
          />
        );
    }
  };

  const BackButton = () => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          cursor: "pointer",
          color: "red",
          marginBottom: "15px",
        }}
        onClick={handleBack}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
        Back
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "24px",
      }}
    >
      <MobileStepper
        activeStep={activeStep}
        position="static"
        sx={progressBarStyle}
        steps={6}
        variant="progress"
        nextButton={<></>}
        backButton={<></>}
      />
      <Box width={isMobile ? "300px" : "400px"}>{returnActiveStep()}</Box>
    </Box>
  );
}
