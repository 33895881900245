import { AppState, Auth0Provider } from "@auth0/auth0-react";
import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { useEnv } from "../context/env.context";

export const Auth0ProviderWithHistory = ({
                                           children
                                         }: PropsWithChildren<any>): JSX.Element | null => {
  const navigate = useNavigate();
  const { domain, clientId, audience } = useEnv();


  const onRedirectCallback = (appState: AppState | undefined) => {
    // console.log("onRedirectCallback")
    // getAccessTokenSilently().then((token) => {
    //     const termsClient = new TermsClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT)
    //     // console.log({domain, clientId, audience })
    //     termsClient!.terms2()
    //         .then((result) => {
    //             console.log("THEN")
    //             console.log("TERMS RESULT: " + result)
    //         })
    // });
    // console.log("APP STATE")
    // console.log(appState)
    // userLoginAnalytics({})

    // console.log("Login Event")
    localStorage.setItem("justLoggedIn", "true");

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      navigate(window.location.pathname);
    } else {
      navigate(appState?.returnTo || window.location.pathname);
    }

  };

  if (!(domain && clientId && audience)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      audience={audience}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
