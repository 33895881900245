import { faSignOut } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import { Card } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavbarMenuOptions } from '../common/Navigation';
import { useAuth0 } from '@auth0/auth0-react';

interface AccountPagesSidebarProps {
    visible?: boolean;
}
export default function AccountPagesSidebar({visible}: AccountPagesSidebarProps) {
  // console.log("SimpleDashboardSidebar rendered")

  const location = useLocation();
  const { pathname } = location;
  const { logout } = useAuth0();

  return (
    <div className={`dashboard-sidebar ${visible ? 'dashboard-sidebar-mobile' : ''}`}>
      <Card>
        <div className="dashboard-list">
          {NavbarMenuOptions().map((item) => {
            const isSelected = pathname.includes(item.destination);
            return (
              <Link
                to={{ pathname: item.destination }}
                key={item.destination}
                className="no-padding"
                style={item.indent ? { paddingLeft: '40px' } : {}}
              >
                <span className={isSelected ? 'selected' : ''}>
                  <FontAwesomeIcon size={'lg'} icon={item.icon} className="simple-dashboard-sidebar-button" />
                  {item.text}
                </span>
              </Link>
            );
          })}
          <Link
            onClick={() =>
              logout({
                returnTo: window.location.origin,
              })
            }
            to={{ pathname: '' }}
            key={'logout'}
            className="no-padding"
          >
            <span>
              <FontAwesomeIcon size={'lg'} icon={faSignOut} className="simple-dashboard-sidebar-button" />
              Logout
            </span>
          </Link>
        </div>
      </Card>
    </div>
  );
}
