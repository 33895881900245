import { useTranslation } from "react-i18next";
import { Card, CardText, CardBody } from "reactstrap";
import GoogleMapsSingle from "../maps/GoogleMapsSingle";

export default function LocationCard({
  latitude,
  longitude,
  city,
  country,
  wantMarker,
  wantCircle,
  accurateLocation,
}: {
  latitude: number;
  longitude: number;
  city: string;
  country: string;
  wantMarker?: boolean;
  wantCircle?: boolean;
  accurateLocation?: string;
}) {
  const { t } = useTranslation();

  return (
    <Card>
      <CardBody>
        <h2>{t("item_detailed_location")}</h2>

        <div className="map-snippet mb-3">
          <GoogleMapsSingle
            lat={latitude}
            lng={longitude}
            wantMarker={wantMarker}
            wantAccurateMarker={accurateLocation !== undefined}
            circleSetup={wantCircle ? { radius: undefined } : undefined}
          />
        </div>
        <CardText className="item-detailed-semi-bold location-card-text mb-1">
          {city}, {country}
        </CardText>
        {!accurateLocation && (
          <CardText className="small location-card-text-variant">
            {t("item_detailed_accurate_location_on_booking")}
          </CardText>
        )}
        {accurateLocation && (
          <CardText className="location-card-text-variant">
            {accurateLocation}
          </CardText>
        )}
      </CardBody>
    </Card>
  );
}
