import { Col, Row } from 'reactstrap';
import * as React from 'react';
import { CustomBookingCard } from '../../booking/CustomBookingCard';
import { LoadingFullSide } from '../../common/LoadingFullScreen';
import { BookingClient, BookingSimpleDto } from '../../../api/rentMyApi';

interface BookingsListProps {
  bookings: BookingSimpleDto[];
  title: string;
  onBookingDetailsClick: (bookingId: string) => void;
  isSharerView: boolean;
  isLoading: boolean;
  bookingClient: BookingClient;
  openHandoverModal: (bookingId: string) => void;
  handleCancellationRequest: (bookingId: string) => void;
}

const BookingsList: React.FC<BookingsListProps> = ({
                                                     bookings,
                                                     title,
                                                     onBookingDetailsClick,
                                                     isSharerView,
                                                     isLoading,
                                                     bookingClient,
                                                     openHandoverModal,
                                                     handleCancellationRequest,
                                                   }) => {
  return (
    <>
      {isLoading && <LoadingFullSide />}
      {!isLoading && bookings.length == 0 && (
        <p className="text-align-center">{`You do not currently have any ${title.toLowerCase()}`}</p>
      )}
      {!isLoading && bookings.length > 0 && (
        <Row>
          {bookings.map((booking) => (
            <Col lg={'6'} md={'12'} sm="12" key={booking.id}>
              <CustomBookingCard
                bookingClient={bookingClient}
                key={booking.id}
                booking={booking}
                isSharerView={isSharerView}
                onBookingDetailsClick={onBookingDetailsClick}
                openHandoverModal={openHandoverModal}
                handleCancellationRequest={handleCancellationRequest}
              />
            </Col>
          ))}
        </Row>
      )}
      <hr className="mt-3 mb-3" />
    </>
  );
};

export default BookingsList;
