import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  faArrowLeft,
  faFilter,
  faLocationDot,
  faMagnifyingGlass,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Autocomplete from "react-google-autocomplete";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Select, { SingleValue } from "react-select";
import { ItemOrderBy } from "../../api/rentMyApi";
import { OptionType } from "../../pages/SearchResultsPage";
import TopLevelCategories from "./TopLevelCategories";
import PlaceResult = google.maps.places.PlaceResult;

interface WhatWhereSearchBarMobileProps {
  handleSubmit: () => Promise<void>;
  localSelectedPlace: google.maps.places.PlaceResult | undefined;
  setLocalSelectedPlace: React.Dispatch<
    React.SetStateAction<PlaceResult | undefined>
  >;
  localSearchTerm: string;
  setLocalSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  localSortByOption: { value: ItemOrderBy; label: string };
  handleSortByChange: (selectedOption: SingleValue<OptionType>) => void;
  sortByOptions: { value: ItemOrderBy; label: string }[];
}



export const WhatWhereSearchBarMobile: React.FC<WhatWhereSearchBarMobileProps> =
  ({
    handleSubmit,
    localSelectedPlace,
    setLocalSelectedPlace,
    localSearchTerm,
    setLocalSearchTerm,
    localSortByOption,
    handleSortByChange,
    sortByOptions,
  }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    // const { what, where } = parsePath(pathname);

    const { searchTerm } = useSelector((state: RootState) => state.search);

    const [preventFormSubmit, setPreventFormSubmit] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const navigate = useNavigate();

    const handleClear = () => {
      setLocalSearchTerm("");
      setLocalSelectedPlace(undefined);
    };

    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;

    const handleSearchBarClick = () => {
      setIsSearchOpen(true);
    };

    const handleBackClick = (e: React.MouseEvent) => {
      e.stopPropagation(); // Prevent the click event from reaching the parent
      if (pathname.split("/")[1] !== "search") navigate(-1);
      else navigate("/");
    };

    const handleFinalSubmit = async (
      event: React.FormEvent<HTMLFormElement>
    ) => {
      if (preventFormSubmit) {
        event.preventDefault();
        setPreventFormSubmit(false);
        return;
      }
      event.preventDefault();
      await handleSubmit();
      setIsSearchOpen(false);
    };

    return (
      <>
        <div className="dummy-search-bar" onClick={handleSearchBarClick}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="search-icon"
            onClick={handleBackClick}
          />
          <div className="search-inputs">
            <span className="search-text main-text">
              {searchTerm.trim().length === 0
                ? t("homepage_what_are_you_looking_for_short")
                : searchTerm}
            </span>
            <span className="search-text sub-text">
              {localSelectedPlace?.formatted_address ??
                t("homepage_where_you_want_it_short")}
            </span>
          </div>
        </div>
        <form onSubmit={handleFinalSubmit}>
          <div className={`search-dropdown ${isSearchOpen ? "open" : ""}`}>
            <div className="mobile-search-header">
              <FontAwesomeIcon
                icon={faTimesCircle}
                onClick={() => setIsSearchOpen(false)}
              />
            </div>
            <div className="mobile-search-content">
              <div className="mobile-input-group">
                <label>
                  {" "}
                  <FontAwesomeIcon icon={faMagnifyingGlass} />{" "}
                  {t("homepage_what_are_you_looking_for_short")}
                </label>
                <input
                  type="text"
                  value={localSearchTerm ||  ""}
                  onChange={(e) => {
                    e.preventDefault();
                    setLocalSearchTerm(e.target.value);
                  }}
                  placeholder="Enter here"
                />
              </div>
              <div className="mobile-input-group">
                <label>
                  {" "}
                  <FontAwesomeIcon
                    className="location-icon"
                    icon={faLocationDot}
                    color={localSelectedPlace ? "#EA554A" : "#b3bac2"}
                    fontSize="20px"
                  />{" "}
                  Where{" "}
                </label>
                <Autocomplete
                  onInput={(e: any) => {
                    if (e.target.value.trim() === "")
                      setLocalSelectedPlace(undefined);
                  }}
                  aria-placeholder={
                    localSelectedPlace?.formatted_address ??
                    t("homepage_where_you_want_it_short")
                  }
                  options={{
                    componentRestrictions: { country: "uk" },
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter" && !localSelectedPlace) {
                      setPreventFormSubmit(true);
                    }
                  }}
                  inputAutocompleteValue={
                    localSelectedPlace?.formatted_address || ""
                  }
                  apiKey={apiKey}
                  className="autocomplete"
                  onPlaceSelected={(place: PlaceResult) => {
                    console.log(place);
                    setLocalSelectedPlace(place);
                  }}
                  defaultValue={localSelectedPlace?.formatted_address || ""}
                />
              </div>
              <div className="mobile-input-group">
                <label>
                  {" "}
                  <FontAwesomeIcon
                    className="location-icon"
                    icon={faFilter}
                    color={"#EA554A"}
                    fontSize="20px"
                  />{" "}
                  Filters{" "}
                </label>
                <Select
                  defaultValue={localSortByOption}
                  options={sortByOptions}
                  menuPlacement="auto"
                  menuPosition="fixed"
                  onChange={handleSortByChange}
                />
                <TopLevelCategories />
              </div>
            </div>
            <div className="search-footer">
              <button className="clear-btn" onClick={handleClear}>
                Clear all
              </button>
              <button className="search-btn" type="submit">
                Search
              </button>
            </div>
          </div>
        </form>
      </>
    );
  };
