import { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import AccountPagesSidebar from '../AccountPagesSidebar';

import {
  BookingSimpleDtoPaginationDto,
  IConfig,
  QuotallClaimDtoPaginationDto,
  QuotallClaimsClient,
} from '../../../api/rentMyApi';
import ClaimsList from './ClaimsList';

export function AccountClaims() {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [quotallClaimClient, setQuotallClaimClient] = useState(new QuotallClaimsClient(new IConfig('notoken'), process.env.REACT_APP_API_ENDPOINT));
  const [myClaims, setMyClaims] = useState<QuotallClaimDtoPaginationDto | undefined>();
  const [isClientLoaded, setIsClientLoaded] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const { getAccessTokenSilently } = useAuth0();

  const pageSize = 150;

  const [bookings, setBookings] = useState(new BookingSimpleDtoPaginationDto());

  useEffect(() => {
    async function initClient() {
      const token = await getAccessTokenSilently();
      const quotallClaimClient = new QuotallClaimsClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT);
      setQuotallClaimClient(quotallClaimClient);
      setIsClientLoaded(true);
    }

    initClient();
  }, [getAccessTokenSilently]);

  const fetchClaims = () => {
    if (isClientLoaded) {
      quotallClaimClient
        .myClaims(undefined, pageSize, myClaims ? myClaims.page! + 1 : 1)
        .then((claims) => {
          setMyClaims(claims);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setMyClaims(undefined);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchClaims();
  }, [isClientLoaded, quotallClaimClient]);

  const onLoadMore = useCallback(() => {
    // setIsProcessing(true)
    setIsLoadingMore(true);
    quotallClaimClient
      .myClaims(undefined, pageSize, myClaims!.page! + 1)
      .then((res) => {
        setMyClaims(
          new QuotallClaimDtoPaginationDto({
            ...res,
            data: [...(myClaims!.data || []), ...(res.data || [])],
          }),
        );

        setIsLoadingMore(false);
      })
      .catch(() => setIsLoadingMore(false));
  }, [quotallClaimClient, bookings]);

  return (
    <Container className="mt-4 account-settings-container">
      <Row>
        <Col md={4} lg={4} xl={3} className="hide-on-mobile hide-on-tablet">
          <AccountPagesSidebar />
        </Col>

        <Col md={8} lg={8} xl={9} className="auto-margin">
          <div className="account-booking-list">
            <ClaimsList claims={myClaims?.data ?? []} title={'My Claims'} isLoading={isLoading} />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
