// rentmy.frontend/src/components/LogoStrip/logostrip.tsx

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container } from "reactstrap"; // Ensure you have reactstrap installed

interface Logo {
  src: string;
  alt: string;
  link?: string;
}

export function LogoStrip() {
  const [textTitle, setTextTitle] = useState("");
  const [logos, setLogos] = useState<Logo[]>([]);

  useEffect(() => {
    const fetchLogos = async () => {
      try {
        const cmsUrl = process.env.REACT_APP_CMS_URL!;
        const logoStripPath = process.env.REACT_APP_CMS_LOGOSTRIP_PATH!;
        const absPath = cmsUrl + logoStripPath;

        const res = await axios.get(absPath);
        const { sectionBackgroundColor, title } = res.data.fields;

        // Set background color
        document.documentElement.style.setProperty(
          "--logo-strip-section-background-color",
          `#${sectionBackgroundColor}`
        );

        setTextTitle(title);

        // Extract image data
        const imageFields = Object.entries(res.data.fields).filter(([key]) =>
          key.startsWith("image")
        );

        const _logos = imageFields.map(([key, imageField]: any) => ({
          src: cmsUrl + imageField.fields.umbracoFile.src,
          alt: imageField.fields.altText || `Logo ${key}`,
          link: imageField.fields.linkUrl || "#",
        }));

        setLogos(_logos);
      } catch (err) {
        console.error("Error fetching logo data:", err);
      }
    };

    fetchLogos();
  }, []);

  // To create a seamless ticker, repeat the logos multiple times
  const repeatedLogos = [...logos, ...logos, ...logos];

  return (
    <section className="logo-strip">
       <div className="ticker-wrapper">
        <div className="img-ticker">
          {repeatedLogos.map((logo, index) => (
            <div key={`ticker-logo-${index}`} className="ticker-logo-container">
              <img
                  src={logo.src}
                  alt={logo.alt}
                  className="tickerlogo"
                />
              </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default LogoStrip;