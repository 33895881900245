import React, { ChangeEvent, ForwardedRef } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export enum Capture {
  User = "user",
  Environment = "environment",
}

interface ImageUploadProps {
  onNewImagesUploaded: (images: File[]) => void; // Assuming 'c' is an array of File objects
  accept: string;
  sizeLimitMB: number;
  capture?: Capture;
}

const ImageUpload = React.forwardRef<HTMLInputElement, ImageUploadProps>(
  (props, ref) => {
    const { t } = useTranslation();
    const approvedFileTypes = props.accept.split(",");

    function isApprovedFileType(userFileType: string): boolean {
      return approvedFileTypes.some(
        (approvedFileType) =>
          userFileType === approvedFileType.replace(/\s/g, "")
      );
    }

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) return;
      const images = Array.from(e.target.files);
      const array: File[] = [];

      images.forEach((image) => {
        const size = image.size / 1024 / 1024; // MB

        if (!isApprovedFileType(image.type)) {
          toast.error(t("image_upload_file_type_error"));
          return;
        }

        if (size >= props.sizeLimitMB) {
          toast.error(t("item_listing_size_error"));
          return;
        }

        array.push(image);
      });

      props.onNewImagesUploaded(array);
    };

    return (
      <input
        type="file"
        ref={ref as ForwardedRef<HTMLInputElement>}
        onChange={onChange}
        accept={props.accept}
        multiple
        className="no-display"
        capture={props.capture ?? undefined}
      />
    );
  }
);

ImageUpload.displayName = "ImageUpload";
export default ImageUpload;
