import BackButton from "../common/BackButton";
import React from "react";
import { useNavigate } from "react-router-dom";

interface ItemListingTitleProps {
  isEditing: boolean;
  t: (key: string) => string;
}

export default function ItemListingTitle({
  isEditing,
  t,
}: ItemListingTitleProps) {
  const navigate = useNavigate();
  return (
    <h1 className="item-listing-title">
      {isEditing && (
        <BackButton onClick={() => navigate("/account/my-items")} />
      )}
      {isEditing ? t("item_listing_edit_header") : t("item_listing_header")}
    </h1>
  );
}
