import { useState, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useEnv } from "../../context/env.context";
import { useAuth0 } from "@auth0/auth0-react";
import { useUserContext } from "../../context/UserContext";
import {
  Box,
  Typography,
  InputLabel,
  TextField,
  SxProps,
  Theme,
} from "@mui/material";
import { Location, UserClient, IConfig } from "../../api/rentMyApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { UserAddressFormErrors } from "../../models/interfaces/UserAddress";
import { UserAddressInput } from "../account/Profile/UserAddressInput";

type AddressInformationStepProps = {
  backButton: ReactNode;
  handleNextStep: () => void;
  headingStyle: SxProps<Theme>;
};

const initialFormErrors: UserAddressFormErrors = {
  addressLine1: "",
  addressLine2: "",
  city: "",
  country: "",
  postcode: "",
};

export function AddressInformationStep({
  backButton,
  handleNextStep,
  headingStyle,
}: AddressInformationStepProps) {
  const { t } = useTranslation();
  const { apiServerUrl } = useEnv();
  const { getAccessTokenSilently } = useAuth0();
  const { refetchCurrentUser } = useUserContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [locationData, setLocationData] = useState<Location>(new Location());
  const [formErrors, setFormErrors] =
    useState<UserAddressFormErrors>(initialFormErrors);

  const validateInput = () => {
    const errors = {
      ...initialFormErrors,
    };
    const { addressLine1, city, country, postcode } = locationData;
    if (!addressLine1) {
      errors.addressLine1 = "Required field";
    }
    if (!city) {
      errors.city = "Required field";
    }
    if (!country) {
      errors.country = "Required field";
    }
    if (!postcode) {
      errors.postcode = "Required field";
    }
    return errors;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    const errors = validateInput();
    setFormErrors(errors);
    if (Object.values(errors).find((fieldError) => fieldError)) {
      setIsSubmitting(false);
    } else {
      const token = await getAccessTokenSilently();
      try {
        await new UserClient(
          new IConfig(token),
          process.env.REACT_APP_API_ENDPOINT
        ).changeLocationPOST2(undefined, locationData);
        await refetchCurrentUser();
        setIsSubmitting(false);
        handleNextStep();
      } catch (error) {
        setIsSubmitting(false);
        toast.error(t("account_settings_err_something_goes_wrong"));
      }
    }
  };

  return (
    <Box>
      <Typography component="h1" sx={headingStyle}>
        {t("address")}
      </Typography>
      {backButton}
      <form onSubmit={(e) => handleSubmit(e)}>
        <UserAddressInput
          locationData={locationData}
          setLocationData={setLocationData}
          formErrors={formErrors}
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <button
            type="submit"
            disabled={isSubmitting}
            className="btn btn-primary Box-grow onboarding-button"
          >
            {isSubmitting && (
              <span className="btn-inner--icon">
                <FontAwesomeIcon icon={faSpinner} spin/>
              </span>
            )}
            {t("onboarding_next")}
          </button>
        </Box>
      </form>
    </Box>
  );
}
