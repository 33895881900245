import * as React from "react";
import { useRef, useState } from "react";
import { Button, Card, CardBody, Row } from "reactstrap";
import { useEnv } from "../../../context/env.context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faStar,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { faXmarkCircle } from "@fortawesome/free-regular-svg-icons";
import moment from "moment";
import { useUserContext } from "../../../context/UserContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

enum UserImageType {
  PROFILE,
  BANNER,
}

interface ProfileProps {
  isEditable: boolean;
  handleUserPhotoUpload: (
    event: React.ChangeEvent<HTMLInputElement>,
    type: UserImageType
  ) => void;
}
export const Profile = ({
  isEditable,
  handleUserPhotoUpload,
}: ProfileProps) => {
  const { apiServerUrl } = useEnv();
  const { user } = useUserContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);
  const fileUploadProfile =
    useRef() as React.MutableRefObject<HTMLInputElement>;

  return (
    <Card>
      <CardBody>
        <div className="flexy mb-3 header-section justify-content-between">
          <h2 className="pr-4">{isEditable ? "Edit Profile" : "My Profile"}</h2>
          {!isEditable && (
            <span
              onClick={() => navigate("/account/profile")}
              className="bold rent-my-red cursor-pointer"
            >
              Edit{" "}
              <FontAwesomeIcon
                className="edit-icon"
                color="#E83E33"
                icon={faPenToSquare}
              />
            </span>
          )}
          {isEditable && (
            <span
              onClick={() => navigate("/public/profile?id=" + user.id)}
              className="bold rent-my-red cursor-pointer"
            >
              View Public Profile{" "}
              <FontAwesomeIcon
                className="edit-icon"
                color="#E83E33"
                icon={faEye}
              />
            </span>
          )}
        </div>
        <div className="profile-info">
          <div className="profile-image-container">
            <img
              src={
                user.profileImage
                  ? user.profileImage.compressedPath
                  : "assets/img/profile-pic-placeholder.webp"
              }
              alt="Profile"
              className="profile-image"
            />
            <input
              type="file"
              ref={fileUploadProfile}
              defaultValue=""
              name="profilePic"
              onChange={(e) => handleUserPhotoUpload(e, UserImageType.PROFILE)}
              hidden
              accept="image/png, image/jpeg, image/jpg, image/webp"
            />
          </div>
          <div className="profile-details">
            <h3>{user.firstName}</h3>
            <p>
              {t("my_profile_joined")}{" "}
              {user?.joinedDate
                ? moment(user.joinedDate).format("DD MMM YYYY")
                : ""}
            </p>
            <p className="semi-bold">
              {user?.location && user.location.city ? user.location.city : ""}
            </p>
            <p className="mb-1">
              {user.isVerified && (
                <span className="">{t("my_profile_id_verified")}</span>
              )}
              {!user.isVerified && (
                <span>
                  <FontAwesomeIcon icon={faXmarkCircle} />{" "}
                  {t("my_profile_id_not_verified")}
                </span>
              )}
            </p>
            <div className="account_profile_profile_info_review">
              <FontAwesomeIcon icon={faStar} className="gold" />
              <span className="review mx-1">
                {user?.reviewOverallAverage
                  ? Math.round((user.reviewOverallAverage ?? 5) * 10) / 10
                  : ""}
              </span>
              <span className="number-of-reviews">
                ({user?.reviewCount ? user.reviewCount : 0}{" "}
                {user.reviewCount > 1 || user.reviewCount === 0
                  ? t("my_profile_reviews")
                  : t("my_profile_review")}
                )
              </span>
            </div>
          </div>
        </div>
        {isEditable && (
          <Row className="button-row justify-content-center">
            <Button
              className="btn-icon btn-3 mt-3 upload-image-button no-flex"
              color="primary"
              type="button"
              disabled={isImageUploading}
              onClick={() => {
                fileUploadProfile?.current?.click();
              }}
            >
              <span className="btn-inner--icon">
                <FontAwesomeIcon icon={faUpload} />
              </span>
              <span className="btn-inner--text upload-image-button">
                {t("my_profile_change_account_photo")}
              </span>
            </Button>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};
