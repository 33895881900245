import React, { useEffect, useRef, useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { faBars, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface KebabMenuProps {
  id: string;
  menuItems: {
    label: string;
    onClick: () => void;
    icon: IconDefinition;
    notification: boolean,
    visible: boolean
  }[];
}

const KebabMenu: React.FC<KebabMenuProps> = ({ id, menuItems }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const openedMenuId = useRef<string | null>(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".kebab-menu-container")) {
        openedMenuId.current = null;
        setDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const showTopLevelNotification = menuItems.some(e => e.notification && e.visible);

  return (
    <div className="kebab-menu-container">
      <Dropdown isOpen={openedMenuId.current === id} toggle={() => {
      }}>
        <DropdownToggle
          className="kebab-menu"
          tag="span"
          data-toggle="dropdown"
          aria-expanded={dropdownOpen}
          onClick={() => {
            if (openedMenuId.current === id) {
              openedMenuId.current = null;
            } else {
              openedMenuId.current = id;
            }
            setDropdownOpen(openedMenuId.current === id);
          }}
        >
          <span className="">
            <FontAwesomeIcon icon={faBars} size="sm" />
            {showTopLevelNotification && <div className="notification" />}
          </span>
        </DropdownToggle>

        <DropdownMenu className="custom-dropdown-menu">
          {menuItems.filter((item => item.visible)).map((item, index) => (
            <DropdownItem key={index} onClick={item.onClick} className="position-relative">
              <FontAwesomeIcon icon={item.icon} className="item-icon" />
              {item.label}
              {item.notification && <div className="sub-notification" />}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default KebabMenu;