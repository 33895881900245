import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';
//import { faHeart as fasHeart } from '@fortawesome/free-solid-svg-icons';
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ItemSearchDto } from "../../api/rentMyApi";
import { cleanUrl } from "../../utils/cleanUrl";

type ItemHighlightProps = {
  item: ItemSearchDto;
  isFocused?: boolean;
  isItemSearch?: boolean;
  isAlreadyOnItemPage?: boolean;
};

export default function ItemHighlight({
  item,
  isFocused = false,
  isItemSearch = false,
  isAlreadyOnItemPage = false,
}: ItemHighlightProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const searchParams = new URLSearchParams(window.location.search);
  const itemImageURL =
    item.image && item.image.path
      ? item.image.path
      : "/assets/img/placeholder.jpg";

  const startDateConst = "start-date";
  const endDateConst = "end-date";

  const isMobile = window.innerWidth <= 768;

  // const routeChange = () => {
  //   // navigate(generateItemDetailedPath(), isItemSearch ? { isFromSearch: true } : {});
  //   navigate(generateItemDetailedPath(), {
  //     state: isItemSearch ? { isFromSearch: true } : {},
  //   });
  //   if (isAlreadyOnItemPage) navigate(0);
  //   // history.go(0) // DEVNOTE - need to re-render as apparently just changing the search params is not enough for the page to auto refresh. Before this the URL would update but nothing else would happen.
  // };

  // const generateItemDetailedPath = () => {
  //   let path = `/item/${item.id}`;
  //
  //   if (searchParams.get(startDateConst) && searchParams.get(endDateConst)) {
  //     path += `&${startDateConst}=${searchParams.get(
  //       startDateConst
  //     )}&${endDateConst}=${searchParams.get(endDateConst)}`;
  //   }
  //
  //   return path;
  // };

  // const [isHeartSelected, setIsHeartSelected] = useState<boolean>(false);
  // const [standardPricePeriod, setStandardPricePeriod] = useState<PricePeriod>();

  // useEffect(() => {
  //     if (!item) {
  //         return;
  //     }
  //
  //     if (!item?.dayRate) {
  //         return;
  //     }
  //
  //     document.documentElement.style.setProperty('--itemHighlightCursor', item.ownerName ? "pointer" : "default");
  //
  //
  //     // setStandardPricePeriod(getStandardPricePeriodPriceWithServiceFeeApplied(item.priceInfo.periods, item.name));
  // }, [item]);



  return (
    <>
      {item && (
          <div
              key={item.id}
              className={`item-highlight-container ${isFocused ? "focused" : ""}`}
          >
            <div className="d-flex justify-content-between">
              <div
                  className="d-flex search_results_sections_header align-items-center clickable"
                  onClick={() => navigate("/public/profile?id=" + item.ownerId)}
              >
                <img
                    width="100%"
                    height="100%"
                    alt="avatar of the user"
                    src={
                      item.ownerProfilePicture && item.ownerProfilePicture.path
                          ? item.ownerProfilePicture.path!
                          : "assets/img/profile-pic-placeholder.webp"
                    }
                    className="item-highlight-image"
                />
                <span>{item.ownerName}</span>
              </div>
            </div>

                  <div onClick={() => {
                      window.open(`/item/${item.url}`, isMobile ? "_self" : "_blank")
              }}>
                <img
                    src={itemImageURL}
                    className="listing-image object-cover position-center"
                    alt={item.itemName}
                />
                <div className="d-flex align-items-center flex-wrap item-highlight-detail">
                  <div className="mb-1">
                    <h4 className="mb-0">
                      {item.itemName}
                    </h4>
                    <span>
                £{item.dayRate?.toFixed(2)}/{t("search_results_price_day")}
              </span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center item-highlight-rating">
                      <FontAwesomeIcon icon={faStar}/>
                      <span>{Math.round((item.rating ?? 5) * 10) / 10 ?? 5}</span>
                    </div>
                    <div className="d-flex justify-content-end item-highlight-location">
                      <span>{item.locationName ? item.locationName : ""}</span>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        )}
    </>
  );
}
