const PlaceholderIcon = () => {
  return (
    <svg
      className="auto-margin image-upload-listing"
      xmlns="http://www.w3.org/2000/svg"
      width="486"
      height="439"
      viewBox="0 0 486 439"
    >
      <g id="Group_135" data-name="Group 135" transform="translate(-367 -281)">
        <rect
          id="Rectangle_18"
          data-name="Rectangle 18"
          width="486"
          height="439"
          rx="11"
          transform="translate(367 281)"
          fill="#d2d2d2"
        />
      </g>
      <g id="Group_136" data-name="Group 136" transform="translate(-420 -377)">
        <g
          id="Rectangle_20"
          data-name="Rectangle 20"
          transform="translate(569 533)"
          fill="none"
          stroke="#ababab"
          strokeWidth="10"
        >
          <rect width="189" height="124" rx="16" stroke="none" />
          <rect x="5" y="5" width="179" height="114" rx="11" fill="none" />
        </g>
        <rect
          id="Rectangle_21"
          data-name="Rectangle 21"
          width="157"
          height="40"
          rx="5"
          transform="translate(585 601)"
          fill="#ababab"
        />
        <ellipse
          id="Ellipse_2"
          data-name="Ellipse 2"
          cx="14.5"
          cy="14"
          rx="14.5"
          ry="14"
          transform="translate(587 553)"
          fill="#ababab"
        />
      </g>
    </svg>
  );
};

export default PlaceholderIcon;
