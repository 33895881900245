import { Card, CardBody, CardImg, CardText, Col, Container, Row } from 'reactstrap';
import { format, isSameMonth } from 'date-fns';
import { QuotallClaimDto } from '../../../api/rentMyApi';
import { useEnv } from '../../../context/env.context';

interface CardComponentProps {
  claim: QuotallClaimDto;
}

export const CustomClaimCard = ({ claim }: CardComponentProps) => {
  const { apiServerUrl } = useEnv();

  const { booking } = claim;
  const { item, bookingStatus, startDate, endDate, sharer, renter } = booking;
  const { name = '', images = [] } = item;
  const image = images[0];
  const src = image?.compressedPath ? image.compressedPath : '/assets/img/placeholder.jpg';

  const profileSource = renter.image
    ? renter.image.path
    : '/assets/img/profile-pic-placeholder.webp';

  const startOfBooking = new Date(startDate || '');
  const endOfBooking = new Date(endDate || '');
  const sameMonth = isSameMonth(startOfBooking, endOfBooking);
  const startDateFormat = sameMonth ? 'dd' : 'dd MMM';
  const endDateFormat = sameMonth ? 'dd MMM, yy' : 'dd MMM, yy';

  const dateStr = `${format(startOfBooking, startDateFormat)} to ${format(endOfBooking, endDateFormat)}`;

  const bookingObject = {
    date: dateStr,
    itemName: name,
    profileImageSrc: profileSource,
    profileName: renter.name,
    status: bookingStatus,
    itemImage: src,
  };

  const getClaimStatus = () => {

    // Agreed AGRE
    // Declined DECL
    // Amended AMEN
    // Pending PEND

    if (!claim.quotallOutcomeType || !claim.quotallOutcomeType.code) {
        return {
            text: "In review",
            class: "in-review"
        };
    }

    switch (claim.quotallOutcomeType.code) {
        case "AGRE":
            return {
            text: "Settled",
            class: "settled"
            };
        case "DECL":
            return {
            text: "Declined",
            class: "refused"
            };
        case "PEND":
            return {
            text: "In review",
            class: "in-review"
            };
        case "AMEN":
            return {
            text: "Amended",
            class: "settled"
            };
        default:
            return {
            text: "In review",
            class: "in-review"
            };
    }
  };

  return (
    <Card className="booking-status-card auto-margin">
      <CardBody>
        <Container fluid className={'booking-status-container'}>
          <Row>
            <Col xs="4">
              <CardImg className="booking-item-image" src={bookingObject.itemImage} alt="Item image" />
            </Col>
            <Col xs="8" className="padding-left-25">
              <Row>
                <CardText color="#303332" className="date-range">
                  {bookingObject.date}
                </CardText>
              </Row>
              <Row>
                <CardText className="item-name">{bookingObject.itemName}</CardText>
              </Row>
            </Col>
          </Row>
        </Container>
      </CardBody>
      <CardText className={'claim-status-banner banner-' + getClaimStatus().class}>{getClaimStatus().text}</CardText>
    </Card>
  );
};
