import React, { useState } from "react";
import {
  Button,
  CardImg,
  CardText,
  CarouselItem,
  Col,
  Collapse,
  Row,
} from "reactstrap";
import StarRating from "./StarRating";
import {
  RentMyUserPublicDetailedDto,
  ReviewDetailedDto,
} from "../../api/rentMyApi";
import { useWindowSize } from "../../utils/WindowSize";
import ItemEmbed from "./ItemEmbed";
import { useEnv } from "../../context/env.context";

const workoutOverallReviewValue = (itemResponse: ReviewDetailedDto) => {
  if (
    !itemResponse ||
    !itemResponse.communicationRating ||
    !itemResponse.pickUpRating ||
    !itemResponse.dropOffRating ||
    !itemResponse.accuracyRating ||
    !itemResponse.qualityRating ||
    !itemResponse.valueRating
  ) {
    return 0;
  }

  return (
    (itemResponse.communicationRating +
      itemResponse.pickUpRating +
      itemResponse.dropOffRating +
      itemResponse.accuracyRating +
      itemResponse.qualityRating +
      itemResponse.valueRating) /
    6
  );
};

interface ReviewProps {
  user: RentMyUserPublicDetailedDto;
  review: ReviewDetailedDto;
  showProduct: boolean;
}

const Review: React.FC<ReviewProps> = ({ user, review, showProduct }) => {
  const maxDescriptionLength = 100;

  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [showFullText, setShowFullText] = useState(
    !(review.description && review.description.length > maxDescriptionLength)
  );
  const { apiServerUrl } = useEnv();
  const { width } = useWindowSize();
  const isMobileView = width < 768; // Adjust this breakpoint value as needed

  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
  };

  const toggle = () => setIsOpen(!isOpen);

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === review.images!.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? review.images!.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex: number) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = review.images?.map((img, index) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={index}
      >
        <img src={img.path} alt={img.name} />
      </CarouselItem>
    );
  });

  const averageRating = workoutOverallReviewValue(review);

  const truncateString = (str: string): string => {
    if (str.length > maxDescriptionLength) {
      return str.slice(0, maxDescriptionLength) + "...";
    } else {
      return str;
    }
  };

  const shortDateFormat = (date: Date): string => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = date.getDate();

    const monthIndex = date.getMonth();
    const monthName = monthNames[monthIndex];

    const year = date.getFullYear().toString().slice(-2);

    return `${day} ${monthName} ${year}`;
  };
  return (
    <div className="padding-top-10 review-card">
      {showProduct && <ItemEmbed item={review.item} />}
      <div className="flex align-items-start">
        <img
          src={
            user.profileImage?.compressedPath
              ? user.profileImage?.compressedPath
              : "assets/img/profile-pic-placeholder.webp"
          }
          alt={user.name}
          className="rounded-circle img-review-thumbnail"
        />
        <div className="ml-2 colour-black">
          <p className="mb-0">{user.name?.split(" ")[0]}</p>
          <p className="small grey mb-0">
            {review.creation ? shortDateFormat(review?.creation) : ""}
          </p>
        </div>
      </div>

      <Row className="margin-top-10">
        <Col xs="12">
          <StarRating rating={averageRating} />
          <CardText>
            {
              <span className={showFullText ? "" : "review-text"}>
                {showFullText
                  ? review.description
                  : truncateString(review.description || "")}
              </span>
            }
            {!showFullText && (
              <p
                className="review-show-more text-align-center"
                onClick={toggleShowFullText}
              >
                Show more
              </p>
            )}
          </CardText>
        </Col>
      </Row>

      <Collapse isOpen={isOpen} className="auto-margin full-width">
        <Row className="mt-2">
          {review.images?.length && (
            <Col xs="12">
              <Row>
                {review.images?.map((imageDto) => (
                  <Col key={imageDto.id} xs="6" sm="6" md="4" className="mb-3">
                    <CardImg
                      top
                      className="img-fluid review-image cursor-pointer"
                      src={imageDto.compressedPath}
                      alt="Image"
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          )}
        </Row>
      </Collapse>
      {isOpen && <br />}
      {review.images?.length && (
        <Row>
          <Col xs="12" className="text-center">
            <Button onClick={toggle}>
              {isOpen ? "Hide Images" : "Show Images"}
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Review;
