import React from "react";
import { Col, FormFeedback, FormGroup, Input, Row } from "reactstrap";
import { Location } from "../../api/rentMyApi";
import Autocomplete from "react-google-autocomplete";
import { useTranslation } from "react-i18next";

interface LocationDataProps {
  locationData: Location;
  setLocationData: React.Dispatch<React.SetStateAction<Location>>;
  isAddressLine1Invalid: boolean;
  isCityInvalid: boolean;
  isCountryInvalid: boolean;
  isPostcodeInvalid: boolean;
  updateErrors: (field: string) => void;
  onAutoComplete: (newLocationData: Location) => void;
}

const LocationData: React.FC<LocationDataProps> = ({
  locationData,
  setLocationData,
  isAddressLine1Invalid,
  isCityInvalid,
  isCountryInvalid,
  isPostcodeInvalid,
  updateErrors,
  onAutoComplete,
}) => {
  const { t } = useTranslation();

  const inputMaxLength = 100;

  /**
   * Handle input change
   */
  function locationInputChanges(e: React.ChangeEvent<HTMLInputElement>): void {
    const newLocationData: Location = new Location({
      ...locationData,
      latitude: undefined,
      longitude: undefined,
      [e.target.name]: e.target.value,
    });
    setLocationData(newLocationData);
    updateErrors(e.target.name);
  }

  /**
   * Handle Autocomplete input data
   */
  const handlePlaceAutocomplete = (
    placeData: google.maps.places.PlaceResult
  ) => {
    const address = placeData.formatted_address,
      addressArray = placeData.address_components,
      addressLine = getStreetAddress(addressArray ?? []),
      city = getCity(addressArray ?? []),
      country = getCountry(addressArray ?? []),
      postCode = getPostCode(addressArray ?? []),
      latValue = placeData.geometry!.location!.lat() ?? 0,
      lngValue = placeData.geometry!.location!.lng() ?? 0;

    const newLocationData: Location = {
      ...locationData,
      init: function (_data?: any): void {},
      toJSON: function (data?: any) {},
    };

    newLocationData.name = address;
    newLocationData.addressLine1 = addressLine;
    newLocationData.city = city;
    newLocationData.country = country;
    newLocationData.postcode = postCode;
    newLocationData.latitude = latValue;
    newLocationData.longitude = lngValue;

    onAutoComplete(newLocationData);
  };

  /**
   * Get street address
   */
  const getStreetAddress = (
    addressArray: google.maps.GeocoderAddressComponent[]
  ) => {
    let streetAddress = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "street_number" === addressArray[i].types[0]
      ) {
        streetAddress += addressArray[i].long_name + " ";
      }
      if (addressArray[i].types[0] && "route" === addressArray[i].types[0]) {
        streetAddress += addressArray[i].long_name + " ";
      }
    }
    return streetAddress;
  };

  /**
   * Get city
   */
  const getCity = (addressArray: google.maps.GeocoderAddressComponent[]) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "postal_town" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  /**
   * Get country
   */
  const getCountry = (addressArray: google.maps.GeocoderAddressComponent[]) => {
    let country = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && "country" === addressArray[i].types[0]) {
        country = addressArray[i].long_name;
        return country;
      }
    }
  };

  /**
   * Get country
   */
  const getPostCode = (
    addressArray: google.maps.GeocoderAddressComponent[]
  ) => {
    let postCode = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "postal_code" === addressArray[i].types[0]
      ) {
        postCode = addressArray[i].long_name;
        return postCode;
      }
    }
  };

  return (
    <Row>
      <Col sm={12}>
        <FormGroup>
          <label htmlFor="item-listing-addressLine">
            {t("item_listing_location_search")}
          </label>
          <Autocomplete
            // apiKey={GOOGLE_MAPS_API_KEY}
            onPlaceSelected={(place) => handlePlaceAutocomplete(place)}
            className="form-control pac-containe input-field"
            aria-placeholder={"Start typing your address..."}
            options={{
              componentRestrictions: { country: "uk" },
              types: ["geocode", "establishment"],
            }}
          />
        </FormGroup>
      </Col>
      <Col sm={6}>
        <FormGroup>
          <label htmlFor="item-listing-addressLine1">
            {t("item_listing_location_address_line_1")}
          </label>
          <Input
            maxLength={inputMaxLength}
            invalid={isAddressLine1Invalid}
            id="item-listing-addressLine1"
            className="input-field"
            name="addressLine1"
            type="text"
            placeholder={t("item_listing_location_address_line_1_placeholder")}
            onChange={locationInputChanges}
            value={locationData.addressLine1 ?? ""}
          />
          {isAddressLine1Invalid && (
            <FormFeedback>
              {t("location_address_line_one_required")}
            </FormFeedback>
          )}
        </FormGroup>
      </Col>
      <Col sm={6}>
        <FormGroup>
          <label htmlFor="item-listing-addressLine2">
            {t("item_listing_location_address_line_2")}
          </label>
          <Input
            maxLength={inputMaxLength}
            id="item-listing-addressLine2"
            className="input-field"
            name="addressLine2"
            type="text"
            placeholder={t("item_listing_location_address_line_2_placeholder")}
            onChange={locationInputChanges}
            value={locationData.addressLine2 ?? ""}
          />
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <label htmlFor="item-listing-city">
            {t("item_listing_location_city")}
          </label>
          <Input
            maxLength={inputMaxLength}
            invalid={isCityInvalid}
            id="item-listing-city"
            className="input-field"
            name="city"
            type="text"
            placeholder={t("item_listing_location_city_placeholder")}
            onChange={locationInputChanges}
            value={locationData.city ?? ""}
          />
          {isCityInvalid && (
            <FormFeedback>{t("location_city_required")}</FormFeedback>
          )}
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <label htmlFor="item-listing-country">
            {t("item_listing_location_country")}
          </label>
          <Input
            maxLength={inputMaxLength}
            invalid={isCountryInvalid}
            id="item-listing-country"
            className="input-field"
            name="country"
            type="text"
            placeholder={t("item_listing_location_country_placeholder")}
            onChange={locationInputChanges}
            value={locationData.country ?? ""}
          />
          {isCountryInvalid && (
            <FormFeedback>{t("location_country_required")}</FormFeedback>
          )}
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <label htmlFor="item-listing-postcode">
            {t("item_listing_location_postcode")}
          </label>
          <Input
            maxLength={inputMaxLength}
            invalid={isPostcodeInvalid}
            id="item-listing-postcode"
            className="input-field"
            name="postcode"
            type="text"
            placeholder={t("item_listing_location_postcode_placeholder")}
            onChange={locationInputChanges}
            value={locationData.postcode ?? ""}
          />
          {isPostcodeInvalid && (
            <FormFeedback>{t("location_Postcode_required")}</FormFeedback>
          )}
        </FormGroup>
      </Col>
    </Row>
  );
};

export default LocationData;
