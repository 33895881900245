import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, FormFeedback, FormGroup, Input, Row } from "reactstrap";
import { Location } from "../../api/rentMyApi";

interface Props {
  locationData: Location;
  handleSaveUserLocation: (localLocationData: any) => Promise<void>;
  isUserLocationProcessing: boolean;
}

export const UserLocationForm: React.FC<Props> = ({
  locationData,
  handleSaveUserLocation,
  isUserLocationProcessing,
}) => {
  const { t } = useTranslation();
  const inputMaxLength = 100;
  const [localLocationData, setLocalLocationData] =
    useState<Location>(locationData);

  const handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newLocalData = {
      ...localLocationData,
      [event.target.name]: event.target.value,
    };
    setLocalLocationData(newLocalData as Location);
  };

  return (
    <Row>
      <Col xs={12} className="mb-3 no-top-margin">
        <i>{t("item_booking_confirmation_update_location")}</i>
      </Col>
      <Col sm={12}>
        <FormGroup>
          <label htmlFor="user-location-addressLine1">
            {t("item_listing_location_address_line_1")}
          </label>
          <Input
            maxLength={inputMaxLength}
            invalid={!(localLocationData && localLocationData.addressLine1)}
            id="user-location-addressLine1"
            name="addressLine1"
            type="text"
            placeholder={t("item_listing_location_address_line_1_placeholder")}
            onChange={handleLocationChange}
            value={(localLocationData && localLocationData.addressLine1) ?? ""}
            className="input-field"
          />
          {!localLocationData.addressLine1 && (
            <FormFeedback>
              {t("location_address_line_one_required")}
            </FormFeedback>
          )}
        </FormGroup>
      </Col>
      <Col sm={12}>
        <FormGroup>
          <label htmlFor="user-location-addressLine2">
            {t("item_listing_location_address_line_2")}
          </label>
          <Input
            maxLength={inputMaxLength}
            id="user-location-addressLine2"
            name="addressLine2"
            type="text"
            placeholder={t("item_listing_location_address_line_2_placeholder")}
            onChange={handleLocationChange}
            value={localLocationData.addressLine2 ?? ""}
            className="input-field"
          />
        </FormGroup>
      </Col>
      <Col sm={12}>
        <FormGroup>
          <label htmlFor="user-location-addressLine3">
            {t("item_listing_location_address_line_3")}
          </label>
          <Input
            maxLength={inputMaxLength}
            id="user-location-addressLine3"
            name="addressLine3"
            type="text"
            placeholder={t("item_listing_location_address_line_3_placeholder")}
            onChange={handleLocationChange}
            value={localLocationData.addressLine3 ?? ""}
            className="input-field"
          />
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <label htmlFor="user-location-city">
            {t("item_listing_location_city")}
          </label>
          <Input
            maxLength={inputMaxLength}
            invalid={!localLocationData.city}
            id="user-location-city"
            name="city"
            type="text"
            placeholder={t("item_listing_location_city_placeholder")}
            onChange={handleLocationChange}
            value={localLocationData.city ?? ""}
            className="input-field"
          />
          {!localLocationData.city && (
            <FormFeedback>{t("location_city_required")}</FormFeedback>
          )}
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <label htmlFor="user-location-country">
            {t("item_listing_location_country")}
          </label>
          <Input
            maxLength={inputMaxLength}
            invalid={!localLocationData.country}
            id="user-location-country"
            name="country"
            type="text"
            // placeholder={t("item_listing_location_country_placeholder")}
            onChange={handleLocationChange}
            value={localLocationData.country ?? "United Kingdom"}
            className="input-field"
          />
          {!localLocationData.country && (
            <FormFeedback>{t("location_country_required")}</FormFeedback>
          )}
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <label htmlFor="user-location-postcode">
            {t("item_listing_location_postcode")}
          </label>
          <Input
            maxLength={inputMaxLength}
            invalid={!localLocationData.postcode}
            id="user-location-postcode"
            name="postcode"
            type="text"
            placeholder={t("item_listing_location_postcode_placeholder")}
            onChange={handleLocationChange}
            value={localLocationData.postcode ?? ""}
            className="input-field"
          />
          {!localLocationData.postcode && (
            <FormFeedback>{t("location_Postcode_required")}</FormFeedback>
          )}
        </FormGroup>
      </Col>
      <Col xs={12}>
        <button
          onClick={() => handleSaveUserLocation(localLocationData)}
          type="button"
          className="btn btn-primary btn-lg btn-block"
          disabled={
            !(
              localLocationData.addressLine1 &&
              localLocationData.city &&
              localLocationData.country &&
              localLocationData.postcode
            )
          }
        >
          {isUserLocationProcessing ? (
            <div className="loading-spinner-small"></div>
          ) : (
            t("save")
          )}
        </button>
      </Col>
    </Row>
  );
};
