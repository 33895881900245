import * as React from "react";
import { useEffect, useState } from "react";
import { CategoryInfo } from "../../models/interfaces/CategoryInfo";
import BasicModal from "./BasicModal";
import MultiLayeredCategoriesSelectorClickableRow from "./MultiLayeredCategoriesSelectorClickableRow";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { cloneDeep } from "lodash";
import BackButton from "./BackButton";
import { Button, Col, Input, Row } from "reactstrap";
import { CategoryClient, CategorySimpleDto } from "../../api/rentMyApi";
import { toast } from "react-toastify";

interface MultiLayeredCategoriesSelectorProps {
  categoryClient: CategoryClient; // Replace 'any' with the actual type
  rootCategories: CategoryInfo[];
  selectedCategories: CategoryInfo[];
  setSelectedCategories: (categories: CategoryInfo[]) => void;
  justOneSelectedCategoryAllowed: boolean;
  toggleModal: () => void;
  modalState: boolean;
}

export default function MultiLayeredCategoriesSelector({
  categoryClient,
  rootCategories,
  selectedCategories,
  setSelectedCategories,
  justOneSelectedCategoryAllowed,
  toggleModal,
  modalState,
}: MultiLayeredCategoriesSelectorProps) {
  const [categories, setCategories] = useState<CategoryInfo[]>([]);
  const [categoryRoute, setCategoryRoute] = useState<CategoryInfo[]>([]);

  const [currentLevelCategoryHeader, setCurrentLevelCategoryHeader] =
    useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const [categorySearchValue, setCategorySearchValue] = useState<string>("");
  const [searchFilteredCategories, setSearchFilteredCategories] = useState<
    CategorySimpleDto[] | undefined
  >();

  useEffect(() => {
    setCategories(rootCategories);
  }, [rootCategories]);

  // const toggleModal = () => {
  //   setModalState(!modalState);
  // };

  const handleCategoryClick = (
    categoryInfo: CategoryInfo,
    event: React.MouseEvent
  ) => {
    const { noChildren } = categoryInfo;
    event.preventDefault();

    if (loading) {
      return;
    }
    setLoading(true);

    if (noChildren) {
      onCheckboxChange(categoryInfo);
      setLoading(false);
      return;
    }

    getCategoryAndChildren(categoryInfo, true);
  };

  const goBack = () => {
    if (loading) {
      return;
    }

    const focusedCategory = RemoveCategoryLayerReturnNewFocusedCategory();

    if (!focusedCategory) {
      setCategories(rootCategories);
    } else {
      setLoading(true);

      getCategoryAndChildren(focusedCategory, false);
    }
  };

  const getCategoryAndChildren = (
    categoryInfo: CategoryInfo,
    wantToAddToCategoryHierarchy: boolean
  ) => {
    categoryClient
      .children(categoryInfo.id)
      .then((response) => {
        // console.log(response.map((x) => ({"id": x.id, "name": x.path})))
        // console.log(response.map((x) => (`${x.name}: ${x.id}`)));
        if (wantToAddToCategoryHierarchy) {
          AddCategoryLayer(categoryInfo);
        }

        if (response) {
          const categoriesInfo: CategoryInfo[] = response.map((category) => ({
            id: category.id as string,
            name: category.name as string,
            noChildren: !category.children
              ? true
              : category.children.length == 0,
          }));
          setCategories(categoriesInfo);
        } else {
          // console.error("valid response but no data field was returned");
        }
      })
      .catch((response) => {
        // console.error("Message NOT sent correctly");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const RemoveCategoryLayerReturnNewFocusedCategory = () => {
    const copy = cloneDeep(categoryRoute);
    copy.pop();
    setCategoryRoute(copy);
    setCurrentLevelCategoryHeader(formatRouteIntoHeaderOutput(copy));

    return copy[copy.length - 1];
  };

  const AddCategoryLayer = (newRoute: CategoryInfo) => {
    const copy = cloneDeep(categoryRoute);
    copy.push(newRoute);
    setCategoryRoute(copy);
    setCurrentLevelCategoryHeader(formatRouteIntoHeaderOutput(copy));
  };

  const formatRouteIntoHeaderOutput = (
    mostRecentRoute: CategoryInfo[]
  ): string => {
    let header = "";

    for (let i = 0; i < mostRecentRoute.length; i++) {
      header += mostRecentRoute[i].name;

      if (i != mostRecentRoute.length - 1) {
        header += " > ";
      }
    }

    return header;
  };

  const onCheckboxChange = (categoryInfo: CategoryInfo) => {
    const currentRoute = formatRouteIntoHeaderOutput(categoryRoute);
    const newValue: CategoryInfo = {
      id: categoryInfo.id,
      name: currentRoute + " > " + categoryInfo.name,
      noChildren: categoryInfo.noChildren,
    };

    if (justOneSelectedCategoryAllowed) {
      setSelectedCategories([newValue]);
      toggleModal();
    } else {
      addOrRemoveCategoryToSelectedCategoryArray(newValue);
    }
  };

  function addOrRemoveCategoryToSelectedCategoryArray(
    categoryInfo: CategoryInfo
  ) {
    const focusedCategoryIndex = selectedCategories.findIndex(
      (category) => category.id == categoryInfo.id
    );

    if (focusedCategoryIndex == -1) {
      addCategoryToSelected(categoryInfo);
    } else {
      removeCategoryFromSelected(focusedCategoryIndex);
    }
  }

  function addCategoryToSelected(categoryInfo: CategoryInfo) {
    const selectedCategoryCopy = cloneDeep(selectedCategories);
    selectedCategoryCopy.push(categoryInfo);
    setSelectedCategories(selectedCategoryCopy);
  }

  function removeCategoryFromSelected(focusedCategoryIndex: number) {
    const selectedCategoryCopy = cloneDeep(selectedCategories);
    selectedCategoryCopy.splice(focusedCategoryIndex, 1);
    setSelectedCategories(selectedCategoryCopy);
  }

  const shouldCheckboxBeSelected = (categoryInfo: CategoryInfo): boolean => {
    return (
      selectedCategories.findIndex(
        (category) => category.id == categoryInfo.id
      ) != -1
    );
  };

  const displayNames = (): string => {
    if (selectedCategories.length == 0) {
      return "";
    }

    const seperator = " |||||||||| ";

    const filtered = selectedCategories.filter(
      (category) => !category.noChildren
    );

    return filtered.map((category) => category.name).join(seperator);
  };

  const performCategorySearch = () => {
    categoryClient
      .category2(undefined, false, true, false, categorySearchValue)
      .then((categorySimple) => {
        if (categorySimple.data && categorySimple.data.length === 0) {
          toast.warning(t("item_listing_category_search_empty"));
        } else {
          setSearchFilteredCategories(categorySimple.data);
        }
      })
      .catch((e) => {
        // console.log("Error at performCategorySearch")
        console.log(e);
      });
  };

  const clearSearch = () => {
    setCategorySearchValue("");
    setSearchFilteredCategories(undefined);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") performCategorySearch();
  };

  const multiLayerCategoryHeader = () => {
    return (
      <>
        {categoryRoute.length === 0 && (
          <Row className="category-search-section mb-3">
            <Col sm={8}>
              <Input
                className="form-control input-field"
                type="text"
                value={categorySearchValue}
                placeholder={t("item_listing_category_search_placeholder")}
                onChange={(e) => setCategorySearchValue(e.target.value)}
                onKeyDown={onKeyDown}
              />
            </Col>
            <Col className="category-search-section-buttons" sm={4}>
              <Button color={"primary"} onClick={performCategorySearch}>
                <FontAwesomeIcon icon={faSearch} />
              </Button>
              <Button color={"secondary"} onClick={clearSearch}>
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </Col>
          </Row>
        )}
      </>
    );
  };

  const multiLayeredCategorySelector = () => {
    const categoriesCopy: CategoryInfo[] = cloneDeep(categories);
    const sorted: CategoryInfo[] = categoriesCopy.sort((a, b) =>
      a.name < b.name ? -1 : 1
    );
    return (
      <>
        <div className="flex">
          {categoryRoute.length != 0 && <BackButton onClick={goBack} />}
          {currentLevelCategoryHeader && (
            <h1 className="category-header">{currentLevelCategoryHeader}</h1>
          )}
        </div>
        {searchFilteredCategories && (
          <>
            {searchFilteredCategories.length > 1 &&
              searchFilteredCategories
                .filter((x) => x.name !== x.path)
                .map((category, index) => {
                  return (
                    <MultiLayeredCategoriesSelectorClickableRow
                      key={"fewefnwefniwe" + index}
                      category={category}
                      loading={loading}
                      handleCategoryClick={() =>
                        onCheckboxChange(category as CategoryInfo)
                      }
                      shouldShowToggle={true}
                      shouldCheckboxBeSelected={shouldCheckboxBeSelected}
                      pathInsteadOfName={true}
                    />
                  );
                })}
          </>
        )}

        {searchFilteredCategories === undefined &&
          sorted.map((category) => {
            return (
              <MultiLayeredCategoriesSelectorClickableRow
                key={category.id}
                category={category}
                loading={loading}
                shouldShowToggle={category.noChildren === true}
                handleCategoryClick={(
                  event: React.MouseEvent<Element, MouseEvent>
                ) => {
                  clearSearch();
                  handleCategoryClick(category, event);
                }}
                shouldCheckboxBeSelected={shouldCheckboxBeSelected}
              />
            );
          })}
      </>
    );
  };

  return (
    <>
      <BasicModal
        modalBody={multiLayeredCategorySelector()}
        modalHeader={multiLayerCategoryHeader()}
        modalState={modalState}
        toggleModal={toggleModal}
      />
    </>
  );
}
