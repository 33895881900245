import React from "react";

import { Button, Modal } from "reactstrap";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

interface ModalWithImageProps {
  isOpen: boolean;
  onClose: () => any;
  image: string;
  title: string;
  description: string;
  descriptionElement?: JSX.Element;
  defaultButtonText?: string;
  onDefaultButtonClick?: () => any;
  primaryButtonText: string;
  onPrimaryButtonClick: () => any;
  isDefault: boolean;
  urlOpenInNewTabButtonSetup?: { text: string; url: string };
}

const ModalWithImage = (props: ModalWithImageProps) => {
  const { t } = useTranslation();
  const {
    isOpen,
    onClose,
    image,
    title,
    description,
    descriptionElement,
    defaultButtonText,
    onDefaultButtonClick,
    primaryButtonText,
    onPrimaryButtonClick,
    isDefault,
    urlOpenInNewTabButtonSetup,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      centered
      size="sm"
      toggle={onClose}
      className="modal-with-image"
    >
      <button className="modal-with-image-close-button" onClick={onClose}>
        <FontAwesomeIcon icon={faXmark} />
      </button>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="row">
              <img
                src={image}
                alt={t("image_placeholder")}
                className={isDefault ? "image-modal-default" : "image-modal"}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col modal-with-image-div">
            <h3>{title}</h3>
            <p>{description}</p>
            {descriptionElement && descriptionElement}
            <br />
            <div className="row">
              {defaultButtonText !== undefined ?? (
                <div className="col">
                  <Button
                    onClick={onDefaultButtonClick}
                    outline
                    color="primary"
                    className="modal-with-image-button"
                  >
                    {defaultButtonText}
                  </Button>
                </div>
              )}

              <div className="col">
                {urlOpenInNewTabButtonSetup && (
                  <div className="anchor-as-button-wrapper">
                    <a
                      href={urlOpenInNewTabButtonSetup.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="modal-with-image-button"
                    >
                      {urlOpenInNewTabButtonSetup.text}
                    </a>
                  </div>
                )}
                {!urlOpenInNewTabButtonSetup && (
                  <Button
                    color="primary"
                    onClick={onPrimaryButtonClick}
                    className="modal-with-image-button"
                  >
                    {primaryButtonText}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalWithImage;
