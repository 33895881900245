import { CancellationPolicy, PricePeriod } from "../../api/rentMyApi";
import { Toggle } from "../../models/interfaces/toggle";
import { PricePeriodError } from "../../models/interfaces/PricePeriodEnhanced";
import { ItemValueProperties } from "../../models/interfaces/ItemValueProperties";

export const STANDARD_PRICE_PERIOD_NAME = "standard";

export const getStandardPricePeriod = (
  pricePeriods: PricePeriod[]
): PricePeriod | undefined => {
  return pricePeriods.find((pricePeriod) => {
    return (
      pricePeriod.name?.toLowerCase() ==
      STANDARD_PRICE_PERIOD_NAME.toLowerCase()
    );
  });
};

export interface DefaultErrors {
  name: boolean;
  images: boolean;
  description: boolean;
  location: {
    addressLine1: boolean;
    city: boolean;
    country: boolean;
    postcode: boolean;
  };
  category: boolean;
  availability: boolean;
  bookingTerms: {
    minBookingPeriod: boolean;
    maxBookingPeriod: boolean;
    minHourly: boolean;
    maxHourly: boolean;
    deposit: boolean;
  };
  standardPeriodsErrors: PricePeriodError[];
  customPeriodErrors: PricePeriodError[];
  terms: {
    minBookingPeriodIsInvalid: boolean;
    maxBookingPeriodIsInvalid: boolean;
    turnaroundIsInvalid: boolean;
    pickupTimeIsInvalid: boolean;
    checkoutTimeIsInvalid: boolean;
    minHourlyIsInvalid: boolean;
    maxHourlyIsInvalid: boolean;
    turnaroundHourlyIsInvalid: boolean;
    bookingDepositIsInvalid: boolean;
  };
  itemValueProperties: {
    originalValue: boolean;
    itemValue: boolean;
    purchaseDate: boolean;
  };
}

export function ItemValueDefault(): ItemValueProperties {
  return {
    originalValue: undefined,
    itemValue: undefined,
    purchaseDate: new Date(),
    receipt: false,
  };
}

export function getDefaultErrors(): DefaultErrors {
  const standardPeriodsErrors: PricePeriodError[] = [];
  const customPeriodErrors: PricePeriodError[] = [];
  return {
    name: false,
    images: false,
    description: false,
    location: {
      addressLine1: false,
      city: false,
      country: false,
      postcode: false,
    },
    category: false,
    availability: false,
    bookingTerms: {
      minBookingPeriod: false,
      maxBookingPeriod: false,
      minHourly: false,
      maxHourly: false,
      deposit: false,
    },
    standardPeriodsErrors,
    customPeriodErrors,
    terms: {
      minBookingPeriodIsInvalid: false,
      maxBookingPeriodIsInvalid: false,
      turnaroundIsInvalid: false,
      pickupTimeIsInvalid: false,
      checkoutTimeIsInvalid: false,
      minHourlyIsInvalid: false,
      maxHourlyIsInvalid: false,
      turnaroundHourlyIsInvalid: false,
      bookingDepositIsInvalid: false,
    },
    itemValueProperties: {
      originalValue: false,
      itemValue: false,
      purchaseDate: false,
    },
  };
}

const recursiveCheck = (value: unknown) => {
  if (value === undefined || value === null) {
    return false;
  } else if (Array.isArray(value)) {
    return value.some((error) => recursiveCheck(error));
  } else if (typeof value === "object") {
    return Object.values(value).some((error) => recursiveCheck(error));
  }

  return value;
};

export const getTopError = (newErrors: any) =>
  Object.keys(newErrors).find((key) => {
    const value = newErrors[key];

    return recursiveCheck(value);
  });

export function availabilityValuesDefault(): Toggle[] {
  return [
    { name: "January", value: "January", isSelected: true },
    { name: "February", value: "February", isSelected: true },
    { name: "March", value: "March", isSelected: true },
    { name: "April", value: "April", isSelected: true },
    { name: "May", value: "May", isSelected: true },
    { name: "June", value: "June", isSelected: true },
    { name: "July", value: "July", isSelected: true },
    { name: "August", value: "August", isSelected: true },
    { name: "September", value: "September", isSelected: true },
    { name: "October", value: "October", isSelected: true },
    { name: "November", value: "November", isSelected: true },
    { name: "December", value: "December", isSelected: true },
  ];
}

export function flexibleCancellationDescription(): JSX.Element {
  return (
    <>
      <b>Cancelling up to 24 hours before collection date:</b>
      <p>Free cancellation up to 24 hours before collection date.</p>
      <p>
        After that, cancel before collection and get a full refund, minus the
        first day’s charge and service fee.
      </p>
      <br />
      {cancellationSharedSection()}
    </>
  );
}

export function moderateCancellationDescription(): JSX.Element {
  return (
    <>
      <b>Cancelling up to 5 days before collection date:</b>
      <p>Free cancellation up to 5 days before collection.</p>
      <br />
      <b>Cancelling made within 5 days of collection date:</b>
      <p>
        Cancellations made within 5 days of the collection date get a 50%
        refund, minus the first day’s charge and service fee.
      </p>
      <br />
      {cancellationSharedSection()}
    </>
  );
}

export function strictCancellationDescription(): JSX.Element {
  return (
    <>
      <b>Cancelling 14 days before booking start date:</b>
      <p>
        Free cancellation for 48 hours after the booking has been made, as long
        as the renter cancels at least 14 days before the booking.
      </p>
      <br />
      <b>Cancelling 7 to 14 days before booking start date:</b>
      <p>
        Renters can cancel up to 7 days before collection and get a 50% refund
        of the daily rate, but not the service fee.
      </p>
      <br />
      <b>Cancelling within 7 days of booking start date:</b>
      <p>
        Cancellations made within 7 days of the collection date are not due a
        refund.
      </p>
      <br />
      {cancellationSharedSection()}
    </>
  );
}

function cancellationSharedSection(): JSX.Element {
  return (
    <>
      <p>
        <b className="bold">Note:</b> Renters won’t get a refund of the service
        fee if they’ve received 3 service fee refunds in the last 12 months or
        if the cancelled booking overlaps with another booking.
      </p>
      <br />
      <b>Other factors to note:</b>
      <ul>
        <li>
          Renters will always be refunded the security deposit if they cancel
          before collection.
        </li>
        <br />
        <li>
          If there is an issue with the item being rented (for example, it is
          faulty or not working as described) renters should contact us within
          24 hours of finding the issue. In qualifying cases we will cancel the
          booking and issue a refund.
        </li>
        <br />
        <li>
          We may be able to issue a refund if a renter has to cancel because of
          an emergency.
        </li>
      </ul>
    </>
  );
}

export const getCancellationPolicyDefault = (
  defaultValue: CancellationPolicy
) => {
  return [
    {
      title: "Flexible",
      body: flexibleCancellationDescription(),
      label: "Set as cancellation policy",
      toggleSelected: defaultValue === CancellationPolicy["Flexible"],
      value: CancellationPolicy["Flexible"],
    },
    {
      title: "Moderate",
      body: moderateCancellationDescription(),
      label: "Set as cancellation policy",
      toggleSelected: defaultValue === CancellationPolicy["Moderate"],
      value: CancellationPolicy["Moderate"],
    },
    {
      title: "Strict",
      body: strictCancellationDescription(),
      label: "Set as cancellation policy",
      toggleSelected: defaultValue === CancellationPolicy["Strict"],
      value: CancellationPolicy["Strict"],
    },
  ];
};
