import React, { useState } from "react";
// import "react-date-range/dist/styles.css"; // main css file
// import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange, Range } from "react-date-range";
import { DateRangePickerDirectionEnum } from "./DateRangePickerDirectionEnum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { toast } from "react-toastify";


const today = new Date(Date.now());
const defaultMonths = 2;
const mondayIndex = 1;
const maxDateYearsFromNow = 1;

export default function DateRangePicker({
  dateRangeState,
  setDateRangeState,

  removeInputSection = false,
  months = defaultMonths,
  direction = DateRangePickerDirectionEnum.VERTICAL,
  minDate = today,
  disabledDates = [],
  startDatePlaceholder = "Start Date",
  endDatePlaceholder = "End Date",
  showClearButton = true,
  setRemoveDateRangeInput,
  dateRangeSelected,
  minNumberOfDays,
  maxNumberOfDays,
}: {
  dateRangeState: Range;
  setDateRangeState: React.Dispatch<any>;
  removeInputSection?: boolean;
  months?: number;
  direction?: DateRangePickerDirectionEnum;
  minDate?: Date;
  disabledDates?: Date[];
  startDatePlaceholder?: string;
  endDatePlaceholder?: string;
  showClearButton?: boolean;
  setRemoveDateRangeInput?: React.Dispatch<React.SetStateAction<boolean>>;
  dateRangeSelected?: () => any;
  minNumberOfDays?: number;
  maxNumberOfDays?: number;
}) {
  const customerColor = "#EA554A";

  const [endDate, setEndDate] = useState<Date | null>(null);
  const [minimumDate, setMinimumDate] = useState<Date | null>(null);
  const [maximumDate, setMaximumDate] = useState<Date | null>(null);

  const onChange = (item: any) => {
    const firstClick = moment(item.selection.startDate).isSame(
      moment(item.selection.endDate)
    );

    if (!firstClick) {
      const startDate = moment(item.selection.startDate);
      const endDate = moment(item.selection.endDate);
      let currentDate = startDate;

      while (currentDate <= endDate) {
        const isDateDisabled = disabledDates.find((disabledDate) =>
          moment(disabledDate).isSame(currentDate, "day")
        );

        if (isDateDisabled) {
          toast.error(
            "You cannot select a range that includes disabled dates."
          );
          return;
        }

        currentDate = currentDate.add(1, "days");
      }
    }

    if (firstClick) {
      if (minNumberOfDays) {
        setMinimumDate(
          moment(item.selection.startDate).add(minNumberOfDays, "days").toDate()
        );
      } else {
        setMinimumDate(
          moment(item.selection.startDate).add(1, "days").toDate()
        );
      }
      if (maxNumberOfDays)
        setMaximumDate(
          moment(item.selection.startDate).add(maxNumberOfDays, "days").toDate()
        );
    } else {
      setMinimumDate(null);
      setMaximumDate(null);
    }

    if (
      dateRangeSelected &&
      endDate &&
      item.selection.endDate !== endDate &&
      !moment(item.selection.startDate).isSame(moment(item.selection.endDate))
    ) {
      dateRangeSelected();
    }

    const newDateRange = {
      startDate: item.selection.startDate,
      endDate: item.selection.endDate,
      key: "selection",
    };

    setEndDate(item.selection.endDate);
    setDateRangeState(newDateRange);
  };

  const clearDateSelection = () => {
    setDateRangeState({
      startDate: null,
      endDate: new Date(""),
      key: "selection",
    });

    if (setRemoveDateRangeInput) {
      setRemoveDateRangeInput(true);
    }
  };

  return (
    <>
      <div className="date-range-picker-container">
        <DateRange
          startDatePlaceholder={startDatePlaceholder}
          endDatePlaceholder={endDatePlaceholder}
          className={removeInputSection ? "date-range-remove-input" : ""}
          onChange={onChange}
          ranges={[dateRangeState]}
          moveRangeOnFirstSelection={removeInputSection}
          months={months}
          direction={direction}
          minDate={minimumDate ? minimumDate : minDate}
          maxDate={
            maximumDate
              ? maximumDate
              : moment().add(maxDateYearsFromNow, "years").toDate()
          }
          disabledDates={disabledDates}
          rangeColors={[customerColor]}
          weekStartsOn={mondayIndex}
        />
      </div>
      {showClearButton && !removeInputSection && (
        <div className="calander-clear" onClick={clearDateSelection}>
          <p>Clear</p>
          <FontAwesomeIcon icon={faEraser} />
        </div>
      )}
    </>
  );
}
