import moment from "moment";
import { DateRange, IDateRange } from "../../api/rentMyApi";

export const dateStringFormat = "YYYY-MM-DD";

export const numericalDefaultStartDate: string = "101"; // 1st of Jan
export const numericalDefaultStartMonth: string = "1";
export const numericalDefaultStartDateOfMonth: string = "01";
export const numericalDefaultEndDate: string = "1231"; // 31st of Dec
export const numericalDefaultEndMonth: string = "12";
export const numericalDefaultEndDateOfMonth: string = "31";

export const DateRangesInBackendFormat: DateRange[] = [
  new DateRange({ start: 101, end: 131 }),
  new DateRange({ start: 201, end: 229 }), // 29 as including leap year when it goes to Feb the 29th.
  new DateRange({ start: 301, end: 331 }),
  new DateRange({ start: 401, end: 430 }),
  new DateRange({ start: 501, end: 531 }),
  new DateRange({ start: 601, end: 630 }),
  new DateRange({ start: 701, end: 731 }),
  new DateRange({ start: 801, end: 831 }),
  new DateRange({ start: 901, end: 930 }),
  new DateRange({ start: 1001, end: 1031 }),
  new DateRange({ start: 1101, end: 1130 }),
  new DateRange({ start: 1201, end: 1231 }),
];

export function convertDateToNumerical(date: Date): number {
  const dateMonthString = moment(date).format("M");
  const dateDayOfMonthString = moment(date).format("DD");

  // DEVNOTE - month then day (with month leading zero removed, the day of month should have leading 0 if that is the case) (e.g. 415 for 15th of April)
  return +(dateMonthString + dateDayOfMonthString);
}

export function numericalToDate(numerical: number): Date {
  const numericalStr = numerical.toString();
  let monthPart: number;
  let dateOfMonthPart: number;
  const doesMonthTakeUpTwoChars = numericalStr.length === 4;

  if (doesMonthTakeUpTwoChars) {
    monthPart = parseInt(numericalStr.substring(0, 2)) - 1;
    dateOfMonthPart = parseInt(numericalStr.substring(2));
  } else {
    monthPart = parseInt(numericalStr.substring(0, 1)) - 1;
    dateOfMonthPart = parseInt(numericalStr.substring(1));
  }

  return new Date(new Date().getFullYear(), monthPart, dateOfMonthPart);
}

export function isDateBetweenTwoDates(
  focusedDate: Date,
  startNumerical: number,
  endNumerical: number
) {
  // as numericalDates are not specific to year, have them be the same year as the focused date. All have time of midnight so comparison works (thats why focusedDateMoment has '.startOf('day')')
  const focusedDateMoment = moment(focusedDate).startOf("day");
  const startDateMoment = moment(numericalToDate(startNumerical)).year(
    focusedDateMoment.year()
  );
  const endDateMoment = moment(numericalToDate(endNumerical)).year(
    focusedDateMoment.year()
  );

  // This is a problem with the current implementation, as we need to calculate all the dates up to the following 2 years we need to check for these additional years.
  const additionalYearsNeedToCheckFor = 1;
  for (
    let focusedYearIndex = 0;
    focusedYearIndex < additionalYearsNeedToCheckFor;
    focusedYearIndex++
  ) {
    const isThisYearGood = focusedDateMoment.isBetween(
      startDateMoment.add(focusedYearIndex, "years"),
      endDateMoment.add(focusedYearIndex, "years"),
      undefined,
      "[]"
    );
    if (isThisYearGood) {
      return true;
    }
  }

  return false;
}

export function showDateRangeSnippetString(dateRange: IDateRange): string {
  if (!dateRange.start || !dateRange.end) {
    return "";
  }

  const prettyDateMomentFormat = "Do [of] MMMM";
  const middleSymbol = " to the ";

  return (
    moment(numericalToDate(dateRange.start)).format(prettyDateMomentFormat) +
    middleSymbol +
    moment(numericalToDate(dateRange.end)).format(prettyDateMomentFormat)
  );
}

export function getAllDaysInclusiveOfTwoDates(
  startDate: Date,
  endDate: Date
): Date[] {
  const dates: Date[] = [moment(startDate).toDate(), moment(endDate).toDate()];

  const currDate = moment(startDate).startOf("day");
  const lastDate = moment(endDate).startOf("day");

  while (currDate.isBefore(lastDate, "day")) {
    dates.push(currDate.clone().toDate());
    currDate.add(1, "days");
  }

  console.log("Called getAllDaysInclusiveOfTwoDates!");
  console.log(dates);
  return dates;
}

export function isFocusedDayInPricePeriodDateRanges(
  focusedPricePeriodDateRanges: DateRange[] | undefined,
  focusedNumericalDay: number
) {
  if (!focusedPricePeriodDateRanges) {
    return false;
  }

  for (const dateRange of focusedPricePeriodDateRanges) {
    if (
      isFocusedDayInDateRange(
        focusedNumericalDay,
        dateRange.start,
        dateRange.end
      )
    ) {
      return true;
    }
  }

  return false;
}

// logic that allows the start date to be later in the year than the end date. This is allowed as the range can go over the end of the year (as year is not taken into account with the date range i.e. it happens every year). E.g. 345 -> 2
function isFocusedDayInDateRange(
  focusedNumericalDay: number,
  start: number,
  end: number
) {
  if (start > end) {
    return !(focusedNumericalDay < start && focusedNumericalDay > end);
  } else {
    return focusedNumericalDay >= start && focusedNumericalDay <= end;
  }
}
