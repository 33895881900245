import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import GoogleMapReact, { GoogleMap } from "google-map-react-concurrent";
import useSupercluster from "use-supercluster";
import { GoogleMapsProps } from "../../models/interfaces/GoogleMapsProps";
import { MarkerInfoWithIdentifier } from "../../models/interfaces/MarkerInfoWithIdentifier";
import { CircleInfo } from "../../models/interfaces/CircleInfo";
import { Circle } from "@react-google-maps/api";
import {
  approximateLocationRangeInMeters,
  circleOptions,
} from "./GoogleMapsSingle";

interface MarkerComponentProps {
  children: React.ReactNode;
  onClick: (e: React.MouseEvent) => void;
  lat: number;
  lng: number;
}

export const MarkerComponent: React.FC<MarkerComponentProps> = memo(
  ({ children, onClick }) => <div onClick={onClick}>{children}</div>
);

function GoogleMapsBounds({
  itemSelectedFocusedId,
  onMarkerClick,
  markers,
  circles,
  latitude,
  longitude,
    defaultZoom,
    mapOpen
}: GoogleMapsProps) {

  console.log("MARKERS")
  console.log(markers)
  const [render, rerender] = useState(false);


  const [map, setMap] = useState<GoogleMap | null>(null);
  const [markersState, setMarkersState] = useState<MarkerInfoWithIdentifier[]>(
      markers ? markers.map((marker, index) => ({
        markerInfo: marker,
        focused: false,
        id: marker.idOfAttachedItem ?? index.toString(),
      })) : []
  );
  const [bounds, setBounds] = useState<number[] | null>(null);
  const [zoom, setZoom] = useState(defaultZoom ? 6 : 10);
  const [readyToRender, setReadyToRender] = useState(false);
  const [defaultLocation, setDefaultLocation] = useState({
    lat: latitude,
    lng: longitude,
  });
  const mapRef = useRef<GoogleMap>();

  const updateFocusedId = useCallback(
    (id: string) => {
      if (!onMarkerClick) {
        return;
      }

      if (clickingCurrentlyFocusedMarker(id)) {
        return;
      }

      onMarkerClick(id);

      // Make a copy of the current marker state
      const markerStateCopy = [...markersState];

      // Reset all markers to not-focused
      for (const marker of markerStateCopy) {
        marker.focused = false;
      }

      // Find and focus the clicked marker
      for (const marker of markerStateCopy) {
        if (marker.id === id) {
          marker.focused = true;
          break;
        }
      }

      setMarkersState(markerStateCopy);
    },
    [onMarkerClick, markersState]
  );

  useEffect(() => {
    if (defaultZoom) {
      setZoom(6);
      map?.setZoom(6);
    }
  }, [defaultZoom, map, mapOpen])

  useEffect(() => {
    generateIdentifiableMarkers();
  }, [markers, mapOpen]);

  useEffect(() => {
    if (!map) return;
    setReadyToRender(true);
  }, [map, mapOpen]);

  const generateIdentifiableMarkers = () => {
    const MarkersWithIdentifiers: MarkerInfoWithIdentifier[] = [];

    if (markers) {
      for (let i: number = 0; i < markers.length; i++) {
        MarkersWithIdentifiers.push({
          markerInfo: markers[i],
          focused: false,
          id: markers[i].idOfAttachedItem ?? i.toString(),
        });
      }
    }

    setMarkersState(MarkersWithIdentifiers);
    if (!defaultZoom) getMedianLocation(MarkersWithIdentifiers);
  };

  function clickingCurrentlyFocusedMarker(id: string): boolean {
    const markerWithFocus = markersState.find((marker) => {
      return marker.id == id;
    });

    return markerWithFocus ? markerWithFocus.focused : false;
  }

  const points = markersState.map((marker) => ({
    type: "Feature",
    properties: { cluster: false, id: marker.id },
    geometry: {
      type: "Point",
      coordinates: [marker.markerInfo.longitude, marker.markerInfo.latitude],
    },
  }));

  const { clusters: superClusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 150, maxZoom: 20 },
  });

  // const getMeanLocation = (markers: MarkerInfoWithIdentifier[]) => {
  //   if (markers.length === 0) return;
  //
  //   let totalLat = 0;
  //   let totalLng = 0;
  //
  //   for (const marker of markers) {
  //     totalLat += marker.markerInfo.latitude;
  //     totalLng += marker.markerInfo.longitude;
  //   }
  //
  //   if (latitude && longitude) {
  //     setDefaultLocation({ lat: latitude, lng: longitude });
  //     map?.panTo({ lat: latitude, lng: longitude });
  //   } else {
  //     setDefaultLocation({
  //       lat: totalLat / markers.length,
  //       lng: totalLng / markers.length,
  //     });
  //     map?.panTo({
  //       lat: totalLat / markers.length,
  //       lng: totalLng / markers.length,
  //     });
  //   }
  //   map?.setZoom(13);
  // };

  const getMedianLocation = (markers: MarkerInfoWithIdentifier[]) => {
    if (markers.length === 0) return;

    // Sort markers by latitude
    const sortedMarkersByLat = markers.sort((a, b) => a.markerInfo.latitude - b.markerInfo.latitude);
    // Sort markers by longitude

    const midIndex = Math.floor(markers.length / 2);

    // Select the marker at the median index
    const medianMarker = sortedMarkersByLat[midIndex];

    // If latitude and longitude are provided, use them
    if (latitude && longitude) {
      setDefaultLocation({ lat: latitude, lng: longitude });
      map?.panTo({ lat: latitude, lng: longitude });
    } else {
      // Otherwise, use the median marker's location
      setDefaultLocation({
        lat: medianMarker.markerInfo.latitude,
        lng: medianMarker.markerInfo.longitude,
      });
      map?.panTo({
        lat: medianMarker.markerInfo.latitude,
        lng: medianMarker.markerInfo.longitude,
      });
    }
    map?.setZoom(13);
  };

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
      defaultCenter={defaultLocation}
      defaultZoom={zoom}
      onGoogleApiLoaded={({ map }: { map: GoogleMap }) => {
        setMap(map);
        mapRef.current = map;
      }}
      onChange={({ zoom, bounds }: { zoom: number; bounds: any }) => {
        setZoom(zoom);
        setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
      }}
    >
      {readyToRender &&
        superClusters.map((cluster, index) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } =
            cluster.properties;

          if (isCluster) {
            return (
              <MarkerComponent
                onClick={(e) => {
                  e.stopPropagation();
                  const expansionZoom = Math.min(
                    supercluster.getClusterExpansionZoom(cluster.id),
                    20
                  );
                  if (mapRef && mapRef.current) {
                    mapRef.current.setZoom(expansionZoom);
                    mapRef.current.panTo({ lat: latitude, lng: longitude });
                  }
                }}
                key={`cluster-${index}`}
                lat={latitude}
                lng={longitude}
              >
                <div className="marker">
                  <img src="/assets/img/map-cluster.webp" alt="Cluster" />
                  <span className="cluster-count"></span>
                </div>
              </MarkerComponent>
            );
          }

          const markerId = cluster.properties.id;

          return (
            <MarkerComponent
              onClick={(e) => {
                e.stopPropagation();
                if (mapRef && mapRef.current) {
                  mapRef.current.panTo({ lat: latitude, lng: longitude });
                }
                onMarkerClick && onMarkerClick(cluster.properties.id);
              }}
              key={`marker-${markerId}`}
              lat={latitude}
              lng={longitude}
            >
              <div className="">
                <img
                  width="auto"
                  height="55"
                  src={
                    itemSelectedFocusedId === markerId
                      ? "/assets/img/map-pin-click.webp"
                      : "/assets/img/map-pin-unclicked.webp"
                  }
                  alt="Marker"
                />
              </div>
            </MarkerComponent>
          );
        })}

      {circles &&
        circles.map((circle: CircleInfo) => {
          const position: google.maps.LatLngLiteral = {
            lat: circle.latitude,
            lng: circle.longitude,
          };
          return (
            <Circle
              options={circleOptions}
              center={position}
              radius={approximateLocationRangeInMeters}
            />
          );
        })}
    </GoogleMapReact>
  );
}

export default React.memo(GoogleMapsBounds);
