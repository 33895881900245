import React from "react";
import { Circle, GoogleMap, Marker } from "@react-google-maps/api";

export const approximateLocationRangeInMeters = 483;
const circleFillColor: string = "#EA554A33";
const circleOutlineColor: string = "#EA554A";

export const circleOptions: google.maps.CircleOptions = {
  fillColor: circleFillColor,
  fillOpacity: 0.8,
  strokeColor: circleOutlineColor,
  strokeWeight: 1,
};

function GoogleMapsSingle({
  lat,
  lng,
  zoom,
  wantMarker,
  circleSetup,
  wantAccurateMarker,
}: {
  lat: number;
  lng: number;
  zoom?: number;
  wantMarker?: boolean;
  circleSetup?: { radius: number | undefined };
  wantAccurateMarker?: boolean;
}) {
  const defaultZoom = 14;
  const center = { lat: lat, lng: lng };
  const position: google.maps.LatLngLiteral = { lat: lat, lng: lng };

  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY as string
  // })

  // const { isLoaded } = new Loader({
  //   apiKey: "",
  //   id: "__googleMapsScriptId",
  //   version: "weekly",
  //   libraries: ["places"]
  // });

  return (
    <GoogleMap
      mapContainerClassName={"google-map-container"}
      zoom={zoom ?? defaultZoom}
      center={center}
    >
      {wantMarker && (
        <Marker
          options={{
            clickable: false,
            icon: {
              url: wantAccurateMarker
                ? "/assets/img/map-pin-click.webp"
                : "/assets/img/map-pin-unclicked.webp",
              scaledSize: new google.maps.Size(45, 55), // Set the width and height to the desired size
            },
          }}
          position={position}
        />
      )}
      {circleSetup && (
        <Circle
          options={circleOptions}
          center={position}
          radius={circleSetup.radius ?? approximateLocationRangeInMeters}
        />
      )}
    </GoogleMap>
  );
}

export default React.memo(GoogleMapsSingle);
