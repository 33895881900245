import { Col, Container, Row } from "reactstrap";
import AccountPagesSidebar from "../AccountPagesSidebar";
import { NewIdentityAndPaymentSteps } from "./NewIdentityAndPaymentSteps";

export function IdentityAndPayment() {
  return (
    <Container className="mt-4 account-settings-container">
      <Row>
        <Col md={4} lg={4} xl={3} className="hide-on-mobile hide-on-tablet">
          <AccountPagesSidebar />
        </Col>
        <Col md={8} lg={8} xl={9} className="auto-margin">
          <NewIdentityAndPaymentSteps />
        </Col>
      </Row>
    </Container>
  );
}
