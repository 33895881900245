import { FPTrack, GAEvent } from "../app";

// New general function for Meta Pixel with Payload passed in:
export const sendFBdata = async (accountID, eventName, customData = {}) => {
  try {
    const accessToken = process.env.REACT_APP_ACCESSTOKEN;
    const id = process.env.REACT_APP_PIXEL_ID;

    const url = `https://graph.facebook.com/v17.0/${id}/events?access_token=${accessToken}`;


    const payload = {
      "data": [
        {
          "event_name": eventName,
          "event_time": Math.floor(Date.now() / 1000),
          "action_source": "website",
          "user_data": {
            "client_ip_address": null,
            "client_user_agent": null,
            "country": [
              "83116acf18e4dc4414762f584ff43d9979ff2c2b0e9e48fbc97b21e23d7004ec"
            ],
            "external_id": [
              accountID
            ]
          },
          "custom_data": customData
        }
      ]
    };

    console.log(payload);

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json"
      }
    });

    if (!response.ok) {
      console.log(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error handling analytics: ", error);
  }
};

// Search Item List Event
export async function sendSearchEvent(searchTerm, searchedLocation, accountID, userLocation, device) {
  const customData = {
    "accountID": accountID,
    "searchTerm": searchTerm,
    "searchedLocation": searchedLocation,
    "userLocation": userLocation,
    "device": device
  };
  try {
    const fbResponse = await sendFBdata(accountID, "viewItemList", customData);
    console.log("Facebook response: ", fbResponse);
  } catch (error) {
    console.error("Error sending data to Facebook: ", error);
  }
}

// When viewing a single specific item 
export async function sendViewItem(searchTerm, searchedLocation, accountID, itemID, userLocation) {
  const customData = {
    "accountID": accountID,
    "itemID": itemID,
    "searchTerm": searchTerm,
    "searchedLocation": searchedLocation,
    "userLocation": userLocation
  };

  try {
    const fbResponse = await sendFBdata(accountID, "ViewContent", customData);
    console.log("Facebook response: ", fbResponse);
  } catch (error) {
    console.error("Error sending data to Facebook: ", error);
  }
}

//Add to cart Event
export async function sendAddToCartEvent(accountID, itemID, itemValue) {
  const customData = {
    "accountID": accountID,
    "itemID": itemID,
    "itemValue": itemValue
  };
  try {
    const fbResponse = await sendFBdata(accountID, "AddToCart");
    // console.log('Facebook response: ', fbResponse);
  } catch (error) {
    // console.error('Error sending data to Facebook: ', error);
  }
}

//BeginCheckout Event 
export async function sendBeginCheckoutEvent(accountID, itemID, itemInCart, cartValue, totalCart) {
  const customData = {
    "accountID": accountID,
    "itemID": itemID,
    "itemInCart": itemInCart,
    "cartValue": cartValue,
    "totalCart": totalCart
  };
  try {
    const fbResponse = await sendFBdata(accountID, "BeginCheckout", customData);
    // console.log('Facebook response: ', fbResponse);
  } catch (error) {
    // console.error('Error sending data to Facebook: ', error);
  }
}

//New AddPayment Event
export async function sendAddPaymentInfoEvent(accountID, date, time, device) {
  const customData = {
    "accountID": accountID,
    "date": date,
    "time": time,
    "device": device
  };
  try {
    const fbResponse = await sendFBdata(accountID, "AddPaymentInfo", customData);
    // console.log('Facebook response: ', fbResponse);
  } catch (error) {
    // console.error('Error sending data to Facebook: ', error);
  }
}


//New Purchase Event Event
export async function sendPurchaseEvent(accountID, itemID, total) {
  const customData = {
    "accountID": accountID,
    "itemID": itemID,
    "currency": "GBP",
    "value": total
  };
  try {
    const fbResponse = await sendFBdata(accountID, "Purchase", customData);
    // console.log('Facebook response: ', fbResponse);
  } catch (error) {
    // console.error('Error sending data to Facebook: ', error);
  }
}

//New Refund Event
export async function sendRefundEvent(currency, value) {
  // const customData = {
  //     "currency": currency,
  //     "value": value
  // };

  // try {
  //     const fbResponse = await sendFBdata("Refund", customData);
  //     console.log('Facebook response: ', fbResponse);
  // } catch (error) {
  //     console.error('Error sending data to Facebook: ', error);
  // }
}

//New Login Event 
export async function sendUserLoginEvent(accountID, date, time, userLocation, device) {
  const customData = {
    "accountID": accountID,
    "date": date,
    "time": time,
    "userLocation": userLocation,
    "device": device
  };
  try {
    const fbResponse = await sendFBdata(accountID, "Login", customData);
    // console.log('Facebook response: ', fbResponse);
    userLoginAnalytics({});  // Old pixel
  } catch (error) {
    // console.error('Error sending data to Facebook: ', error);
  }
}

//New Signup Event
export async function sendCompleteRegistrationEvent(accountID, currentDate, currentTime, location, device) {
  const customData = {
    accountID: accountID,
    date: currentDate.toDateString(),
    time: currentTime.toTimeString(),
    location: location,
    device: device
  };
  try {
    const fbResponse = await sendFBdata(accountID, "CompleteRegistration", customData);
    // console.log('Facebook response: ', fbResponse);
  } catch (error) {
    // console.error('Error sending data to Facebook: ', error);
  }
}

// New Create Listing Event

export async function sendCreateListingEvent(itemName, itemID, accountID, location, device) {
  const eventName = "CreateListing";
  const customData = {
    "itemID": itemID,
    "itemName": itemName,
    "accountID": accountID,
    "location": location,
    "device": device
  };
  try {
    const fbResponse = await sendFBdata(accountID, eventName, customData);
    // console.log('Facebook response: ', fbResponse);
  } catch (error) {
    // console.error('Error sending data to Facebook: ', error);
  }
}

//OLD PIXELS
// For when viewing a search results page 
export const viewItemListAnalytics = (data) => {
  FPTrack("Search", data);
  GAEvent("user", "view_item_list", data);
};

// When viewing a single specific item 
export const viewItemAnalytics = (data) => {
  FPTrack("View content", data);
  GAEvent("user", "view_item", data);
};

// When "rent me" has been clicked with a successful rental date etc. 
export const addToCartAnalytics = (data) => {
  FPTrack("Add to cart", data);
  GAEvent("user", "add_to_cart", data);
};

// Same as begin checkout based on UX
export const viewCartAnalytics = (data) => GAEvent("user", "view_cart", data);

// Once the customer gets to "Confirm your booking" 
export const beginCheckoutAnalytics = (data) => {
  FPTrack("Initiate checkout", data);
  GAEvent("user", "begin_checkout", data);
};

// Once the customer submits info on the Payment Page
export const addPaymentInfoAnalytics = (data) => {
  FPTrack("Add payment info", data);
  GAEvent("user", "add_payment_info", data);

};

// When a booking has been paid and confirmed  
export const itemPurchasedAnalytics = (data) => {
  // console.log("Called itemPurchasedAnalytics")
  // @ts-ignore
  window.gtag("event", "conversion", { "send_to": process.env.REACT_APP_GOOGLE_ADS_KEY });
  FPTrack("Purchase", data);
  GAEvent("user", "purchase", data);
};

// when a booking has a refund for cancellation, problem with rental or other "problem" transactions.
export const itemRefundedAnalytics = (data) => GAEvent("user", "refund", data);

// When a customer has logged in
export const userLoginAnalytics = (data) => {
  FPTrack("login", data);
  GAEvent("user", "login", data);
};

// When a customer registers a new account with RentMy 
export const userSignupAnalytics = (data) => {
  FPTrack("Complete registration", data);
  GAEvent("user", "sign_up", data);
};

export const createListingAnalytics = (data) => {
  FPTrack("Create listing", data);
  GAEvent("user", "create_listing", data);
};