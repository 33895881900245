import { useState, useEffect, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { SxProps, Theme, Box, Typography, InputLabel, TextField } from '@mui/material';
import { IConfig, StripeOnboardingRedirects, UserClient } from '../../api/rentMyApi';
import { useEnv } from '../../context/env.context';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useUserContext } from '../../context/UserContext';
import { loadStripe, Stripe } from '@stripe/stripe-js';

type BankDetailsStepProps = {
  backButton: ReactNode;
  headingStyle: SxProps<Theme>;
  inputStyle: SxProps<Theme>;
  stepSummaryStyle: SxProps<Theme>;
  setOnboardingCompleted: React.Dispatch<React.SetStateAction<boolean>>;
};

interface FormValues {
  bankAccountNumber: string;
  sortCode: string;
}

const initialFormState: FormValues = {
  bankAccountNumber: '',
  sortCode: '',
};

interface FormErrors {
  sortCode: string;
  bankAccountNumber: string;
}

const initialFormErrors: FormErrors = {
  sortCode: '',
  bankAccountNumber: '',
};

export function BankDetailsStep({
  backButton,
  headingStyle,
  inputStyle,
  stepSummaryStyle,
  setOnboardingCompleted,
}: BankDetailsStepProps) {
  const { t } = useTranslation();
  const { apiServerUrl } = useEnv();
  const { getAccessTokenSilently } = useAuth0();
  const { user, updateUser } = useUserContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stripe, setStripe] = useState<Stripe>();
  const [formValues, setFormValues] = useState<FormValues>(initialFormState);
  const [formErrors, setFormErrors] = useState<FormErrors>(initialFormErrors);

  async function loadStripeClient() {
    try {
      const stripeApiKey = process.env.REACT_APP_STRIPE_PUBLISHING_KEY || '';
      const _stripe = await loadStripe(stripeApiKey);
      if (_stripe) setStripe(_stripe);
    } catch (error) {
      // console.log("Failed to load stripe")
      // console.error(error);
    }
  }

  useEffect(() => {
    loadStripeClient();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormValues((previousValues) => ({ ...previousValues, [e.target.name]: e.target.value }));
  };

  const validateInput = () => {
    const errors = {
      ...initialFormErrors,
    };
    const { bankAccountNumber, sortCode } = formValues;
    if (!bankAccountNumber) {
      errors.bankAccountNumber = 'Required field';
    }
    if (!sortCode) {
      errors.sortCode = 'Required field';
    }
    return errors;
  };
  function triggerBankError(errorMessage: string) {
    toast.error(errorMessage);
    setFormErrors({ bankAccountNumber: 'Invalid account number', sortCode: 'Invalid sort code' });
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setIsSubmitting(true);
    const errors = validateInput();
    setFormErrors(errors);
    if (Object.values(errors).find((fieldError) => fieldError)) {
      setIsSubmitting(false);
    } else {
      const token = await getAccessTokenSilently();
      const userClient = new UserClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT);
      if (stripe !== undefined) {
        stripe
          .createToken('bank_account', {
            country: 'GB',
            currency: 'gbp',
            account_number: formValues.bankAccountNumber,
            routing_number: formValues.sortCode,
            account_holder_name: user.name,
            account_holder_type: 'individual',
          })
          .then(function (result) {
            if (result.error !== undefined) {
              triggerBankError('We were unable to add your bank account, please contact support');
              setIsSubmitting(false);
            } else if (result.token !== undefined) {
              userClient!
                .addStripeBank(undefined, result.token.id)
                .then((response) => {
                  if (response) {
                    setIsSubmitting(false);
                    updateUser(response);
                    setOnboardingCompleted(true);
                  }
                })
                .catch((e) => {
                  triggerBankError('We were unable to add your bank account, please contact support');
                  setIsSubmitting(false);
                });
            }
          })
          .catch((e) => {
            triggerBankError('We were unable to add your bank account, please contact support');
            setIsSubmitting(false);
          })
          .catch((e) => {
            triggerBankError('We were unable to add your bank account, please contact support');
            setIsSubmitting(false);
          });
      } else {
        toast.error('Error connecting to Stripe, please try again later');
        setIsSubmitting(false);
      }
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography component="h1" sx={headingStyle}>
        {t('onboarding_bank_details')}
      </Typography>
      {backButton}
      <Typography sx={{ ...stepSummaryStyle, marginBottom: '15px' }}>{t('onboarding_edit_bank_details')}</Typography>
      <form onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <InputLabel id="sortCode">{t('sort_code')}</InputLabel>
          <TextField
            id="sortCode"
            name="sortCode"
            fullWidth
            sx={inputStyle}
            inputProps={{ maxLength: 6 }}
            value={formValues.sortCode ?? ''}
            onChange={handleChange}
            error={!!formErrors.sortCode}
            helperText={formErrors.sortCode}
          />
          <InputLabel id="bankAccountNumber">{t('account_number')}</InputLabel>
          <TextField
            id="bankAccountNumber"
            name="bankAccountNumber"
            fullWidth
            sx={inputStyle}
            inputProps={{ maxLength: 8 }}
            value={formValues.bankAccountNumber ?? ''}
            onChange={handleChange}
            error={!!formErrors.bankAccountNumber}
            helperText={formErrors.bankAccountNumber}
          />
          <button type="submit" disabled={isSubmitting} className="btn btn-primary onboarding-button">
            {isSubmitting && (
              <span className="btn-inner--icon">
                <FontAwesomeIcon icon={faSpinner} spin/>
              </span>
            )}
            {user.canBeSharer ? t('onboarding_edit_bank_account') : t('onboarding_connect_bank_account')}
          </button>
        </Box>
      </form>
    </Box>
  );
}
