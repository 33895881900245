import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { LoadingFullScreen } from "../components/common/LoadingFullScreen";

export const AuthRedirect = (screen_hint: string) => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect({
      redirectUri: window.location.origin + "/",
      screen_hint: screen_hint,
    });
  });

  return <LoadingFullScreen />;
};
