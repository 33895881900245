import React from "react";
import { slide as Menu } from "react-burger-menu";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  accountPageLinks,
  UmbracoSiteLinks,
  unauthorisedAccountPageLinks,
} from "./HeaderLinks";
import { useAuth0 } from "@auth0/auth0-react";
import { useUserContext } from "../../../context/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { notificationCounter } from "./NotificationCounter";
import { TopRightDropDownMenu } from "../../common/TopRightDropDownMenu";
import NavSearchBar from "./NavSearchBar";

interface NavbarMobileProps {
  hamburgerMenuOpen: boolean;
  setHamburgerMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const NavbarMobile: React.FC<NavbarMobileProps> = ({
  hamburgerMenuOpen,
  setHamburgerMenuOpen,
}) => {
  const topRightDropDownMenu = TopRightDropDownMenu({
    isMobile: true,
    setHamburgerOpen: setHamburgerMenuOpen,
    isHamburgerOpen: hamburgerMenuOpen,
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuth0();
  const {
    unreadMessageCount,
    user,
    bookingNotifications,
    rentalNotifications,
  } = useUserContext();

  const routeChangeItemListing = () => {
    navigate("/item-listing");
    setHamburgerMenuOpen(false);
  };

  const hamburgerMenu = () => {
    let hamburgerClass = "hamburger-container";
    if (hamburgerMenuOpen) hamburgerClass += " change";

    return (
      <div
        onBlur={() => setHamburgerMenuOpen(false)}
        className={hamburgerClass}
        onClick={() => setHamburgerMenuOpen(!hamburgerMenuOpen)}
      >
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </div>
    );
  };

  const { pathname } = useLocation();
  return (
    <>
      <div className="flexy container-fluid">
        {pathname === "/" && (
          <img
            className="navbar-image"
            src="/assets/img/RentMy-logo-no-tm.webp"
            onClick={() => navigate("/")}
            alt="RentMy Logo"
          />
        )}
        <div className="flexy center-item-align additional-padding-on-tablet w-100">
          <NavSearchBar />
        </div>
        <div className="flexy flex-right center-item-align additional-padding-on-tablet">
          <div className="desktop-header-links-container">
            {UmbracoSiteLinks.map((headerLinkObject, index) => {
              if (headerLinkObject.type === "link") {
                return (
                  <li key={index}>
                    <a
                      className="desktop-header-links text-align-center"
                      href={headerLinkObject.url}
                    >
                      {headerLinkObject.title}
                    </a>
                  </li>
                );
              } else if (headerLinkObject.type === "external-link") {
                return (
                  <li key={index}>
                    <a
                      className="desktop-header-links text-align-center"
                      href={headerLinkObject.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {headerLinkObject.title}
                    </a>
                  </li>
                );
              }
            })}
          </div>

          <div className={`navbar-item navmenu-dropdown block`}>
            {topRightDropDownMenu}
          </div>

          {hamburgerMenu()}
        </div>
      </div>
      <Menu
        isOpen={hamburgerMenuOpen}
        onClose={() => setHamburgerMenuOpen(false)}
        right
      >
        <Button
          onClick={() => {
            setHamburgerMenuOpen(false);
            routeChangeItemListing();
          }}
          className="full-width font-size-1-rem"
          color="primary"
          type="button"
        >
          {t("nav_create_listing")}
        </Button>
        {(isAuthenticated
          ? accountPageLinks
          : unauthorisedAccountPageLinks
        ).map((headerLinkObject, index) => (
          <li
            onClick={() => {
              setHamburgerMenuOpen(false);
              navigate(headerLinkObject.url);
            }}
            key={index}
            className={`${headerLinkObject.indent ? "indented-link" : ""}`}
            style={headerLinkObject.indent ? { fontSize: "17px" } : {}}
          >
            <FontAwesomeIcon className="bm-icon" icon={headerLinkObject.icon} />

            <Link
              onClick={() => setHamburgerMenuOpen(false)}
              className={`desktop-header-links bm-link`}
              to={headerLinkObject.url}
            >
              {headerLinkObject.title}
            </Link>

            {index === 1 && notificationCounter(unreadMessageCount, true, true)}
            {index === 2 &&
              rentalNotifications &&
              notificationCounter(1, true, true, headerLinkObject.indent)}
            {index === 4 &&
              bookingNotifications &&
              notificationCounter(1, true, true, headerLinkObject.indent)}
          </li>
        ))}
        <hr className="navbar-mobile-hr" />
        {UmbracoSiteLinks.slice(1).map((headerLinkObject, index) => (
          <li key={index}>
            <a
              className="desktop-header-links bm-link full-width display-block"
              href={headerLinkObject.url}
            >
              {headerLinkObject.title}
            </a>
          </li>
        ))}
        {isAuthenticated && <hr className="navbar-mobile-hr" />}
        {isAuthenticated && (
          <a
            href="/"
            className="desktop-header-links bm-link"
            onClick={() => logout()}
          >
            Logout
          </a>
        )}
      </Menu>
    </>
  );
};

export default NavbarMobile;
