const getClass = (subNotification: boolean, isMobile: boolean) => {
  if (subNotification) {
    return isMobile
      ? "notifications-counter mobile-sub-notifications-counter"
      : "notifications-counter sub-notifications-counter";
  } else {
    return "notifications-counter";
  }
};

export const notificationCounter = (
  count: number,
  subNotification: boolean,
  isMobile: boolean,
  indent: boolean = false
) =>
  count > 0 ? (
    <div
      className={getClass(subNotification, isMobile)}
      style={indent ? { marginLeft: "0px" } : {}}
    >
      <div
        className={
          isMobile && !subNotification ? "circle mobile-circle" : "circle"
        }
      >
        {count}
      </div>
    </div>
  ) : (
    <></>
  );
