import { Card, CardBody, CardHeader } from "reactstrap";
import { AccordionState } from "../../models/interfaces/AccordionState";

interface ListingCancellationSelectorProps {
  accordionState: AccordionState[];
}

export default function ListingCancellationSelector({
  accordionState,
}: ListingCancellationSelectorProps) {
  return (
    <div className="accordion">
      {accordionState.map((accordion, index) => {
        const colourScheme = "cancellation-header-" + (index + 1).toString();
        return (
          <>
            <Card key={index} className="card-plain">
              <CardHeader className={colourScheme}>
                <h3 className="mb-0 colour-white">{accordion.title}</h3>
              </CardHeader>
              <CardBody>{accordion.body}</CardBody>
            </Card>
          </>
        );
      })}
    </div>
  );
}
