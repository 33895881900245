import * as React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Location } from "../../api/rentMyApi";

import LocationData from "../common/LocationData";
import {
  DefaultErrors,
  getDefaultErrors,
} from "./ItemListingConstantsAndDefaults";

interface ItemAddressProps {
  errors: {
    location: {
      addressLine1: boolean;
      city: boolean;
      country: boolean;
      postcode: boolean;
    };
  };
  setErrors: (errors: any) => void;
  locationData: Location;
  setLocationData: React.Dispatch<React.SetStateAction<Location>>;
}

export default function ItemAddress({
  errors,
  setErrors,
  locationData,
  setLocationData,
}: ItemAddressProps) {
  return (
    <Card className="item-listing-card">
      <CardBody>
        <h2>Item Address</h2>
        <Row>
          <Col id="location-section" sm={12}>
            <LocationData
              locationData={locationData}
              setLocationData={setLocationData}
              onAutoComplete={(newLocationData) => {
                setLocationData(newLocationData);
                const emptyLocationError = getDefaultErrors().location;

                setErrors((errors: DefaultErrors) => ({
                  ...errors,
                  location: {
                    ...errors.location,
                    ...emptyLocationError,
                  },
                }));
              }}
              isAddressLine1Invalid={errors.location.addressLine1}
              isCityInvalid={errors.location.city}
              isCountryInvalid={errors.location.country}
              isPostcodeInvalid={errors.location.postcode}
              updateErrors={(field) => {
                setErrors({
                  ...errors,
                  location: {
                    ...errors.location,
                    [field]: false,
                  },
                });
              }}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
