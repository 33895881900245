import { useEffect, useState } from "react";
import axios from "axios";
import { Container } from "reactstrap";

export function Footer() {
  const [data, setData] = useState({
    instagramIconPath: "",
    instagramUrl: "",
    facebookIconPath: "",
    facebookUrl: "",
    twitterIconPath: "",
    twitterUrl: "",
    brandImagePath: "",
    brandUrl: "",
    policyTitle1: "",
    policyUrl1: "",
    copyrightDetail: "",
  });

  const [section2Links, setSection2Links] = useState<
    Array<{
      text: string;
      href: string;
    }>
  >([]);

  const [section3Links, setSection3Links] = useState<
    Array<{
      text: string;
      href: string;
    }>
  >([]);

  useEffect(() => {
    const cmsUrl = process.env.REACT_APP_CMS_URL!;
    const footerPath = process.env.REACT_APP_CMS_FOOTER_PATH!;
    const absPath = cmsUrl + footerPath;

    axios
      .get(absPath)
      .then((res) => {
        const { fields } = res.data;
        document.documentElement.style.setProperty(
          "--footerBackgroundColor",
          "#" + fields.backgroundColor
        );

        setData({
          instagramIconPath:
            cmsUrl + fields.instagramIcon.fields.umbracoFile.src,
          instagramUrl: fields.instagramUrl,
          facebookIconPath: cmsUrl + fields.facebookIcon.fields.umbracoFile.src,
          facebookUrl: fields.facebookUrl,
          twitterIconPath: cmsUrl + fields.twitterIcon.fields.umbracoFile.src,
          twitterUrl: fields.twitterUrl,
          brandImagePath: cmsUrl + fields.brandImage.fields.umbracoFile.src,
          brandUrl: fields.brandUrl,
          policyTitle1: fields.policyTitle1,
          policyUrl1: fields.policyUrl1,
          copyrightDetail: fields.copyrightDetail,
        });

        // Extract links for section 2
        const section2LinkObjects = Object.keys(fields)
          .filter((key) => key.startsWith("section2Link"))
          .map((key) => ({ text: fields[key], href: fields[key + "url"] }))
          .filter((obj) => obj.href);
        setSection2Links(section2LinkObjects);

        // Extract links for section 3
        const section3LinkObjects = Object.keys(fields)
          .filter((key) => key.startsWith("section3Link"))
          .map((key) => ({ text: fields[key], href: fields[key + "url"] }))
          .filter((obj) => obj.href);
        setSection3Links(section3LinkObjects);
      })
      .catch((err) => {});
  }, []);

  return (
    <section className="footer">
      <Container>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-3">
            <a href={data.brandUrl} title="" target="_blank" rel="noreferrer">
              <img
                src={data.brandImagePath}
                alt=""
                width="130px"
                height="auto"
              />
            </a>
          </div>
          {[section2Links, section3Links].map((group, groupIndex) => (
            <div key={groupIndex} className="col-12 col-sm-6 col-md-3">
              <ul>
                {group.map((link, linkIndex) => (
                  <li key={linkIndex}>
                    <a href={link.href}>
                      <p className="footer-link-text">{link.text}</p>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <div className="col-12 col-sm-6 col-md-3 social-icons">
            <div>
              <a
                href={data.twitterUrl}
                title=""
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={data.twitterIconPath}
                  alt=""
                  width="auto"
                  height="24px"
                />
              </a>
              <a
                href={data.instagramUrl}
                title=""
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={data.instagramIconPath}
                  alt=""
                  width="auto"
                  height="24px"
                />
              </a>
              <a
                href={data.facebookUrl}
                title=""
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={data.facebookIconPath}
                  alt=""
                  width="auto"
                  height="24px"
                />
              </a>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-12 col-sm-4 small-print small">
            <span>{data.copyrightDetail}</span>
          </div>
          <div className="col-12 col-sm-4 small-print small">
            <a href={data.policyUrl1}>{data.policyTitle1}</a>
          </div>
        </div>
      </Container>
    </section>
  );
}
