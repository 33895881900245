import * as React from "react";
import { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { faSpinner, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";

import AccountPagesSidebar from "../../components/account/AccountPagesSidebar";
import { useEnv } from "../../context/env.context";
import { useUserContext } from "../../context/UserContext";

import {
  FileClient,
  IConfig,
  ImageDto,
  IRentMyUserDetailedDto,
  RentMyUserDetailedDto,
  UserClient,
  WalletDto,
  Location,
} from "../../api/rentMyApi";
import {
  descriptionMaxLength,
  emailMaxLength,
  locationMaxLength,
  nameMaxLength,
  websiteMaxLength,
} from "../../components/common/FormConstants";
import { useNavigate } from "react-router-dom";
import { Profile } from "../../components/account/Dashboard/Profile";
import { UserAddressFormErrors } from "../../models/interfaces/UserAddress";
import { UserAddressInput } from "../../components/account/Profile/UserAddressInput";

const initialUserAddressFormErrors: UserAddressFormErrors = {
  addressLine1: "",
  addressLine2: "",
  city: "",
  country: "",
  postcode: "",
};

export function AccountProfilePage() {
  const { user, updateUser, refetchCurrentUser } = useUserContext();
  const searchParams = new URLSearchParams(window.location.search);

  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);

  const [userData, setUserData] = useState<IRentMyUserDetailedDto>(user);
  const [referralsModalOpen, setReferralsModalOpen] = useState<boolean>(false);
  const [updateUserData, setUpdateUserData] = useState<any>({});
  // const [showReviews, setShowReviews] = useState<boolean>(false);
  const [noProfileWithThatId, setNoProfileWithThatId] =
    useState<boolean>(false);
  // const [wallet, setWallet] = useState<WalletDto | undefined>(undefined);
  const [isUserDataSubmitting, setIsUserDataSubmtting] = useState(false);
  const [locationData, setLocationData] = useState<Location>(new Location());
  const [userAddressErrors, setUserAddressErrors] =
    useState<UserAddressFormErrors>(initialUserAddressFormErrors);

  // const [userClient] = useState<UserClient>(new UserClient(new IConfig('notoken'), process.env.REACT_APP_API_ENDPOINT));

  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const fileUploadProfile =
    useRef() as React.MutableRefObject<HTMLInputElement>;
  const fileUploadBanner = useRef() as React.MutableRefObject<HTMLInputElement>;
  const { apiServerUrl } = useEnv();

  enum UserImageType {
    PROFILE,
    BANNER,
  }

  const approvedFileTypes = ["image/jpg", "image/jpeg", "image/png", "image/webp"];

  function isApprovedFileType(userFileType: string): boolean {
    return approvedFileTypes.some(
      (approvedFileType) => userFileType === approvedFileType.replace(/\s/g, "")
    );
  }

  const navigate = useNavigate();

  const CloseIcon = () => {
    return (
      <div
        onClick={() => setReferralsModalOpen(false)}
        className="cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 36 36"
        >
          <circle
            id="Ellipse_10"
            data-name="Ellipse 10"
            cx="18"
            cy="18"
            r="18"
            fill="#e1e1e1"
          />
          <g
            id="Group_202"
            data-name="Group 202"
            transform="translate(-779.5 -325)"
          >
            <line
              id="Line_31"
              data-name="Line 31"
              y2="29"
              transform="translate(787.247 332.747) rotate(-45)"
              fill="none"
              stroke="#000"
              strokeWidth="3"
            />
            <line
              id="Line_32"
              data-name="Line 32"
              x1="28.5"
              transform="translate(787.424 353.076) rotate(-45)"
              fill="none"
              stroke="#000"
              strokeWidth="3"
            />
          </g>
        </svg>
      </div>
    );
  };

  useEffect(() => {
    const populateUserData = async () => {
      const idConst = "id";

      if (searchParams.has(idConst)) {
        try {
          // let response: RentMyUserPublicDetailedDto = await userClient.detailed52(searchParams.get(idConst)!);
          // let walletResponse: WalletDto = await userClient.wallet();
          // setUserData(response as unknown as IRentMyUserDetailedDto);
          // setWallet(walletResponse)
        } catch (e: any) {
          if (e.status === 404) {
            setNoProfileWithThatId(true);
            setUserData(user);
          }
          return;
        }
      } else {
        setUserData(user);
      }
    };

    populateUserData();
  }, [user.id]);

  async function handleUserPhotoUpload(e: any, imageType: UserImageType) {
    setIsImageUploading(true);

    const token = await getAccessTokenSilently();

    const imageData = e.target.files[0];

    const updated_image: File = new File([imageData], imageData.name);

    if (!isApprovedFileType(imageData.type)) {
      toast.error(t("image_upload_file_type_error"));
      setIsImageUploading(false);
      return;
    }

    const apiFileClient = await new FileClient(
      new IConfig(token),
      process.env.REACT_APP_API_ENDPOINT
    );

    console.log("Updated image");
    console.log(updated_image);
    console.log("type");
    console.log(updated_image.type);

    apiFileClient
      .upload(updated_image.type, {
        fileName: updated_image.name,
        data: updated_image,
      })
      .then(async (res) => {
        const userContextValueClone = { ...user } as RentMyUserDetailedDto;

        if (imageType === UserImageType.PROFILE) {
          try {
            await handleProfilePhotoUpdateAsync(res, token);
          } catch (e) {
            toast.error(t("my_profile_assign_error"));
          }

          setUserData({ ...userData, profileImage: res as ImageDto });
          userContextValueClone.profileImage = res as ImageDto;
          toast.success(t("my_profile_img_upload_success"));
        } else if (imageType === UserImageType.BANNER) {
          try {
            await handleBannerPhotoUpdateAsync(res, token);
          } catch (e) {
            toast.error(t("my_profile_assign_error"));
            // console.log(e)
          }

          setUserData({ ...userData, bannerImage: res as ImageDto });
          userContextValueClone.bannerImage = res as ImageDto;
          toast.success(t("my_profile_banner_upload_success"));
        }

        updateUser(userContextValueClone);
      })
      .catch((e) => {
        toast.error(t("my_profile_upload_error"));
      })
      .finally(() => {
        setIsImageUploading(false);
      });
  }

  // DEVNOTE - For "handleProfilePhotoUpdateAsync" and "handleBannerPhotoUpdateAsync". The backend does not expect you to pass down a userId, this is only if an admin is changing your picture. UserId is gotten from the authorisation instead to tell who is the logged in person.
  async function handleProfilePhotoUpdateAsync(
    imageUpdateData: ImageDto,
    token: string
  ) {
    await new UserClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT).changeProfileImage(
      imageUpdateData.id
    );
  }

  async function handleBannerPhotoUpdateAsync(
    imageUpdateData: ImageDto,
    token: string
  ) {
    await new UserClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT).changeBannerImage(
      undefined,
      imageUpdateData.id
    );
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });

    setUpdateUserData({
      ...updateUserData,
      [e.target.name]: e.target.value,
    });
  }

  const validateLocationInput = () => {
    const errors = {
      ...initialUserAddressFormErrors,
    };
    const { addressLine1, city, country, postcode } = locationData;
    if (!addressLine1) {
      errors.addressLine1 = "Required field";
    }
    if (!city) {
      errors.city = "Required field";
    }
    if (!country) {
      errors.country = "Required field";
    }
    if (!postcode) {
      errors.postcode = "Required field";
    }
    return errors;
  };

  async function handleAccountDataUpdate() {
    setIsUserDataSubmtting(true);
    const locationErrors = validateLocationInput();
    setUserAddressErrors(locationErrors);
    if (Object.values(locationErrors).find((fieldError) => fieldError)) {
      setIsUserDataSubmtting(false);
    } else {
      const token = await getAccessTokenSilently();
      try {
        await new UserClient(
          new IConfig(token),
          process.env.REACT_APP_API_ENDPOINT
        ).changeLocationPOST2(undefined, locationData);
        await new UserClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT).update25(
          undefined,
          updateUserData
        );
        await refetchCurrentUser();
        setIsUserDataSubmtting(false);
        toast.success(t("account_settings_user_data_updated"));
      } catch (error) {
        setIsUserDataSubmtting(false);
        toast.error(t("account_settings_err_something_goes_wrong"));
      }
    }
  }

  const getUserCloneWithNewValues = function (): RentMyUserDetailedDto {
    const userClone = { ...user } as RentMyUserDetailedDto;
    const userDataClone = userData as RentMyUserDetailedDto;

    Object.keys(userClone).forEach(function (key) {
      userClone[key] = userDataClone[key];
    });
    return userClone;
  };

  const BannerSection = () => (
    <Col lg={6} className="profile-banner">
      <Card>
        <CardBody>
          <div className="flexy mb-3 header-section">
            <h2>{t("my_profile_banner")}</h2>
          </div>

          <div>
            <img
              className="account_profile_banner_picture"
              alt={"banner picture of " + userData.name}
              src={`${
                userData?.bannerImage
                  ? apiServerUrl +
                    "/api/v1/" +
                    userData.bannerImage.compressedPath
                  : "assets/img/user-banner-img-placeholder.webp"
              }`}
            />
            <input
              type="file"
              ref={fileUploadBanner}
              defaultValue=""
              name="bannerPic"
              onChange={(e) => handleUserPhotoUpload(e, UserImageType.BANNER)}
              hidden
              accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
            />
          </div>
          <p className="banner-instructions">
            {t("my_profile_banner_instructions")}
          </p>
          <Row className="button-row">
            <Button
              className="btn-icon btn-3 mt-3 upload-image-button no-flex"
              color="primary"
              type="button"
              disabled={isImageUploading}
              onClick={() => {
                fileUploadBanner?.current?.click();
              }}
            >
              <span className="btn-inner--icon">
                <FontAwesomeIcon icon={faUpload} />
              </span>
              <span className="btn-inner--text upload-image-button">
                {t("my_profile_change_account_photo")}
              </span>
            </Button>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );

  const AboutSection = () => (
    <Row className="about-section">
      <Col xs={6} className="mb-2 full-width-on-mobile">
        <label htmlFor="email">{t("manage_profile_about_email_address")}</label>
        <Input
          id="email"
          maxLength={emailMaxLength}
          name="email"
          placeholder={t("my_profile_email_placeholder")}
          type="email"
          value={userData.emailAddress ?? ""}
          disabled
        />
      </Col>

      <Col xs={6} className="mb-2">
        {/*<label htmlFor="name">*/}
        {/*  Username*/}
        {/*</label>*/}
        {/*<Input spellCheck={false} id="name" maxLength={emailMaxLength} name='name' placeholder={"Username"} value={userData.name ?? ''} onChange={(e) => handleInputChange(e)} />*/}
      </Col>

      <Col xs={6} className="mb-2 full-width-on-mobile">
        <label htmlFor="firstName">
          {t("manage_profile_about_first_name")}
        </label>
        <Input
          id="firstName"
          maxLength={nameMaxLength}
          name="firstName"
          placeholder={t("my_profile_first_name_placeholder")}
          type="text"
          value={userData.firstName ?? ""}
          onChange={(e) => handleInputChange(e)}
        />
      </Col>

      <Col xs={6} className="mb-2 full-width-on-mobile">
        <label htmlFor="firstName">{t("manage_profile_about_last_name")}</label>
        <Input
          id="lastName"
          maxLength={nameMaxLength}
          name="lastName"
          placeholder={t("my_profile_last_name_placeholder")}
          type="text"
          value={userData.lastName ?? ""}
          onChange={(e) => handleInputChange(e)}
        />
      </Col>

      <Col xs={12} className="mb-2 full-width-on-mobile">
        <label htmlFor="aboutMe">{t("manage_profile_about_bio")}</label>
        <Input
          id="aboutMe"
          maxLength={descriptionMaxLength}
          name="aboutMe"
          rows="3"
          placeholder={t("my_profile_about_me_placeholder")}
          type="textarea"
          value={userData.aboutMe ?? ""}
          onChange={(e) => handleInputChange(e)}
        />
      </Col>

      <Col xs={6} className="mb-2 full-width-on-mobile">
        <label htmlFor="nationalityCountry">
          {t("manage_profile_about_location")}
        </label>
        <Input
          id="nationalityCountry"
          maxLength={locationMaxLength}
          name="nationalityCountry"
          placeholder={t("my_profile_country_placeholder")}
          type="text"
          value={userData.nationalityCountry ?? ""}
          onChange={(e) => handleInputChange(e)}
        />
      </Col>

      <Col xs={6} className="mb-2 full-width-on-mobile">
        <label htmlFor="website">{t("manage_profile_about_website")}</label>
        <Input
          id="website"
          maxLength={websiteMaxLength}
          name="website"
          placeholder={t("manage_profile_about_website_placeholder")}
          type="text"
          value={userData.website ?? ""}
          onChange={(e) => handleInputChange(e)}
        />
      </Col>
    </Row>
  );

  return (
    <>
      <Container className="mt-4 account-settings-container">
        <Row>
          <Col md={4} lg={4} xl={3} className="hide-on-mobile hide-on-tablet">
            <AccountPagesSidebar />
          </Col>
          <Col md={8} lg={8} xl={9} className="auto-margin">
            <>
              {noProfileWithThatId && (
                <h1 className="center-text">No profile with that Id</h1>
              )}
              {!noProfileWithThatId && (
                <>
                  <Row>
                    <Col lg={6}>
                      <Profile
                        isEditable={true}
                        handleUserPhotoUpload={handleUserPhotoUpload}
                      />
                    </Col>
                    <BannerSection />
                  </Row>
                  <Row>
                    <Col lg={12} className="about-me">
                      <Card>
                        <CardBody>
                          <div className="flex align-items-center mb-3 pb-3 header-section">
                            <h2 className="mb-0">
                              {t("manage_profile_about_header")}
                            </h2>
                            <button
                              disabled={
                                isUserDataSubmitting ||
                                !userData.firstName?.trim() ||
                                !userData.lastName?.trim()
                              }
                              type="button"
                              onClick={() => handleAccountDataUpdate()}
                              className="btn btn-primary flex-right profile-section-button no-flex"
                            >
                              {isUserDataSubmitting && (
                                <span className="btn-inner--icon">
                                  <FontAwesomeIcon icon={faSpinner} spin/>
                                </span>
                              )}
                              {t("save")}
                            </button>
                          </div>
                          <Row className="about-section">
                            <Col xs={6} className="mb-2 full-width-on-mobile">
                              <label htmlFor="email">
                                {t("manage_profile_about_email_address")}
                              </label>
                              <Input
                                id="email"
                                maxLength={emailMaxLength}
                                name="email"
                                placeholder={t("my_profile_email_placeholder")}
                                type="email"
                                value={userData.emailAddress ?? ""}
                                disabled
                              />
                            </Col>

                            <Col xs={6} className="mb-2">
                              {/*<label htmlFor="name">*/}
                              {/*  Username*/}
                              {/*</label>*/}
                              {/*<Input spellCheck={false} id="name" maxLength={emailMaxLength} name='name' placeholder={"Username"} value={userData.name ?? ''} onChange={(e) => handleInputChange(e)} />*/}
                            </Col>

                            <Col xs={6} className="mb-2 full-width-on-mobile">
                              <label htmlFor="firstName">
                                {t("manage_profile_about_first_name")}
                              </label>
                              <Input
                                id="firstName"
                                maxLength={nameMaxLength}
                                name="firstName"
                                className="input-field"
                                placeholder={t(
                                  "my_profile_first_name_placeholder"
                                )}
                                type="text"
                                value={userData.firstName ?? ""}
                                onChange={(e) => handleInputChange(e)}
                              />
                            </Col>

                            <Col xs={6} className="mb-2 full-width-on-mobile">
                              <label htmlFor="firstName">
                                {t("manage_profile_about_last_name")}
                              </label>
                              <Input
                                id="lastName"
                                maxLength={nameMaxLength}
                                name="lastName"
                                className="input-field"
                                placeholder={t(
                                  "my_profile_last_name_placeholder"
                                )}
                                type="text"
                                value={userData.lastName ?? ""}
                                onChange={(e) => handleInputChange(e)}
                              />
                            </Col>

                            <Col xs={12} className="mb-2 full-width-on-mobile">
                              <label htmlFor="aboutMe">
                                {t("manage_profile_about_bio")}
                              </label>
                              <Input
                                id="aboutMe"
                                maxLength={descriptionMaxLength}
                                name="aboutMe"
                                rows="3"
                                className="input-field"
                                placeholder={t(
                                  "my_profile_about_me_placeholder"
                                )}
                                type="textarea"
                                value={userData.aboutMe ?? ""}
                                onChange={(e) => handleInputChange(e)}
                              />
                            </Col>

                            <Col xs={12} className="mb-2 full-width-on-mobile">
                              <label htmlFor="website">
                                {t("manage_profile_about_website")}
                              </label>
                              <Input
                                id="website"
                                maxLength={websiteMaxLength}
                                name="website"
                                className="input-field"
                                placeholder={t(
                                  "manage_profile_about_website_placeholder"
                                )}
                                type="text"
                                value={userData.website ?? ""}
                                onChange={(e) => handleInputChange(e)}
                              />
                            </Col>

                            <Col xs={12} className="mt-2 full-width-on-mobile">
                              <p>{t("manage_profile_about_location")}</p>
                              <UserAddressInput
                                locationData={locationData}
                                setLocationData={setLocationData}
                                formErrors={userAddressErrors}
                              />
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </>
              )}
            </>
          </Col>
        </Row>
      </Container>
    </>
  );
}

{
  /*<Col xs={6} className='mb-2'>*/
}
{
  /*  <div className="flex-display-only">*/
}
{
  /*    <label htmlFor="instagramUrl">*/
}
{
  /*      {t("manage_profile_about_instagram")}*/
}
{
  /*    </label>*/
}
{
  /*    <div className="center-question-tooltip-with-label">*/
}
{
  /*      <QuestionTooltip tooltipDirection='right' tooltipUniqueId='instagram' tooltipValue={t("instragram_help")} />*/
}
{
  /*    </div>*/
}
{
  /*  </div>*/
}
{
  /*  <Input id="instagramUrl" maxLength={socialMediaHandleMaxLength} name='instagramUrl' placeholder={t("manage_profile_about_instagram_placeholder")} type="text" value={userData.instagramUrl ?? ''} onChange={(e) => handleInputChange(e)} />*/
}
{
  /*</Col>*/
}

{
  /*<Col xs={6} className='mb-2'>*/
}
{
  /*  <div className="flex-display-only">*/
}
{
  /*    <label htmlFor="facebookUrl">*/
}
{
  /*      {t("manage_profile_about_facebook")}*/
}
{
  /*    </label>*/
}
{
  /*    <div className="center-question-tooltip-with-label">*/
}
{
  /*      <QuestionTooltip tooltipDirection='right' tooltipUniqueId='facebook' tooltipValue={t("facebook_help")} />*/
}
{
  /*    </div>*/
}
{
  /*  </div>*/
}
{
  /*  <Input id="facebookUrl" maxLength={socialMediaHandleMaxLength} name='facebookUrl' placeholder={t("manage_profile_about_facebook_placeholder")} type="text" value={userData.facebookUrl ?? ''} onChange={(e) => handleInputChange(e)} />*/
}
{
  /*</Col>*/
}

{
  /*<Col xs={6} className='mb-2'>*/
}
{
  /*  <div className="flex-display-only">*/
}
{
  /*    <label htmlFor="twitterUrl">*/
}
{
  /*      {t("manage_profile_about_twitter")}*/
}
{
  /*    </label>*/
}
{
  /*    <div className="center-question-tooltip-with-label">*/
}
{
  /*      <QuestionTooltip tooltipDirection='right' tooltipUniqueId='twitter' tooltipValue={t("twitter_help")} />*/
}
{
  /*    </div>*/
}
{
  /*  </div>*/
}
{
  /*  <Input id="twitterUrl" maxLength={socialMediaHandleMaxLength} name='twitterUrl' placeholder={t("manage_profile_about_twitter_placeholder")} type="text" value={userData.twitterUrl ?? ''} onChange={(e) => handleInputChange(e)} />*/
}
{
  /*</Col>*/
}

{
  /*<Col xs={6} className='mb-2'>*/
}
{
  /*  <div className="flex-display-only">*/
}
{
  /*    <label htmlFor="youtubeUrl">*/
}
{
  /*      {t("manage_profile_about_youtube")}*/
}
{
  /*    </label>*/
}
{
  /*    <div className="center-question-tooltip-with-label">*/
}
{
  /*      <QuestionTooltip tooltipDirection='right' tooltipUniqueId='youtube' tooltipValue={t("youtube_help")} />*/
}
{
  /*    </div>*/
}
{
  /*  </div>*/
}
{
  /*  <Input id="youtubeUrl" maxLength={socialMediaHandleMaxLength} name='youtubeUrl' placeholder={t("manage_profile_about_youtube_placeholder")} type="text" value={userData.youtubeUrl ?? ''} onChange={(e) => handleInputChange(e)} />*/
}
{
  /*</Col>*/
}
