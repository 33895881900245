import { useState, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { SxProps, Theme, Box, Typography } from '@mui/material';
import { IConfig, StripeOnboardingRedirects, UserClient } from '../../api/rentMyApi';
import { useEnv } from '../../context/env.context';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useUserContext } from '../../context/UserContext';
import { useLocation } from 'react-router-dom';

type StripeAddressVerificationStepProps = {
  backButton: ReactNode;
  handleNextStep: () => void;
  headingStyle: SxProps<Theme>;
  stepSummaryStyle: SxProps<Theme>;
};

export function StripeAddressVerificationStep({
  backButton,
  handleNextStep,
  headingStyle,
  stepSummaryStyle,
}: StripeAddressVerificationStepProps) {
  const { t } = useTranslation();
  const { apiServerUrl } = useEnv();
  const { getAccessTokenSilently } = useAuth0();
  const { user } = useUserContext();
  const location = useLocation();
  const { pathname } = location;
  const [isLoading, setIsLoading] = useState(false);

  const verifyUserAddress = async () => {
    setIsLoading(true);
    const onboardingUrl = window.location.href + "?redirectFromStripeAddressVerification=true";

    // Navigation to next step is handled by parent component (OnbardingStepper)
    const stripeRedirectObj: StripeOnboardingRedirects = new StripeOnboardingRedirects({
      refreshUrl: onboardingUrl,
      returnUrl: onboardingUrl,
    });
    const token = await getAccessTokenSilently();
    const userClient = new UserClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT);

    userClient!
      .onboardStripe(undefined, stripeRedirectObj)
      .then((accountLink) => {
        window.location.href = accountLink.url!;
      })
      .catch((e) => {
        toast.error('We were unable to onboard your account with Stripe, please contact support');
        setIsLoading(false);
      });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography component="h1" sx={headingStyle}>
        {t('onboarding_address_verification_with_stripe')}
      </Typography>
      {backButton}
      {user.stripeOnboarded ? (
            <Typography component="h1" sx={headingStyle}>
          <span>
            <FontAwesomeIcon color="green" icon={faCheckCircle} size="2xl" className="text-large having-trouble-a" />
          </span>
          {t('onboarding_verification_completed')}
        </Typography>
      ) : (
        <Typography sx={stepSummaryStyle}>{t('onboarding_address_verification')}</Typography>
      )}

      <button
        type="button"
        onClick={user.stripeOnboarded ? handleNextStep : verifyUserAddress}
        disabled={isLoading}
        className="btn btn-primary onboarding-button"
      >
        {isLoading && (
          <span className="btn-inner--icon">
            <FontAwesomeIcon icon={faSpinner} spin/>
          </span>
        )}
        {user.stripeOnboarded ? t('onboarding_next') : t('onboarding_get_verified')}
      </button>
    </Box>
  );
}
