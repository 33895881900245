import moment from "moment";
import { dateStringFormat } from "../common/DateFormatting";

export const idConst = "id";
export const termsOfServiceName = "Terms of Service";
export const termsOfServiceType = "Service";
const startDateConst = "start-date";
const endDateConst = "end-date";

export function toBookingConfirmationUrl(
  searchParams: string,
  startDate: Date,
  endDate: Date
) {
  return (
    "/item-booking-confirmation" +
    searchParams +
    `&${startDateConst}=` +
    moment(startDate).format(dateStringFormat) +
    `&${endDateConst}=` +
    moment(endDate).format(dateStringFormat)
  );
}
