import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'reactstrap';
import { format, isSameMonth } from 'date-fns';

import StarRatingSelect from '../../common/StarRatingSelect';
import ImageUpload from '../../common/ImageSelector';
import { ImagePromise } from '../../../models/interfaces/Image';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import {
  BookingDetailedDto,
  FileClient,
  IConfig,
  ImageDto,
  ItemClient,
  ReviewCreateDto,
} from '../../../api/rentMyApi';
import { useEnv } from '../../../context/env.context';

interface BookingReviewProps {
  setIsProcessing: (isProcessing: boolean) => void;
  booking: BookingDetailedDto;
}

export const BookingReview = ({ setIsProcessing, booking }: BookingReviewProps) => {
  const [communicationRating, setCommunicationRating] = useState(0);
  const [pickUpRating, setPickUpRating] = useState(0);
  const [dropOffRating, setDropOffRating] = useState(0);
  const [accuracyRating, setAccuracyRating] = useState(0);
  const [qualityRating, setQuality] = useState(0);
  const [valueRating, setValueRating] = useState(0);
  const [privateReviewMyRent, setPrivateReviewMyRent] = useState('');
  const [privateReview, setPrivateReview] = useState('');
  const [publicReview, setPublicReview] = useState('');
  const [uploadedImages, setUploadedImages] = useState<ImageDto[]>([]);
  const { getAccessTokenSilently } = useAuth0();
  const [apiFileClient, setApiFileClient] = useState(new FileClient(new IConfig('notoken'), process.env.REACT_APP_API_ENDPOINT));
  const [apiItemClient, setApiItemClient] = useState<ItemClient>(new ItemClient(new IConfig('notoken'), process.env.REACT_APP_API_ENDPOINT));
  const [showModal, setShowModal] = useState(false);

  const [isUploadingImages, setIsUploadingImages] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const { apiServerUrl } = useEnv();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    async function initClient() {
      const token = await getAccessTokenSilently();
      setApiFileClient(new FileClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT));
      setApiItemClient(new ItemClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT));
    }

    initClient();
  }, []);

  const onNewImagesUploaded = (newImages: File[]) => {
    setIsProcessing(true);
    setIsUploadingImages(true);
    const promises = newImages.map((image) => {
      const updated_image: File = new File([image], image.name);
      return apiFileClient.upload('.jpeg', {
        fileName: updated_image.name,
        data: image,
      });
    });

    Promise.allSettled(promises)
      .then((data: ImagePromise[]) => {
        const totalImages = data.length;
        const fulfilled = data.filter((promise: ImagePromise) => promise.status === 'fulfilled');
        const images = fulfilled.map((promise: ImagePromise) => promise.value as ImageDto);

        if (totalImages === fulfilled.length) {
          toast.success(t('item_listing_upload_succes'));
        } else {
          toast.warn(`${images.length} ${t('item_listing_out_of')} ${totalImages} ${t('item_listing_partial_upload')}`);
        }

        setIsProcessing(false);
        setIsUploadingImages(false);
        setUploadedImages((prevImages) => [...prevImages, ...images]);
      })
      .catch((error) => {
        // console.error('Error uploading images:', error);
        setIsProcessing(false);
        setIsUploadingImages(false);
        toast.error('Failed to upload images. Please try again.');
      });
  };

  const onSubmit = () => {
    const payload = new ReviewCreateDto({
      active: true,
      description: publicReview,
      communicationRating,
      pickUpRating,
      dropOffRating,
      accuracyRating,
      qualityRating,
      valueRating,
      imageIds: uploadedImages.map((image) => image.id),
      videoIds: undefined,
    });

    apiItemClient
      .createPOST12(booking?.item?.id, payload)
      .then((response) => {
        // console.log(response)
        setShowModal(true);
        toast.success('review has been saved.');
        navigate('/account/my-rentals');
      })
      .catch((error) => {
        // console.error('Error creating review:', error);
        toast.error('Failed to save review. Please try again.');
      });
  };

  const onRemovePhoto = (index: number) => {
    setUploadedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  // const image = booking.item && booking.item.images && booking.item.images.length && booking.item.images[0];
  const image = booking.item?.images?.[0] ?? undefined;
  const src = image?.compressedPath ? image.compressedPath : '/assets/img/placeholder.jpg';
  const { startDate, endDate } = booking;
  const startOfBooking = startDate ? new Date(startDate) : new Date();
  const endOfBooking = endDate ? new Date(endDate) : new Date();
  const sameMonth = isSameMonth(startOfBooking, endOfBooking);
  const endDateFormat = sameMonth ? 'dd, yy' : 'MMM dd, yy';

  let dateStr = '';

  try {
    dateStr =
      startOfBooking && endOfBooking && endDateFormat
        ? `${format(startOfBooking, 'MMM dd')}-${format(endOfBooking, endDateFormat)}`
        : '';
  } catch (e) { /* empty */
  }

  return (
    <>
      <ImageUpload
        onNewImagesUploaded={onNewImagesUploaded}
        ref={inputRef}
        accept={'image/jpg, image/png, image/jpeg, image/webp'}
        sizeLimitMB={10}
      />
      <br />
      <Card className="custom-card">
        <h3>Review your booking</h3>
        <div className="uploaded-row">
          <div className="uploaded-row-image-div">
            <img src={src} alt="Item" className={'uploaded-row-image'} />
          </div>
          <div>
            <p className="uploaded-row-title">{booking?.item?.name}</p>
            <p className="uploaded-row-subtitle">
              {`${t('account_bookings_shared_by')} ${booking?.sharer?.name}`}
              <br />
              {dateStr}
            </p>
          </div>
        </div>
      </Card>

      <Card className="custom-card">
        <h3>Communication and handover</h3>
        <div>
          <p className="review-question">Communication</p>
          <p className="review-question">How clear and helpful did you find communication with the listing sharer?</p>
          <StarRatingSelect
            numberOfStars={5}
            value={communicationRating}
            onClick={(value: React.SetStateAction<number>) => setCommunicationRating(value)}
          />
        </div>
        <br />
        <div>
          <p className="review-question">Pick-up/check-in</p>
          <p className="review-question">How easy did you find your pick-up/check-in experience?</p>
          <StarRatingSelect numberOfStars={5} value={pickUpRating}
                            onClick={(value: React.SetStateAction<number>) => setPickUpRating(value)} />
        </div>
        <br />
        <div>
          <p className="review-question">Drop-off/check-out</p>
          <p className="review-question">How easy did you find your drop-off/check-out experience?</p>
          <StarRatingSelect numberOfStars={5} value={dropOffRating}
                            onClick={(value: React.SetStateAction<number>) => setDropOffRating(value)} />
        </div>
      </Card>

      <Card className="custom-card">
        <h3>Accuracy, quality and value</h3>
        <div>
          <p className="review-question">Accuracy</p>
          <p className="review-question">How accurate do you feel the listing images and description were?</p>
          <StarRatingSelect numberOfStars={5} value={accuracyRating}
                            onClick={(value: React.SetStateAction<number>) => setAccuracyRating(value)} />
        </div>
        <br />
        <div>
          <p className="review-question">Quality</p>
          <p className="review-question">How closely did the listing meet your expectations of quality?</p>
          <StarRatingSelect numberOfStars={5} value={qualityRating}
                            onClick={(value: React.SetStateAction<number>) => setQuality(value)} />
        </div>
        <br />
        <div>
          <p className="review-question">Value</p>
          <p className="review-question">How much do you feel your experience of renting provided value for money?</p>
          <StarRatingSelect numberOfStars={5} value={valueRating}
                            onClick={(value: React.SetStateAction<number>) => setValueRating(value)} />
        </div>
      </Card>

      <Card className="custom-card">
        <h3>Write a private review for RentMy</h3>
        <div>
          <p className="review-question">Guidance</p>
          <p className="review-question">
            This feedback is just for RentMy. This will not be made public. Please share your experience using the
            platform to help us improve.
          </p>
          <br />
          <textarea
            value={privateReviewMyRent}
            onChange={(e) => setPrivateReviewMyRent(e.target.value)}
            placeholder="Write a review..."
            className="review-input"
          />
        </div>
      </Card>

      <Card className="custom-card">
        <h3>Write a private review for {booking?.sharer?.name}</h3>
        <div>
          <p className="review-question">Guidance</p>
          <p className="review-question">This review is just for the listing sharer. We will not make it public.</p>
          <br />
          <textarea
            value={privateReview}
            onChange={(e) => setPrivateReview(e.target.value)}
            placeholder="Write a review..."
            className="review-input"
          />
        </div>
      </Card>

      <Card className="custom-card">
        <h3>Write a public review</h3>
        <div>
          <p className="review-question">Guidance</p>
          <p className="review-question">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Your review will be visible on the listing sharer's profile. Please check your reviews for errors, once it
            is published it cannot be edited via this page.
          </p>
          <br />
          <textarea
            value={publicReview}
            onChange={(e) => setPublicReview(e.target.value)}
            placeholder="Write a review..."
            className="review-input"
          />
        </div>
      </Card>

      <Card className="custom-card">
        <h3>Add images of your experience</h3>
        <div>
          <p className="review-question">Guidance</p>
          <p className="review-question">
            Help encourage more people to rent this listing by sharing images of your experience.
          </p>
          <br />
          {uploadedImages.length > 0 && (
            <>
              <div className="account-booking-list">
                {uploadedImages.map((image, i) => (
                  <div key={i} className="booking-item uploaded-row">
                    <div className="uploaded-row-image-div">
                      <img
                        src={image.compressedPath}
                        alt="Uploaded"
                        className={'uploaded-row-image'}
                      />
                    </div>
                    <Button
                      color="primary"
                      type="button"
                      size="sm"
                      className="account-booking-list-button"
                      onClick={() => onRemovePhoto(i)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </div>
                ))}
              </div>
              <br />
            </>
          )}
        </div>
        <Button
          outline
          color="primary"
          type="button"
          onClick={() => {
            inputRef?.current?.click();
          }}
          className="auto-margin"
        >
          {isUploadingImages ? <div className="loading-spinner-small"></div> : 'Upload images'}
        </Button>
      </Card>

      <div className="handover_button-container">
        <Button className="mb-2" color="primary" type="button" onClick={onSubmit}>
          Submit review
        </Button>
      </div>
    </>
  );
};
