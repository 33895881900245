import { Container } from "reactstrap";
import { Terms } from "../components/terms/Terms";

export function TermsPage() {

  return (
    <Container>
      {Terms({ itemListingPayload: undefined, itemBookingPayload: undefined, isLogin: true, withAcceptButton: false })}
    </Container>
  );
}
