import { useEffect, useState } from "react";
import {
  IConfig,
  ReferralClient,
  ReferralSimpleDto,
} from "../../../api/rentMyApi";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { useEnv } from "../../../context/env.context";
import { useUserContext } from "../../../context/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

interface ReferralsProps {
  handleReferralsClick: () => void;
}
export const Referrals = ({ handleReferralsClick }: ReferralsProps) => {
  const [wrapText, setWrapText] = useState(false);
  const [referralData, setReferralData] = useState(new ReferralSimpleDto());
  const [isLoading, setIsLoading] = useState(true);
  const [opacity, setOpacity] = useState(0);
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const { user } = useUserContext();
  const [tooltipText, setTooltipText] = useState("Copy");
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  useEffect(() => {
    const fetchReferralData = async () => {
      const token = await getAccessTokenSilently();
      const referralClient = new ReferralClient(
        new IConfig(token),
        process.env.REACT_APP_API_ENDPOINT
      );
      try {
        const response = await referralClient.referral(user.id);
        setReferralData(response);
        console.log("REFERRAL");
        console.log(response);
      } catch (e) {
        console.error(e);
      }

      setIsLoading(false);
      setOpacity(1);
    };

    fetchReferralData();
  }, [getAccessTokenSilently]);

  const handleShareClick = () => {
    const subject = encodeURIComponent(
      `${user.firstName} has something awesome to share with you`
    );
    const body = encodeURIComponent(`Hey,

Your lovely friend ${user.firstName} has shared something super special with you - RentMy.com!

RentMy is a peer-to-peer rental platform where people in your community can share their belongings. It's a bit like Airbnb, but for your stuff: think lawn mower, cameras, kayak, tent, trailers, the list is endless. Whether sharing your stuff and making money, or renting from other people to try something new or access more, RentMy is the place to be!

And even better, because ${user.firstName} has referred you, you'll get £10 added to your account when you sign up using their code - FREE rentals, does it get any better?!

Sign up now using this link:
https://www.rentmy.com/referral

And enter the referral code:
${user.referral?.code}

The small print:
- It's free to sign up
- Add the voucher when you book your first rental over £60 and get £10 off!
- Your referrer will also get a little thank you for being an awesome friend

Happy renting!`);

    window.location.href = `mailto:?subject=${subject}&body=${body}`;
  };

  return (
    <Card>
      <CardBody>
        <div className="text-align-center mb-4">
          <h2>Share the Savings</h2>
        </div>
        <div className="icon-row mb-4">
          <img
            className="referral-icon"
            src="/assets/svgs/referrals-icons.svg"
            alt="referrals icons"
          />
        </div>
        <p className="referral-description mb-3">
          Share the joy of renting.
          <br />
          Give a £10 voucher by sharing the code below.
        </p>
        <div className="text-align-center mb-3">
          <p>Unique referral code</p>
          <h2 className="user-code">
            {user.referral?.code || "No code available"}
            {user.referral?.code && (
              <div
                className="copy-button"
                onMouseEnter={() => {
                  setIsTooltipVisible(true);
                  setTooltipText("Copy");
                }}
                onMouseLeave={() => setIsTooltipVisible(false)}
                onClick={() => {
                  if (typeof user.referral.code === "string") {
                    navigator.clipboard.writeText(user.referral.code);
                  }
                  setTooltipText("Copied");
                }}
              >
                <FontAwesomeIcon icon={faCopy} color="grey" />
                <span
                  className={`tooltip ${
                    isTooltipVisible ? "visible" : "hidden"
                  }`}
                >
                  {tooltipText}
                </span>
              </div>
            )}
          </h2>
        </div>
        <Row>
          <Col>
            <Button className="btn-primary" block onClick={handleShareClick}>
              Share
            </Button>
          </Col>
          <Col>
            <Button
              className="btn-outline-primary"
              block
              onClick={handleReferralsClick}
            >
              View Referrals
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
