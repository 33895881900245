import * as React from "react";
import { ExploreTopCategories } from "../components/home/ExploreTopCategories";
import { PopularSlider } from "../components/home/PopularSlider";
import { OneWideImage } from "../components/home/OneWideImage";
import { OneWideImage2 } from "../components/home/OneWideImage2";
import { TwoImages } from "../components/home/TwoImages";
import { LogoStrip } from "../components/home/LogoStrip";
import { LogoStripWhite } from "../components/home/LogoStripWhite";
import { Footer } from "../components/layout/Footer";
import Header from "../components/home/Header";

export function HomePage() {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);

  React.useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {/* If you plan to use conditional rendering, adjust and uncomment this section
      {isMobile && isAuthenticated ? (
        <MobileHomepage />
      ) : (
        <>*/}
          <Header />
          <PopularSlider />
          <OneWideImage />
          <ExploreTopCategories />
          <OneWideImage2 />
          <TwoImages />
          <LogoStrip />
          <Footer />
      {/*  </>
      )}*/}
    </>
  );
}