import * as React from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { getCancellationPolicyDefault } from "./ItemListingConstantsAndDefaults";
import { CancellationPolicy } from "../../api/rentMyApi";
import { AccordionState } from "../../models/interfaces/AccordionState";

interface CancellationPolicySelectorProps {
  toggleModal: (value: boolean) => void;
  setAccordionState: (value: AccordionState[]) => void;
  accordionState: AccordionState[];
}

export default function CancellationPolicySelector({
  toggleModal,
  setAccordionState,
  accordionState,
}: CancellationPolicySelectorProps) {
  const { t } = useTranslation();

  return (
    <Card id="booking-terms-section" className="item-listing-card">
      <CardBody>
        <h2>{t("item_listing_cancellation_policy_title")}</h2>
        <Row className={"justify-content-space-between"}>
          {[
            CancellationPolicy.Flexible,
            CancellationPolicy.Moderate,
            CancellationPolicy.Strict,
          ].map((cancellationType, index) => {
            const colourScheme =
              "cancellation-button-" + (index + 1).toString();
            const cancellationPolicy = accordionState.find(
              (o) => o.title === cancellationType
            );

            return (
              <Col
                key={cancellationType}
                sm={2}
                className="item-listing-cancellation-column"
              >
                <Button
                  className={"full-width standard-font-weight " + colourScheme}
                  name="save"
                  type="button"
                  onClick={() => {
                    setAccordionState(
                      getCancellationPolicyDefault(
                        CancellationPolicy[cancellationType]
                      )
                    );
                  }}
                >
                  <div className="button-with-radio">
                    <span>{cancellationType}</span>
                    <div className="outer-circle">
                      {cancellationPolicy &&
                        cancellationPolicy.toggleSelected && (
                          <div className="inner-circle"></div>
                        )}
                    </div>
                  </div>
                </Button>
              </Col>
            );
          })}
        </Row>
        <p
          onClick={() => toggleModal(true)}
          className="small top-padding-4 underlined clickable width-120"
        >
          What do these mean?
        </p>
      </CardBody>
    </Card>
  );
}
