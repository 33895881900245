import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../context/env.context";
import { useUserContext } from "../../context/UserContext";
import NavMenuUserLoggedInDropdown from "../layout/nav-menu/NavMenuUserLoggedInDropdown";
import { LoadingFullScreen } from "./LoadingFullScreen";
import React from "react";

interface TopRightDropDownMenuProps {
  isHamburgerOpen: boolean;
  setHamburgerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isMobile: boolean;
}
export function TopRightDropDownMenu({
  isHamburgerOpen,
  setHamburgerOpen,
  isMobile,
}: TopRightDropDownMenuProps) {
  //const { apiServerUrl } = useEnv();

  const { user } = useUserContext();

  const { isLoading } = useAuth0();

  const src = user.profileImage?.compressedPath
    ? user.profileImage.compressedPath
    : "assets/img/profile-pic-placeholder.webp";

  return (
    <>
      {isLoading && <LoadingFullScreen />}

      {!isLoading && (
        <NavMenuUserLoggedInDropdown
          username={user.name ?? "User"}
          userProfilePic={src}
          isMobile={isMobile}
          isHamburgerOpen={isHamburgerOpen}
          setHamburgerOpen={setHamburgerOpen}
        />
      )}
    </>
  );
}
