import {
  IConfig,
  RentMyUserDetailedDto,
  StripeOnboardingRedirects,
  UserClient,
  UserIntention,
} from "../../../api/rentMyApi";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Input,
  Row,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircleInfo,
  faFingerprint,
  faMinusCircle,
  faPiggyBank,
  faPlusCircle,
  faPoundSign,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useUserContext } from "../../../context/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import {
  LoadingFullScreen,
  LoadingFullSide,
} from "../../common/LoadingFullScreen";
import { useEnv } from "../../../context/env.context";

interface NewIdentityAndPaymentStepsProps {
  isOnboarding?: boolean;
  redirectLink?: string;
  showVerificationOnly?: boolean;
}

export const NewIdentityAndPaymentSteps = ({
  isOnboarding = false,
  redirectLink = "/account/verification",
  showVerificationOnly = false,
}: NewIdentityAndPaymentStepsProps) => {
  const sharerOnlyPage = window.location.pathname === "/item";

  const [circle1, setCircle1Filled] = useState(false);
  const [circle2, setCircle2Filled] = useState(false);
  const [circle3, setCircle3Filled] = useState(false);

  const { t } = useTranslation();
  const { user } = useUserContext();

  const [localUser, setLocalUser] = useState(new RentMyUserDetailedDto());
  const [userClient, setUserClient] = useState<UserClient | undefined>(
    undefined
  );
  const [stripe, setStripe] = useState<Stripe>();
  const [userBankSortCode, setUserBankSortCode] = useState<string>("");
  const [userBankAccountNumber, setUserBankAccountNumber] =
    useState<string>("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [bankAccountError, setBankAccountError] = useState(false);
  const [clientInitialised, setClientInitialised] = useState(false);
  const [renterVerificationOpen, setRenterVerificationOpen] = useState(true);
  const [sharerVerificationOpen, setSharerVerificationOpen] = useState(false);

  const [isVerified, setIsVerified] = useState(false);
  const [canBeSharer, setCanBeSharer] = useState(false);
  const [stripeOnboarded, setStripeOnboarded] = useState(false);

  const [isMobile, setIsMobile] = useState(false);
  const [onboardStripeButtonClicked, setOnboardStripeButtonClicked] =
    useState(false);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { apiServerUrl } = useEnv();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const shouldReload = params.get("reload");

  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();

  const [progress, setProgress] = useState(0);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProgress(Number(event.target.value));
  };

  async function loadStripeClient() {
    try {
      const stripeApiKey = process.env.REACT_APP_STRIPE_PUBLISHING_KEY || "";
      const _stripe = await loadStripe(stripeApiKey);
      if (_stripe) setStripe(_stripe);
    } catch (error) {
      // console.log("Failed to load stripe")
      // console.error(error);
    }
  }

  useEffect(() => {
    loadStripeClient();
  }, []);

  useEffect(() => {
    if (
      (!user.canBeSharer &&
        user.intention != UserIntention.Renter &&
        user.isVerified) ||
      sharerOnlyPage
    )
      setSharerVerificationOpen(true);
  }, []);

  async function initClient() {
    try {
      if (shouldReload) {
        window.location.replace(window.origin + "/account/verification");
      }
      const token = await getAccessTokenSilently();
      const userClient = new UserClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT);
      setUserClient(userClient);
      const localUser = await userClient.current(true, true);
      setLocalUser(localUser);
      setIsVerified(localUser.isVerified);
      setCanBeSharer(localUser.canBeSharer);
      setStripeOnboarded(localUser.stripeOnboarded);
    } catch (e) {
      // console.log("error inside top useEffect")
      // console.log(e)
      // console.log("That was the error")
    }
  }

  useEffect(() => {
    try {
      if (!isLoading && isAuthenticated)
        initClient().then(() => {
          setClientInitialised(true);
        });
    } catch (e) {
      // console.log("caught out of scope top function")
      // console.log(e)
    }
  }, [isAuthenticated, isLoading]);

  function onBoardUser() {
    setOnboardStripeButtonClicked(true);
    setIsProcessing(true);
    const accountProfileLocationUrl =
      window.location.origin + "/account/verification?reload=true";

    const stripeRedirectObj: StripeOnboardingRedirects =
      new StripeOnboardingRedirects({
        refreshUrl: accountProfileLocationUrl,
        returnUrl: accountProfileLocationUrl,
      });

    userClient!
      .onboardStripe(undefined, stripeRedirectObj)
      .then((accountLink) => {
        window.location.href = accountLink.url!;
      })
      .catch((e) => {
        toast.error(
          "We were unable to onboard your account with Stripe, please contact support"
        );
        setIsProcessing(false);
        setOnboardStripeButtonClicked(false);
      });
  }

  function verifyUser() {
    setIsProcessing(true);
    const accountProfileLocationUrl =
      window.location.origin + "/account/verification?reload=true";
    userClient!
      .verification(accountProfileLocationUrl)
      .then((accountLink) => {
        window.location.href = accountLink!;
      })
      .catch((e) => {
        console.log(e);
        // toast.error("We were unable to verify your account with Stripe, please contact support");
        setIsProcessing(false);
        window.location.reload();
      });
  }

  function triggerBankError(errorMessage: string) {
    toast.error(errorMessage);
    setBankAccountError(true);
  }

  const updateUserIntention = async (userIntention: UserIntention) => {
    await userClient?.update25(undefined, { intention: userIntention });
    window.location.reload();
  };

  // const getBankAccounts = async () => {
  //     try {
  //         if (userClient) {
  //             const bankAccounts = await userClient!.listStripeBanks();
  //             console.log("BANK ACCOUNTS")
  //             console.log(bankAccounts)
  //         }
  //     } catch (e) {
  //         console.log("Error at addBankDetails")
  //         console.log(e)
  //     }
  // }

  async function addBankDetails() {
    setIsProcessing(true);
    if (stripe !== undefined) {
      stripe
        .createToken("bank_account", {
          country: "GB",
          currency: "gbp",
          account_number: userBankAccountNumber,
          routing_number: userBankSortCode,
          account_holder_name: localUser.name,
          account_holder_type: "individual",
        })
        .then(function (result) {
          if (result.error !== undefined) {
            triggerBankError(
              "We were unable to add your bank account, please contact support"
            );
            setIsProcessing(false);
          } else if (result.token !== undefined) {
            userClient!
              .addStripeBank(undefined, result.token.id)
              .then((response) => {
                if (response) {
                  setIsProcessing(false);
                  window.location.reload();
                }
                initClient();
              })
              .catch((e) => {
                // console.log("Error at addBankDetails on listStripeBanks")
                // console.log(e)
                // console.log(e.response);
                triggerBankError(
                  "We were unable to add your bank account, please contact support"
                );
                setIsProcessing(false);
              });
            // window.location.reload()
          }
        })
        .catch((e) => {
          // console.log("Error at addBankDetails on outer scope")
          triggerBankError(
            "We were unable to add your bank account, please contact support"
          );
          setIsProcessing(false);
        })
        .catch((e) => {
          triggerBankError(
            "We were unable to add your bank account, please contact support"
          );
          setIsProcessing(false);
        });
    } else {
      toast.error("Error connecting to Stripe, please try again later");
      setIsProcessing(false);
    }
  }

  useEffect(() => {
    setIsMobile(window.innerWidth <= 576);
  });

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--page-width",
      window.innerWidth + "px"
    );
    const handleResize = () => {
      document.documentElement.style.setProperty(
        "--page-width",
        window.innerWidth + "px"
      );
    };
    window.addEventListener("resize", () => handleResize());
  });

  useEffect(() => {
    let progressBarPercentage = "0%";

    if (isVerified) {
      progressBarPercentage = "25%";
      setCircle1Filled(true);

      if (stripeOnboarded) {
        progressBarPercentage = "55%";
        setCircle2Filled(true);

        if (canBeSharer) {
          progressBarPercentage = "100";
          setCircle3Filled(true);
        }
      }
    }

    document.documentElement.style.setProperty(
      "--personal-details-complete-percent",
      progressBarPercentage
    );
  }, [isVerified, stripeOnboarded, canBeSharer]);

  if (loading) return <LoadingFullScreen />;

  return (
    <>
      {clientInitialised ? (
        <>
          <Col>
            <Row className="justify-content-end">
              <div className="row justify-content-between to-end">
                <a
                  className="row having-trouble-section"
                  href="https://zen.rentmy.com/hc/en-gb/requests/new"
                >
                  <h5 className="italic font-weight-300">
                    Having trouble getting verified?
                  </h5>
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="text-large having-trouble-a"
                  />
                </a>
              </div>
            </Row>
          </Col>

          {(!isOnboarding || showVerificationOnly) && (
            <Card>
              {isOnboarding && (
                <div className="banner no-margin no-padding">
                  You must complete verification before you can rent or message
                  other users
                </div>
              )}

              <CardHeader className="no-margin row justify-content-between">
                <Col>
                  <Row>
                    <h2>ID Verification with Stripe</h2>
                    {isVerified && (
                      <FontAwesomeIcon
                        color="green"
                        icon={faCheckCircle}
                        size="2xl"
                        className="text-large having-trouble-a"
                      />
                    )}
                  </Row>
                  <Row>
                    <p>Required to message other users and rent items</p>
                  </Row>
                </Col>
              </CardHeader>
              {!isVerified && (
                <CardBody className="auto-margin verification-card-body">
                  <Button
                    color="primary"
                    className="full-width"
                    onClick={verifyUser}
                    disabled={isVerified}
                  >
                    <Row>
                      <div className="auto-margin">
                        <FontAwesomeIcon
                          size="lg"
                          icon={faFingerprint}
                          className="account-details-fa-icon font-awesome-icon-with-text"
                        />
                        <text>{isVerified ? "Verified" : "Get verified"}</text>
                      </div>
                    </Row>
                  </Button>
                </CardBody>
              )}
            </Card>
          )}
          {(!isOnboarding || (!showVerificationOnly && isOnboarding)) && (
            <Card>
              {isOnboarding && (
                <div className="banner no-margin no-padding">
                  You must complete Sharer Verification to list an item
                </div>
              )}
              <CardHeader
                className="no-margin row justify-content-between cursor-pointer"
                onClick={() =>
                  setSharerVerificationOpen(!sharerVerificationOpen)
                }
              >
                <Col>
                  <Row>
                    <h2>For Sharers</h2>
                    {canBeSharer && (
                      <FontAwesomeIcon
                        color="green"
                        icon={faCheckCircle}
                        size="2xl"
                        className="text-large having-trouble-a"
                      />
                    )}
                  </Row>
                  <Row>
                    <p>Required to list items, you must be verified first</p>
                  </Row>
                </Col>{" "}
                <FontAwesomeIcon
                  icon={sharerVerificationOpen ? faMinusCircle : faPlusCircle}
                  size="2xl"
                  className="text-large having-trouble-a"
                />
              </CardHeader>
              <Collapse isOpen={sharerVerificationOpen}>
                <CardBody className="flex-column">
                  <div className="account-personal-details-grid-sharer position-relative">
                    <div
                      className={
                        isOnboarding
                          ? "meter fingerprint-icon onboarding-meter"
                          : "meter fingerprint-icon"
                      }
                    >
                      <span>
                        <span className="progress"></span>
                      </span>
                    </div>

                    {circle1 && (
                      <div
                        className={
                          "fingerprint-icon personal-details-icon icon-circle circle-meter"
                        }
                      >
                        <span>
                          <span className="circle-progress circle-1"></span>
                        </span>
                      </div>
                    )}
                    <div className="fingerprint-icon personal-details-icon icon-circle">
                      <FontAwesomeIcon
                        icon={faFingerprint}
                        className="text-xl account-details-fa-icon"
                      />
                    </div>

                    {circle2 && (
                      <div
                        className={
                          "information-icon personal-details-icon icon-circle circle-meter circle-meter-2"
                        }
                      >
                        <span>
                          <span className="circle-progress circle-2"></span>
                        </span>
                      </div>
                    )}

                    <div className="information-icon personal-details-icon icon-circle">
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        className="text-xl account-details-fa-icon"
                      />
                    </div>

                    {circle3 && (
                      <div
                        className={
                          "piggy-bank-icon personal-details-icon icon-circle circle-meter circle-meter-3"
                        }
                      >
                        <span>
                          <span className="circle-progress circle-3"></span>
                        </span>
                      </div>
                    )}

                    <div className="piggy-bank-icon personal-details-icon icon-circle">
                      <FontAwesomeIcon
                        icon={faPiggyBank}
                        className="text-xl account-details-fa-icon account-details-pig-icon"
                      />
                    </div>

                    {circle3 && (
                      <div
                        className={
                          "money-icon personal-details-icon icon-circle circle-meter circle-meter-4"
                        }
                      >
                        <span>
                          <span className="circle-progress circle-4"></span>
                        </span>
                      </div>
                    )}

                    <div className="money-icon personal-details-icon icon-circle">
                      <FontAwesomeIcon
                        icon={faPoundSign}
                        className="text-xl account-details-fa-icon"
                      />
                    </div>

                    <div className="get-verified personal-details-form-section">
                      <h2 className="mb-0 ml-2 account-details-step-title">
                        Get verified with Stripe
                      </h2>
                      <p className="mb-0 ml-2 account-details-step-sub-title">
                        Add your photo ID & take a photo of yourself to confirm
                        its you
                      </p>
                      <Button
                        color="primary"
                        disabled={isVerified}
                        type="button"
                        outline
                        className="profile-section-button account-details-step-sub-button"
                        onClick={verifyUser}
                      >
                        {isVerified
                          ? "Verified"
                          : t("account_settings_stripe_validation_button")}
                      </Button>
                    </div>
                    <div className="address personal-details-form-section">
                      <h2 className="mb-0 ml-2 account-details-step-title">
                        Address and Account Details
                      </h2>
                      <p className="mb-0 ml-2 account-details-step-sub-title">
                        Tell us your name & address so we can confirm this
                        against your ID
                      </p>
                      <Button
                        color="primary"
                        type="button"
                        outline
                        className="profile-section-button account-details-step-sub-button"
                        disabled={!isVerified}
                        onClick={onBoardUser}
                      >
                        {onboardStripeButtonClicked ? (
                          <div className="loading-spinner-small"></div>
                        ) : stripeOnboarded ? (
                          t("account_settings_edit_stripe_details_button")
                        ) : (
                          "Add"
                        )}
                      </Button>
                    </div>
                    <div className="bank personal-details-form-section">
                      <h2 className="mb-0 ml-2 account-details-step-title">
                        Bank Details
                      </h2>
                      <p className="mb-0 ml-2 account-details-step-sub-title">
                        Where you want to get paid into - you can come back and
                        edit these details whenever you want
                      </p>
                      <div className="flex-display-row padding-top-10 pl-2">
                        <Input
                          id="sortCode"
                          maxLength={6}
                          name="sortCode"
                          invalid={bankAccountError}
                          placeholder={t("account_settings_sort_code_title")}
                          type="text"
                          value={userBankSortCode}
                          onChange={(e) => setUserBankSortCode(e.target.value)}
                          className="account-details-step-bank-input input-field"
                        />
                        <div className="width-6" />
                        <Input
                          id="accountNumber"
                          maxLength={8}
                          name="accountNumber"
                          invalid={bankAccountError}
                          placeholder={t(
                            "account_settings_account_number_title"
                          )}
                          type="text"
                          value={userBankAccountNumber}
                          onChange={(e) =>
                            setUserBankAccountNumber(e.target.value)
                          }
                          className="account-details-step-bank-input input-field ml-2"
                        />
                      </div>
                      <Button
                        color="primary"
                        disabled={!userBankAccountNumber || !userBankSortCode}
                        type="button"
                        outline
                        className="profile-section-button account-details-step-sub-button"
                        onClick={addBankDetails}
                      >
                        {canBeSharer
                          ? "Update Bank Account"
                          : t("account_settings_add_bank_account_details")}
                      </Button>
                    </div>
                    <div className="start personal-details-form-section padding-bottom-15">
                      <h2 className="mb-0 ml-2 account-details-step-title">
                        Start earning!
                      </h2>
                      <p className="mb-0 ml-2 account-details-step-sub-title">
                        You’re all set to start listing items as a sharer on
                        RentMy!
                      </p>
                    </div>
                  </div>
                  {isOnboarding && (
                    <div className="flex-display-row additional-padding justify-content-center padding-top-10">
                      <Button
                        color="primary"
                        type="button"
                        outline
                        className="profile-section-button account-details-step-sub-button"
                        onClick={() => {
                          navigate("/account/profile");
                        }}
                      >
                        Skip
                      </Button>
                      <Button
                        color="primary"
                        disabled={!canBeSharer}
                        type="button"
                        outline
                        className="profile-section-button account-details-step-sub-button"
                        onClick={() => {
                          navigate(redirectLink);
                        }}
                      >
                        Continue
                      </Button>
                    </div>
                  )}
                </CardBody>
              </Collapse>
            </Card>
          )}
          {/*{!showVerificationOnly && user.intention === UserIntention.Renter && <Button onClick={() => updateUserIntention(UserIntention.Both)} className='show-more-button'>{"Become a Sharer!"}</Button>}*/}
        </>
      ) : (
        <LoadingFullSide />
      )}
    </>
  );
};
