import React from "react";

export const InputDescription = ({ text }: { text: string }) => (
  <p className="small top-padding-4">{text}</p>
);
export const ItalicDescription = ({ text }: { text: string }) => (
  <p className="small top-padding-4 text-align-center">
    <i>{text}</i>
  </p>
);
