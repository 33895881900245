export const HourlyClockArray: string[] = [
  "0:00am", "1:00am", "2:00am", "3:00am", "4:00am", "5:00am",
  "6:00am", "7:00am", "8:00am", "9:00am", "10:00am", "11:00am",
  "12:00pm", "1:00pm", "2:00pm", "3:00pm", "4:00pm", "5:00pm",
  "6:00pm", "7:00pm", "8:00pm", "9:00pm", "10:00pm", "11:00pm"
];

export const HourlyClockOptions = (): JSX.Element => {
  return (
    <>
      <option value="0">0:00am</option>
      <option value="1">1:00am</option>
      <option value="2">2:00am</option>
      <option value="3">3:00am</option>
      <option value="4">4:00am</option>
      <option value="5">5:00am</option>
      <option value="6">6:00am</option>
      <option value="7">7:00am</option>
      <option value="8">8:00am</option>
      <option value="9">9:00am</option>
      <option value="10">10:00am</option>
      <option value="11">11:00am</option>
      <option value="12">12:00pm</option>
      <option value="13">1:00pm</option>
      <option value="14">2:00pm</option>
      <option value="15">3:00pm</option>
      <option value="16">4:00pm</option>
      <option value="17">5:00pm</option>
      <option value="18">6:00pm</option>
      <option value="19">7:00pm</option>
      <option value="20">8:00pm</option>
      <option value="21">9:00pm</option>
      <option value="22">10:00pm</option>
      <option value="23">11:00pm</option>
    </>
  );
};

export const numberTo12HourTime = (num: number | null | undefined): string => {
  if (num === null || num === undefined || num < 0 || num > 23) {
    return "";
  }

  let suffix: string = (num < 12) ? "am" : "pm";

  return `${num}:00${suffix}`;
};
