// File: rentmy.frontend/src/components/OneWideImage.tsx

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Container } from "reactstrap";
import { useNavigate } from "react-router-dom";

export function OneWideImage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [textTitle, setTextTitle] = useState("");
  const [imagePath1, setImagePath1] = useState("");
  const [imageTitle1, setImageTitle1] = useState("");
  const [imageCaptions1, setImageCaptions1] = useState<string[]>([]);
  const [buttonTitle, setButtonTitle] = useState("");

  useEffect(() => {
    const cmsUrl = process.env.REACT_APP_CMS_URL!;
    const oneWideImagePath = process.env.REACT_APP_CMS_ONEWIDEIMAGE_PATH!;
    const absPath = cmsUrl + oneWideImagePath;

    axios
      .get(absPath)
      .then((res) => {
        const { sectionBackgroundColor } = res.data.fields;

        // Set CSS variable for background color
        document.documentElement.style.setProperty(
          "--oneWideImageSectionBackgroundColor",
          `#${sectionBackgroundColor}`
        );

        // Set component state with new static data
        setTextTitle("£10,000 cover on your items");
        setImagePath1("/assets/img/frontpage/1wide_img1.webp"); // Update if dynamic
        setImageTitle1("When sharing your items you want them to be safe, the RentMy Guarantee covers your items up to £10,000 from damage, loss or theft.");
        setImageCaptions1([
          "Maximise Earnings: Rent out your unused items with ease.",
          "Low Commissions: Keep more of your profits with our competitive rates.",
          "Enhanced Protection: Secure rentals with coverage up to £10,000 per item.",
        ]);
        setButtonTitle("Find Out More");
      })
      .catch((err) => {
        console.error("Error fetching OneWideImage data:", err);
        // Optionally set error state here
      });
  }, []);



  return (
    <section className="product-benefits one-wide-image-background">
      <Container>
        <div className="row align-items-center">
          <div className="col-12 col-md-6 mb-4 mb-md-0">
           <ul className="image-captions">
            <h2>{textTitle}</h2>
            <h3>{imageTitle1}</h3>
              {imageCaptions1.map((caption, index) => {
                const words = caption.split(" ");
                const firstTwoWords = words.slice(0, 2).join(" ");
                const remainingText = words.slice(2).join(" ");
                return (
                  <li key={index}>
                    <span className="caption-title">{firstTwoWords}</span>
                    {remainingText}
                  </li>
                );
              })}
            </ul>
            <a className="btn btn-secondary ml-15" href="guarantee/">
              {buttonTitle}
            </a>
          </div>
          {/* Image Content */}
          <div className="col-md-6 image-wrapper">
            <img
              src={imagePath1}
              alt={imageTitle1 || "Product Image"}
              className="responsive-image"
              loading="lazy"
            />
             <div className="overlay">
            Your rentals are protected
            </div>
           </div>
        </div>
      </Container>
    </section>
  );
}

export default OneWideImage;