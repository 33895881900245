import React, { useRef, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { categoryFileNames } from "../../constants/top-level-categories";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { setSelectedCategoryId } from "../../redux/searchSlice";

interface TopLevelCategoriesProps {}

const TopLevelCategories: React.FC<TopLevelCategoriesProps> = () => {
    const carouselRef = useRef<HTMLDivElement>(null);
    const { topLevelCategories, selectedCategoryId } = useSelector((state: RootState) => state.search);
    const dispatch = useDispatch<AppDispatch>();
    const [leftControlVisible, setLeftControlVisible] = useState(false);
    const [rightControlVisible, setRightControlVisible] = useState(true);

    const updateControlVisibility = () => {
        if (carouselRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
            setLeftControlVisible(scrollLeft > 0);
            setRightControlVisible(scrollLeft + clientWidth < scrollWidth);
        }
    };

    useEffect(() => {
        updateControlVisibility();
        if (carouselRef.current) {
            carouselRef.current.addEventListener('scroll', updateControlVisibility);
        }
        return () => {
            if (carouselRef.current) {
                carouselRef.current.removeEventListener('scroll', updateControlVisibility);
            }
        };
    }, []);

    const scroll = (direction: "left" | "right") => {
        if (carouselRef.current) {
            const { scrollLeft, clientWidth } = carouselRef.current;
            const scrollTo = direction === "left" ? scrollLeft - clientWidth : scrollLeft + clientWidth;
            carouselRef.current.scrollTo({ left: scrollTo, behavior: "smooth" });
        }
    };

    const getSvgPath = (categoryName: string) => {
        const filename = categoryFileNames[categoryName];
        return filename ? `/assets/svgs/categories/${filename}` : "/assets/svgs/categories/everything-1.svg";
    };

    return (
        <div className="top-level-category-carousel-container">
            <div className={`gradient-left ${leftControlVisible ? "visible" : "hidden"}`}></div>
            <div className={`carousel-control left ${leftControlVisible ? "visible" : "hidden"}`} onClick={() => scroll("left")}>
                <FontAwesomeIcon icon={faChevronLeft} />
            </div>
            <div className="top-level-category-carousel" ref={carouselRef}>
                {topLevelCategories.filter(category => category.name).map((category) => (
                    <div
                        key={category.id}
                        className={`category-item ${selectedCategoryId === category.id ? "selected" : ""}`}
                        onClick={(e) => {
                            e.preventDefault();
                            dispatch(setSelectedCategoryId(selectedCategoryId === category.id ? undefined : category.id));
                        }}
                    >
                        <div className="category-icon">
                            <img src={getSvgPath(category.name!)} alt={category.name} />
                        </div>
                        <span className="category-name">
              {category.shortName ?? category.name}
            </span>
                    </div>
                ))}
            </div>
            <div className={`gradient-right ${rightControlVisible ? "visible" : "hidden"}`}></div>
            <div className={`carousel-control right ${rightControlVisible ? "visible" : "hidden"}`} onClick={() => scroll("right")}>
                <FontAwesomeIcon icon={faChevronRight} />
            </div>
        </div>
    );
};

export default TopLevelCategories;
