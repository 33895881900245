import { Button, Card, CardBody, Col, Row } from "reactstrap";
import React, { useEffect, useState } from "react";

interface SaveOptionsProps {
  isEditing: boolean;
  postListing: (publish: boolean) => void;
  isPublished: boolean;
  t: (key: string) => string;
  isProcessing: boolean;
  setIsProcessing: (processing: boolean) => void;
}

export default function SaveOptions({
  isEditing,
  postListing,
  isPublished,
  t,
  isProcessing,
  setIsProcessing,
}: SaveOptionsProps) {
  const [publishClicked, setPublishClicked] = useState(false);
  const [unPublishClicked, setUnPublishClicked] = useState(false);

  useEffect(() => {
    if (!isEditing) {
      setPublishClicked(false);
      setUnPublishClicked(false);
    }
  }, [isProcessing]);

  return (
    <Card className="item-listing-card">
      <CardBody>
        {isEditing ? (
          <Row className="justify-content-center">
            <Col sm={3} className="item-listing-column">
              <Button
                className="full-width standard-font-weight"
                color="secondary"
                name="publish"
                type="button"
                onClick={() => {
                  setUnPublishClicked(true);
                  postListing(false);
                }}
              >
                {unPublishClicked && isProcessing ? (
                  <div className="loading-spinner-small"></div>
                ) : isPublished ? (
                  t("item_listing_unpublish")
                ) : (
                  t("item_listing_draft_update")
                )}
              </Button>
            </Col>
            <Col sm={3} className="item-listing-column">
              <Button
                className="full-width standard-font-weight"
                color="primary"
                name="publish"
                type="button"
                onClick={() => {
                  setPublishClicked(true);
                  postListing(true);
                }}
              >
                {publishClicked && isProcessing ? (
                  <div className="loading-spinner-small"></div>
                ) : isPublished ? (
                  t("item_listing_update")
                ) : (
                  t("item_listing_publish")
                )}
              </Button>
            </Col>
          </Row>
        ) : (
          <Row className={"justify-content-center"}>
            <Col sm={3} className="item-listing-column">
              <Button
                className="full-width standard-font-weight"
                color="secondary"
                name="save"
                type="button"
                onClick={() => {
                  setUnPublishClicked(true);
                  postListing(false);
                }}
              >
                {unPublishClicked && isProcessing ? (
                  <div className="loading-spinner-small"></div>
                ) : (
                  t("item_listing_save")
                )}
              </Button>
            </Col>
            <Col sm={3} className="item-listing-column">
              <Button
                className="full-width standard-font-weight"
                color="primary"
                name="publish"
                type="button"
                onClick={() => {
                  setPublishClicked(true);
                  postListing(true);
                }}
              >
                {publishClicked && isProcessing ? (
                  <div className="loading-spinner-small"></div>
                ) : (
                  t("item_listing_publish")
                )}
              </Button>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
}
