import { configureStore } from "@reduxjs/toolkit";
import searchReducer from "./searchSlice";
import { IConfig, SearchPageClient } from "../api/rentMyApi";

const searchPageClient = new SearchPageClient(new IConfig("notoken"), process.env.REACT_APP_API_ENDPOINT);

export const store = configureStore({
  reducer: {
    search: searchReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: { searchPageClient }
      }
    })
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;