import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

interface StarRatingSelectProps {
  numberOfStars: number;
  value: number;
  onClick: (value: number) => void;
}

export default function StarRatingSelect({
  numberOfStars,
  value,
  onClick,
}: StarRatingSelectProps) {
  return (
    <div className="rating-css ">
      {Array(numberOfStars)
        .fill(null)
        .map((x, i) => (
          <FontAwesomeIcon
            key={i}
            icon={faStar}
            onClick={() => onClick(i + 1)}
            className={i + 1 <= value ? "star-active" : "star-inactive"}
          />
        ))}
    </div>
  );
}
