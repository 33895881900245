import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row } from "reactstrap";

export default function MultiLayeredCategoriesSelectorClickableRow({
  category,
  loading,
  handleCategoryClick,
  shouldShowToggle,
  shouldCheckboxBeSelected,
  pathInsteadOfName,
}: {
  category: any;
  loading: boolean;
  handleCategoryClick: any;
  shouldShowToggle: boolean;
  shouldCheckboxBeSelected: any;
  pathInsteadOfName?: boolean;
}) {
  return (
    <Row
      key={"category_selection_" + category.id}
      className={loading ? "item-row loading-cursor" : "item-row"}
      onClick={handleCategoryClick}
    >
      {/* <img className="avatar row-avatar" alt="category_icon" src={categoryIconSrc} /> */}
      {/*<div className="avatar row-avatar category-background-colour"/>*/}
      <div className="btn-inner--icon" />
      {pathInsteadOfName && <p>{category.path}</p>}
      {!pathInsteadOfName && <p>{category.name}</p>}
      {shouldShowToggle && (
        <div className="right-align">
          <label className="custom-toggle">
            <input
              className="custom-control-input"
              id={category.id}
              type="checkbox"
              checked={shouldCheckboxBeSelected({
                id: category.id,
                name: category.name,
              })}
              onChange={(e) => e.preventDefault()}
            />
            <span className="custom-toggle" />
          </label>
        </div>
      )}
      {!shouldShowToggle && (
        <FontAwesomeIcon className="right-align" icon={faChevronRight} />
      )}
    </Row>
  );
}
