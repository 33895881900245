import * as React from "react";

export function LoadingFullScreen(props) {
  return (
    <div className="loading-spinner-fullscreen text-align-center"></div>
  );
}

export function LoadingFullSide(props) {
  return (
    <div className="loading-spinner-side"></div>
  );
}