import { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import AccountPagesSidebar from "./AccountPagesSidebar";
import ItemSnippet from "../common/ItemSnippet";
import { LoadingFullScreen } from "../common/LoadingFullScreen";

import {
  ConversationDto,
  IConfig,
  MessageClient,
  MessageSimpleDto,
  RentMyUserPublicSimpleDto,
} from "../../api/rentMyApi";
import { useEnv } from "../../context/env.context";
import { subjectInUserReadableFormat } from "../common/MessageUrlFormatting";
import { useUserContext } from "../../context/UserContext";

export function AccountConversations() {
  const { t } = useTranslation();
  const { apiServerUrl } = useEnv();
  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();
  const [dataLoaded, setDataLoaded] = useState(false);

  const [allConversations, setAllConversations] = useState<ConversationDto[]>();

  const { user } = useUserContext();

  const messageCardId = "message-card";

  useEffect(() => {
    const fetchConversations = async () => {
      const token = await getAccessTokenSilently();
      const messageClient: MessageClient = new MessageClient(
        new IConfig(token),
        process.env.REACT_APP_API_ENDPOINT
      );
      try {
        const data = await messageClient.conversations();
        const filteredMessages = data.filter(
          (conversationMessages) => conversationMessages.users?.length
        );
        setAllConversations(filteredMessages);
      } catch (error) {
        toast.error(t("account_conversations_loading_error"));
      } finally {
        setDataLoaded(true);
      }
    };
    fetchConversations();
  }, []);

  const shouldNewMessageBeShown = (lastMessage: MessageSimpleDto): boolean => {
    return (
      lastMessage.recipient.id === user.id &&
      (lastMessage.dateRead === null || lastMessage.dateRead === undefined)
    );
  };

  const openSpecificConversation = (
    subject: string | undefined,
    otherParticipantId: string | undefined
  ) => {
    navigate(`/account/messages/${subject}/${otherParticipantId}`);
  };

  return (
    <Container className="mt-4 account-messages account-settings-container">
      <Row>
        <Col className="hide-on-mobile hide-on-tablet" md={4} lg={4} xl={3}>
          <AccountPagesSidebar />
        </Col>
        <Col md={8} lg={8} xl={9} className="auto-margin">
          <Card>
            {!dataLoaded && <LoadingFullScreen />}
            <CardBody id={messageCardId}>
              {dataLoaded && (
                <>
                  <h2 className="mb-3">{t("account_conversations_title")}</h2>
                  <hr className="mt-3 mb-3" />
                  {allConversations! && allConversations!.length === 0 && (
                    <p className="text-align-center">
                      You do not currently have any conversations
                    </p>
                  )}
                  {allConversations! &&
                    allConversations
                      ?.sort((a, b) =>
                        moment(a.lastMessage.dateSent).isBefore(
                          moment(b.lastMessage.dateSent)
                        )
                          ? 1
                          : moment(a.lastMessage.dateSent).isAfter(
                              moment(b.lastMessage.dateSent)
                            )
                          ? -1
                          : 0
                      )
                      .map((conversation: ConversationDto, index: number) => {
                        const otherParticipantUser:
                          | RentMyUserPublicSimpleDto
                          | undefined =
                          conversation.users && conversation.users[0];

                        if (!otherParticipantUser) {
                          // console.error("This conversation '" + conversation.subject + "' has no other participant attached to it. This conversation will not be shown on the application");
                          return <div key={"all_conversations_" + index}></div>;
                        }

                        const conversationUserProfilePic = otherParticipantUser?.profileImage?.compressedPath
                          ? otherParticipantUser.profileImage.compressedPath
                          : "assets/img/profile-pic-placeholder.webp";
                        const otherParticipantId: string | undefined =
                          otherParticipantUser?.id
                            ? otherParticipantUser?.id
                            : undefined;
                        const lastMessage = conversation.lastMessage;

                        return (
                          <div key={"all_conversations_" + index}>
                            <ItemSnippet
                              wantInCard={false}
                              icon={{
                                userSetup: {
                                  userProfilePicSrc: conversationUserProfilePic,
                                  userId: otherParticipantId,
                                },
                              }}
                              text={{
                                title: subjectInUserReadableFormat(
                                  lastMessage.subject ?? "",
                                  otherParticipantUser.name
                                ),
                                text: lastMessage.content ?? "",
                                secondaryText:
                                  (shouldNewMessageBeShown(
                                    conversation.lastMessage
                                  )
                                    ? t("account_conversations_new_message") +
                                      " "
                                    : "") +
                                  moment(lastMessage.dateSent).format(
                                    "DD MMM YYYY, h:mm"
                                  ),
                                wantSecondaryTextToBeBold:
                                  shouldNewMessageBeShown(
                                    conversation.lastMessage
                                  ),
                              }}
                              buttonSetup={{
                                text: "Open",
                                onClick: () => {
                                  openSpecificConversation(
                                    conversation.subject,
                                    conversation?.users?.[0]?.id
                                  );
                                },
                                buttonOutline: false,
                              }}
                              onInfoSectionClick={() => {
                                openSpecificConversation(
                                  conversation.subject,
                                  conversation?.users?.[0]?.id
                                );
                              }}
                            />
                            <hr className="mt-3 mb-3" />
                          </div>
                        );
                      })}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
