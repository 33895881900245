import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import React, {useState, useMemo, useEffect} from "react";
import { LoadingFullScreen } from "../common/LoadingFullScreen";
import { useUserContext } from "../../context/UserContext";
import { Container } from "reactstrap";
import {OnboardingStep, OnboardingStepper} from "../onboarding/OnboardingStepper";
import { EmailVerificationRequired } from "../common/email-verification/EmailVerificationRequired";

type OnboardingRequiredProps = {
    Component: React.ComponentType<any>;
    requiresAuthentication: boolean;
    requiresVerification: boolean;
    requiresBeingSharer: boolean;
    requiresEmailVerification: boolean;
    requiresOnboarding: boolean;
};


const OnboardingComponent = ({ setOnboardingCompleted, activeStep }: { setOnboardingCompleted: React.Dispatch<React.SetStateAction<boolean>>, activeStep: OnboardingStep }) => (
    <Container>
        <OnboardingStepper setOnboardingCompleted={setOnboardingCompleted} initialActiveStep={activeStep} />
    </Container>
);

const SharerOnboardingComponent = ({ showSharerOnboarding, setOnboardingCompleted, activeStep }: { showSharerOnboarding: boolean, setOnboardingCompleted: React.Dispatch<React.SetStateAction<boolean>>, activeStep: OnboardingStep }) => (
    <Container>
        <OnboardingStepper showSharerOnboarding={showSharerOnboarding} setOnboardingCompleted={setOnboardingCompleted} initialActiveStep={activeStep} />
    </Container>
)


const EmailVerificationRequiredComponent = () => (
    <Container>
        <EmailVerificationRequired />
    </Container>
)

const shouldShowOnboarding = (requiresOnboarding: boolean, isVerified: boolean, requiresBeingSharer: boolean, userCanBeSharer: boolean) => {
    console.log("Requires onboarding: " + requiresOnboarding)
    console.log("isVerified: " + isVerified)
    console.log("Requires being sharer: " + requiresBeingSharer)
    console.log("User can be sharer: " + userCanBeSharer)
    return (requiresOnboarding && !isVerified) || (requiresBeingSharer && !userCanBeSharer);
};

export const OnboardingRequired: React.FC<OnboardingRequiredProps> = React.memo((props) => {



    const {
        Component,
        requiresAuthentication,
        requiresVerification,
        requiresBeingSharer,
        requiresEmailVerification,
        requiresOnboarding,
        ...args
    } = props;
    const [initialActiveStepLoading, setInitialActiveStepLoading] = useState(true);
    const [onboardingCompleted, setOnboardingCompleted] = useState(true);
    const [onboardingStatusChecked, setOnboardingStatusChecked] = useState(false);
    const [initialActiveStep, setInitialActiveStep] = useState<OnboardingStep | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const { isAuthenticated, isLoading: isLoadingAuth, user: auth0User } = useAuth0();
    const { user, hasAcceptedTerms, hasSetTermsStatus } = useUserContext();

    const checkPersonalInformation = () => (
        !user.firstName ||
        !user.lastName ||
        !user.phoneNumber ||
        !user.gender ||
        !user.intention ||
        !hasAcceptedTerms
    );

    const checkAddressInformation = () => (
        !user.location?.addressLine1 ||
        !user.location?.city ||
        !user.location?.country ||
        !user.location?.postcode
    );

    const determineActiveStep = () => {
        if (checkPersonalInformation()) return OnboardingStep.PersonalInformation;
        if (checkAddressInformation()) return OnboardingStep.AddressInformation;
        if (!user.isVerified) return OnboardingStep.StripeIdVerification;
        if (!requiresBeingSharer && user.isVerified) return OnboardingStep.OnboardingCompleted;
        if (requiresBeingSharer) {
            if (user.canBeSharer) return OnboardingStep.OnboardingCompleted;
            if (user.stripeOnboarded) return OnboardingStep.BankDetails;
            if (user.isVerified) return OnboardingStep.StripeAddressVerification;
        }
        return OnboardingStep.OnboardingCompleted;
    };


    useEffect(() => {
        if (!user || !user.id) return
        const _initialActiveStep = determineActiveStep()
        console.log("Initial active step: " + _initialActiveStep)
        console.log("user")
        console.log(user)
        setInitialActiveStep(_initialActiveStep);
        setInitialActiveStepLoading(false);
    }, [user, isLoadingAuth, hasSetTermsStatus]);


    useEffect(() => {
        if (initialActiveStepLoading) return;
        setOnboardingCompleted(initialActiveStep === OnboardingStep.OnboardingCompleted)
        setOnboardingStatusChecked(true);
    }, [initialActiveStepLoading, initialActiveStep]);

    useEffect(() => {
        if (!onboardingStatusChecked) return;
        setIsLoading(false);
    }, [onboardingStatusChecked]);

    const MemoizedAuthenticatedComponent = useMemo(
        () =>
            withAuthenticationRequired(Component, {
                onRedirecting: () => <LoadingFullScreen />,
            }),
        [Component]
    );

    if (isLoading || isLoadingAuth) {
        if (!isLoadingAuth && isAuthenticated && requiresEmailVerification && !auth0User?.email_verified) {
            console.log("Requires email verification and user isn't verified")
            return <EmailVerificationRequiredComponent />;
        }
        if (!isLoadingAuth && !isAuthenticated && requiresEmailVerification && !auth0User?.email_verified) {
            return <MemoizedAuthenticatedComponent {...args} />;
        }
        console.log("Still loading")
        return <LoadingFullScreen />;
    }

    if (!requiresAuthentication) {
        console.log("Doesn't require authentication")
        return <Component {...args} />;
    }

    if (!isAuthenticated) {
        console.log("User is not authenticated")
        return <MemoizedAuthenticatedComponent {...args} />;
    }

    if (requiresEmailVerification && !auth0User?.email_verified) {
        console.log("Requires email verification and user isn't verified")
        return <EmailVerificationRequiredComponent />;
    }

    if (requiresVerification && !user.isVerified && initialActiveStep) {
        console.log("Requires verification and user isn't verified")
        return <OnboardingComponent setOnboardingCompleted={setOnboardingCompleted} activeStep={initialActiveStep}/>;
    }

    // Check hasAcceptedTerms only if requiresOnboarding is true
    if (requiresOnboarding && !hasAcceptedTerms && initialActiveStep) {
        console.log("Requires onboarding and user hasn't accepted terms")
        return <OnboardingComponent setOnboardingCompleted={setOnboardingCompleted} activeStep={initialActiveStep}/>;
    }

    if (!user.canBeSharer && requiresBeingSharer && initialActiveStep) {
        console.log("Should show onboarding is true")
        return <SharerOnboardingComponent showSharerOnboarding={requiresBeingSharer} setOnboardingCompleted={setOnboardingCompleted} activeStep={initialActiveStep}/>;
    }

    if (!user.isVerified && requiresVerification || !user.canBeSharer && requiresBeingSharer) {
        return requiresBeingSharer ? <SharerOnboardingComponent showSharerOnboarding={requiresBeingSharer} setOnboardingCompleted={setOnboardingCompleted} activeStep={determineActiveStep()}/> : <OnboardingComponent setOnboardingCompleted={setOnboardingCompleted} activeStep={determineActiveStep()}/>;
    }

    return <Component {...args} />;
});
