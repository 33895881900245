import { Button, Card, CardBody, Col, FormGroup, Input, Row } from "reactstrap";
import React from "react";
import { AspectSimpleDto } from "../../api/rentMyApi";

interface AspectSectionGenerationProps {
  defaultOptionValue: string;
  showMoreAspects: boolean;
  allAspectsFromCategory: AspectSimpleDto[];
  lengthBeforeAspectCutOff: number;
  setShowMoreAspects: (showMore: boolean) => void;
  t: (key: string) => string; // Assuming t is a translation function
  aspectsInItemFormat: Record<string, string[]>; // Assuming based on how it's used
  setAspectsInItemFormat: (aspects: Record<string, string[]>) => void;
  category: any; // The type of 'category' wasn't demonstrated in the code provided
}
export default function AspectSectionGeneration({
  defaultOptionValue,
  showMoreAspects,
  allAspectsFromCategory,
  lengthBeforeAspectCutOff,
  setShowMoreAspects,
  t,
  aspectsInItemFormat,
  setAspectsInItemFormat,
  category,
}: AspectSectionGenerationProps) {
  const stripAndOrderAspects = (): AspectSimpleDto[] => {
    if (!allAspectsFromCategory) {
      return [];
    }

    const allAspectsClone = [...allAspectsFromCategory];
    allAspectsClone.sort((a, b) => a.name!.localeCompare(b.name!));

    if (!showMoreAspects) {
      allAspectsClone.length = lengthBeforeAspectCutOff;
    }

    return allAspectsClone;
  };

  const aspectSelectOnChange = (
    aspectName: string,
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setAspectsInItemFormat({
      ...aspectsInItemFormat,
      [aspectName]: [e.target.value],
    });
  };

  const aspectSelectOnChange2 = (aspectName: string): string => {
    return aspectsInItemFormat[aspectName]
      ? aspectsInItemFormat[aspectName][0]
      : defaultOptionValue;
  };

  return (
    <Card className="item-listing-card">
      <CardBody>
        <h2>Add additional details</h2>
        <Row>
          {stripAndOrderAspects().map((aspect) => {
            return (
              <Col
                key={"aspect_selection_form_group_" + aspect.name}
                xs={12}
                md={6}
                lg={3}
              >
                <FormGroup>
                  <label
                    htmlFor={"aspect_selection_form_control_" + aspect.name}
                  >
                    {aspect.name}
                  </label>
                  <Input
                    id={"aspect_selection_form_control_" + aspect.name}
                    className="form-control input-field"
                    type="select"
                    onChange={(e) => aspectSelectOnChange(aspect.name!, e)}
                    value={aspectSelectOnChange2(aspect.name!)}
                  >
                    <option
                      key={
                        "aspect_selection_dropdown_" + aspect.name + "_default"
                      }
                    >
                      {defaultOptionValue}
                    </option>

                    {!aspect.values && <h1>No aspects on this category</h1>}

                    {aspect.values &&
                      aspect.values.map((aspectValue, index) => {
                        return (
                          <option
                            key={
                              "aspect_selection_dropdown_" +
                              aspect.name +
                              "_" +
                              index
                            }
                          >
                            {aspectValue}
                          </option>
                        );
                      })}
                  </Input>
                </FormGroup>
              </Col>
            );
          })}

          {!showMoreAspects &&
            allAspectsFromCategory &&
            allAspectsFromCategory.length > lengthBeforeAspectCutOff && (
              <Col xs={12}>
                <Button
                  className="item-listing-button show-more-button"
                  color="primary"
                  type="button"
                  onClick={() => setShowMoreAspects(true)}
                >
                  {t("show_more")}
                </Button>
              </Col>
            )}
          {showMoreAspects && (
            <Col xs={12}>
              <Button
                className="item-listing-button show-more-button"
                color="primary"
                type="button"
                onClick={() => setShowMoreAspects(false)}
              >
                Show Less
              </Button>
            </Col>
          )}
        </Row>
      </CardBody>
    </Card>
  );
}
