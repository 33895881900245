import MultiSelectImagePreview from "./MultiSelectImagePreview";
import LocalImageUploadManagement from "./LocalImageUploadManagement";
import { Card, CardBody } from "reactstrap";
import React, { useState } from "react";
import { ImageDto } from "../../api/rentMyApi";

interface UploadProductImageProps {
  uploadedImages: ImageDto[];
  setUploadedImages: (images: ImageDto[]) => void;
  setIsProcessing: (isProcessing: boolean) => void;
  isProcessing: boolean;
  newImagesOnly?: boolean;
}

export default function UploadProductImage({
  uploadedImages,
  setUploadedImages,
  setIsProcessing,
  isProcessing,
  newImagesOnly = false,
}: UploadProductImageProps) {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  return (
    <Card className="item-listing-card">
      <CardBody>
        <LocalImageUploadManagement
          uploadedImages={uploadedImages}
          setUploadedImages={setUploadedImages}
          setIsProcessing={setIsProcessing}
          selectedImageIndex={selectedImageIndex}
          isProcessing={isProcessing}
          newImagesOnly={newImagesOnly}
        />
        {uploadedImages.length > 1 && (
          <MultiSelectImagePreview
            imagesPreviewUrls={uploadedImages}
            setImagePreviewUrls={setUploadedImages}
            setSelectedImageIndex={setSelectedImageIndex}
            selectedImageIndex={selectedImageIndex}
            setUploadedImages={setUploadedImages} // Add this line to pass the prop
          />
        )}
      </CardBody>
    </Card>
  );
}
