import * as React from "react";
import { useEffect, useState } from "react";
import { IConfig, UserClient, WalletDto } from "../../../api/rentMyApi";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { useEnv } from "../../../context/env.context";

interface WalletProps {
  setPayoutModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export const Wallet = ({ setPayoutModalOpen }: WalletProps) => {
  const [walletData, setWalletData] = useState(new WalletDto());
  const [isLoading, setIsLoading] = useState({
    lifetimeEarnings: true,
    totalBalance: false,
    arrivingSoon: false,
    payoutAvailable: true,
  });
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();

  async function handleWithdraw() {
    const token = await getAccessTokenSilently();
    const response = await new UserClient(
      new IConfig(token),
      process.env.REACT_APP_API_ENDPOINT
    ).payout();
    setPayoutModalOpen(true);
  }

  useEffect(() => {
    const fetchWalletData = async () => {
      const token = await getAccessTokenSilently();
      const response = await new UserClient(
        new IConfig(token),
        process.env.REACT_APP_API_ENDPOINT
      ).wallet();
      setWalletData(response);
      setIsLoading({
        lifetimeEarnings: false,
        totalBalance: false,
        arrivingSoon: false,
        payoutAvailable: false,
      });
    };

    fetchWalletData();
  }, [getAccessTokenSilently]);

  const walletColumns = [
    {
      title: "Lifetime Earnings",
      value: `£${walletData.lifetimeEarnings}`,
      disabled: walletData.lifetimeEarnings === 0,
      isLoading: isLoading.lifetimeEarnings,
    },
    {
      title: "Payout Available",
      value: `£${walletData.payoutAvailable}`,
      disabled: walletData.payoutAvailable === 0,
      isLoading: isLoading.payoutAvailable,
    },
  ];

  return (
    <Col lg={12} className="wallet">
      <Card>
        <CardBody>
          <div className="flexy mb-3 header-section">
            <h2>My Wallet</h2>
          </div>
          <Row className="justify-content-center">
            {walletColumns.map((column, index) => (
              <Col key={index} lg={3} xs={6} className="text-center">
                <p>{column.title}</p>
                <div className="fixed-height-div">
                  {column.isLoading ? (
                    <div className="full-width text-align-center padding-5">
                      <div className="loading-spinner-small"></div>
                    </div>
                  ) : (
                    <h1
                      className={`${column.disabled ? "disabled" : ""} ${
                        !column.isLoading ? "fade-in" : ""
                      }`}
                    >
                      {column.value}
                    </h1>
                  )}
                </div>
              </Col>
            ))}
          </Row>
          <Row className="button-row justify-content-center mt-4">
            <Button
              color="primary"
              className={
                isLoading.payoutAvailable || walletData.payoutAvailable === 0
                  ? "button-disabled"
                  : ""
              }
              onClick={handleWithdraw}
              disabled={
                isLoading.payoutAvailable || walletData.payoutAvailable === 0
              }
            >
              Withdraw
            </Button>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};