import {useState, ReactNode, useEffect} from "react";
import { useTranslation } from "react-i18next";
import { SxProps, Theme, Box, Typography, FormControlLabel, Checkbox } from "@mui/material";
import { IConfig, UserClient } from "../../api/rentMyApi";
import { useEnv } from "../../context/env.context";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useUserContext } from "../../context/UserContext";
import { useLocation } from "react-router-dom";

type StripeIdVerificationStepProps = {
  backButton: ReactNode;
  handleNextStep: () => void;
  headingStyle: SxProps<Theme>;
  stepSummaryStyle: SxProps<Theme>;
};

interface FormValues {
  hasAcceptedTerms: boolean;
}

const initialFormState: FormValues = {
    hasAcceptedTerms: false,
};

interface FormErrors {
    hasAcceptedTerms: string;
}

const initialFormErrors: FormErrors = {
    hasAcceptedTerms: '',
};

export function StripeIdVerificationStep({
  backButton,
  handleNextStep,
  headingStyle,
  stepSummaryStyle,
}: StripeIdVerificationStepProps) {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const { user, hasAcceptedTerms } = useUserContext();
  const location = useLocation();
  const { pathname } = location;
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>(initialFormState);
  const [formErrors, setFormErrors] = useState<FormErrors>(initialFormErrors);

  const verifyUserId = async () => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    const userClient = new UserClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT);
    const successfulStripeVerificationUrl =
       window.location.href;

      userClient!
      .verification(successfulStripeVerificationUrl)
      .then((accountLink) => {
        window.location.href = accountLink!;
      })
      .catch((e) => {
        console.log(e);
        toast.error(
          "We were unable to verify your account with Stripe, please contact support"
        );
        setIsLoading(false);
      });
  };

    // useEffect which sets hasAcceptedStripeTerms to true if user.stripeOnboarded is true
    useEffect(() => {
        if (hasAcceptedTerms || user.isVerified) {
            setFormValues((previousValues) => ({ ...previousValues, hasAcceptedStripeTerms: true }));
        }
    }, [hasAcceptedTerms, user]);

    const handleAcceptTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormValues((previousValues) => ({ ...previousValues, hasAcceptedTerms: event.target.checked }));
    };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography component="h1" sx={headingStyle}>
        {t("onboarding_id_verification_with_stripe")}
      </Typography>
      {backButton}
      {user.isVerified && (
        <Typography variant="body1" sx={{pt: 1, pb: 0}}>
                    {t("onboarding_verification_completed_renter")}
        </Typography>
      )}
        <Typography variant="body1" sx={{pt: 1, pb: 0}}>
            By getting verified you agree to Stripe’s {' '}
            <a href="https://stripe.com/GB/legal/connect-account" target='_blank'>
                Connected Account Agreement
            </a>
            , and you certify that the information you have provided is complete and correct. Stripe’s payment
            services provider may obtain information from credit agencies to verify your identity. This information
            will be used for the purposes described in their {' '}
            <a href="https://stripe.com/legal/privacy-center" target="_blank">
                Privacy Policy
            </a>
        </Typography>
        <FormControlLabel
            sx={{ pt: 3, pb: 0 }}
            control={
                <Checkbox
                    checked={formValues.hasAcceptedTerms}
                    onChange={handleAcceptTermsChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    size="small"
                />
            }
            label={
                <Typography variant="body1">
                    {t('terms_of_service_accept_text')}{' '}
                    <a href="/terms" target="_blank">
                        {t('terms_of_service')}
                    </a>
                </Typography>
            }
        />
      <button
        type="button"
        onClick={user.isVerified ? handleNextStep : verifyUserId}
        disabled={isLoading || !formValues.hasAcceptedTerms}
        className="btn btn-primary onboarding-button"
      >
        {isLoading && (
          <span className="btn-inner--icon">
            <FontAwesomeIcon icon={faSpinner} spin/>
          </span>
        )}
        {user.isVerified ? t("onboarding_next") : t("onboarding_get_verified")}
      </button>
    </Box>
  );
}
