export const parsePath = (path: string): { what: string, where: string } => {
    console.log("Parsing path: " + path)
    // Split the path by '/'
    const parts = path.split('/');

    // The second part is 'what' and the third part is 'where'
    const what = parts[1] === 'rent' ? parts[2] : ""
    if (!parts[3]) return {
        what: (what === 'all' ? "" : what),
        where: ""
    }
    // Decode the 'where' part to replace '%20' with spaces
    let where = decodeURIComponent(parts[3]) ?? "";
    // replaces dashes - with spaces
    where = where.replace(/-/g, ' ');
    if (!where) {
        return { what: (what === 'all' ? "" : what), where: "" };
    }

    // Return the object
    return { what: (what === 'all' ? "" : what), where: where ? where : "" };
}
