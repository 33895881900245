import React from "react";

interface ToggleProps {
  text: string;
  size?: string;
  checked: boolean;
  disabled: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  offStyle?: string;
  onStyle?: string;
}

function Toggle({
  text,
  size = "default",
  checked,
  disabled,
  onChange,
  offStyle = "btn-danger",
  onStyle = "btn-success",
}: ToggleProps) {
  const displayStyle = checked ? onStyle : offStyle;
  return (
    <>
      <label>
        <span className={`${size} switch-wrapper`}>
          <input
            type="checkbox"
            checked={checked}
            disabled={disabled}
            onChange={(e) => onChange(e)}
          />
          <span className={`${displayStyle} switch`}>
            <span className="switch-handle" />
          </span>
        </span>
        <span className="switch-label">{text}</span>
      </label>
    </>
  );
}

export default Toggle;
