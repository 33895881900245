// use this component on pages
// with google maps already initialized

interface Coordinates {
  latitude: number | undefined;
  longitude: number | undefined;
}

function GetCoordinatesFromAddress(query: string): Promise<Coordinates> {
  if (!window.google && !window.google.maps) {
    // TODO: handle initialization
    throw new Error("Google maps not initialized");
  }
  const el = document.createElement("div");
  const map = new window.google.maps.Map(el);

  const request = {
    query: query,
    fields: ["name", "geometry"],
  };

  const service = new window.google.maps.places.PlacesService(map);

  const promise = new Promise<Coordinates>((resolve) => {
    service.findPlaceFromQuery(request, function (results, status) {
      if (
        status === window.google.maps.places.PlacesServiceStatus.OK &&
        results
      ) {
        const topResult = results[0];
        const coordinates = {
          latitude: topResult.geometry?.location?.lat(),
          longitude: topResult.geometry?.location?.lng(),
        };
        resolve(coordinates);
      } else {
        resolve({
          latitude: undefined,
          longitude: undefined,
        });
      }
    });
  });

  return promise;
}

export default GetCoordinatesFromAddress;
