import { useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Collapse,
  FormFeedback,
  FormGroup,
  Input,
  Row,
} from "reactstrap";

import CategoryIcon from "../../icons/category-icon";
import Typewriter from "../../openai/Typewritter";

interface WritingStyleSelectProps {
  writingStyle: string;
  setWritingStyle: (style: string) => void;
}

const WritingStyleSelect = ({
  writingStyle,
  setWritingStyle,
}: WritingStyleSelectProps) => (
  <Col sm={12} className="style-container-ai ai-styling-column">
    <ButtonGroup className="ai-writing-style">
      {["creative", "professional", "funny"].map((style) => (
        <Button
          key={style}
          color={"black"}
          outline
          onClick={() => setWritingStyle(style)}
          active={writingStyle === style}
          className={
            "ai-btn-style" +
            (style === "professional" ? " middle-ai-style" : " side-ai-style") +
            (writingStyle === style ? " ai-btn-style-active" : "")
          }
        >
          {style.charAt(0).toUpperCase() + style.slice(1)}
        </Button>
      ))}
    </ButtonGroup>
  </Col>
);

interface AiBriefDescriptionProps {
  errors: any; // Use a more specific type for errors
  aiBrief: string;
  setAiBrief: (brief: string) => void;
  t: (key: string) => string; // Assuming 't' is a translation function, adjust if necessary
}

const AiBriefDescription = ({
  errors,
  aiBrief,
  setAiBrief,
  t,
}: AiBriefDescriptionProps) => {
  return (
    <FormGroup id="ai-description" className="d-flex flex-wrap">
      <Col sm={12} md={10} className="padding-5">
        <Input
          invalid={errors.name}
          id="ai-description-input input-ai"
          type="text"
          value={aiBrief}
          onChange={(e) => {
            setAiBrief(e.target.value);
          }}
          placeholder={t("item_listing_ai_brief_placeholder")}
          maxLength={150}
          required
          className="ai-description-input full-width input-field"
        />
      </Col>
      <Col sm={12} md={2} className="padding-5 full-width"></Col>
    </FormGroup>
  );
};

interface AiAssistantInputFieldsProps {
  useAi: boolean;
  writingStyle: string;
  setWritingStyle: (style: string) => void;
  aiBrief: string;
  setAiBrief: (brief: string) => void;
  errors: any; // Define more specific type
  t: (key: string) => string;
}

const AiAssistantInputFields = ({
  useAi,
  writingStyle,
  setWritingStyle,
  aiBrief,
  setAiBrief,
  errors,
  t,
}: AiAssistantInputFieldsProps) => {
  return (
    <Collapse isOpen={useAi} className="auto-margin full-width">
      <WritingStyleSelect
        setWritingStyle={setWritingStyle}
        writingStyle={writingStyle}
      />
      <AiBriefDescription
        setAiBrief={setAiBrief}
        t={t}
        aiBrief={aiBrief}
        errors={errors}
      />
    </Collapse>
  );
};

interface TitleInputProps {
  t: (key: string) => string;
  setName: (name: string) => void;
  name: string;
  errors: any; // Define more specific type
  setErrors: (errors: any) => void; // Define more specific type
  titleMaxLength: number;
}

const TitleInput = ({
  t,
  setName,
  name,
  errors,
  setErrors,
  titleMaxLength,
}: TitleInputProps) => (
  <Col sm={12}>
    <FormGroup id="title-section">
      <label htmlFor="item-listing-title">{t("item_listing_title")}</label>
      <Input
        invalid={errors.name}
        id="item-listing-title"
        type="text"
        value={name}
        onChange={(e) => {
          setName(e.target.value);
          setErrors({
            ...errors,
            name: false,
          });
        }}
        placeholder="Give your item a title!"
        maxLength={titleMaxLength}
        required
        className="input-field"
      />
      {errors.name && (
        <FormFeedback>{t("item_listing_title_required")}</FormFeedback>
      )}
    </FormGroup>
  </Col>
);

interface DescriptionSectionProps {
  t: (key: string) => string;
  errors: any; // Define more specific type
  setDescription: (description: string) => void;
  description: string;
  triggerKey: string; // Assuming this is a string, adjust if necessary
  setTyping: (typing: boolean) => void;
  typing: boolean;
  setAiTextComplete: (complete: boolean) => void;
}

const DescriptionSection = ({
  t,
  errors,
  setDescription,
  description,
  triggerKey,
  setTyping,
  typing,
  setAiTextComplete,
}: DescriptionSectionProps) => (
  <Col sm={12}>
    <FormGroup id="description-section">
      <label htmlFor="item-listing-description">
        {t("item_listing_describe")}
      </label>
      <Typewriter
        errors={errors}
        key={triggerKey}
        text={description}
        typingSpeed={10}
        onTextChange={setDescription}
        setTypingComplete={setAiTextComplete}
        setTyping={setTyping}
        typing={typing}
        description={description}
        resetTyping={false}
      />
      {errors.description && (
        <FormFeedback>{t("item_listing_description_required")}</FormFeedback>
      )}
    </FormGroup>
  </Col>
);

interface CategorySelectProps {
  category: any[]; // Define more specific type, like an array of a category interface
  setCategoryModalState: (state: boolean) => void;
  t: (key: string) => string;
  categoryError: boolean;
}

const CategorySelect = ({
  category,
  setCategoryModalState,
  t,
  categoryError,
}: CategorySelectProps) => {
  return (
    <Col sm={12} id="category-selector">
      <h2>{category.length > 0 ? "Category" : t("select_a_category")}</h2>
      {category.length > 0 && <p>{category[0].name}</p>}
      <div className="flex-display-row">
        <div className="icon-in-circle icon-in-circle-default">
          <CategoryIcon />
        </div>
        <Button
          className="item-snippet-button small-button"
          color={categoryError ? "danger" : "secondary"} // Highlight the button if categoryError is true
          size="sm"
          onClick={() => setCategoryModalState(true)}
          type="button"
        >
          {category.length > 0 ? t("update") : t("select")}
        </Button>
      </div>
    </Col>
  );
};

interface AboutYourListingProps {
  name: string;
  setName: (name: string) => void;
  errors: any; // Define more specific type
  setErrors: (errors: any) => void; // Define more specific type
  description: string;
  setDescription: (description: string) => void;
  t: (key: string) => string;
  setCategoryModalState: (state: boolean) => void;
  category: any[]; // Define more specific type
  triggerKey: string;
  setTyping: (typing: boolean) => void;
  typing: boolean;
}

export default function AboutYourListing({
  name,
  setName,
  errors,
  setErrors,
  description,
  setDescription,
  t,
  setCategoryModalState,
  category,
  triggerKey,
  setTyping,
  typing,
}: AboutYourListingProps) {
  const [useAi, setUseAi] = useState(false);
  const [aiTextComplete, setAiTextComplete] = useState(false);
  const [aiBrief, setAiBrief] = useState("");
  const [writingStyle, setWritingStyle] = useState("professional");
  const titleMaxLength = 75;

  return (
    <Card className="item-listing-card">
      <CardBody>
        <h2>{t("item_listing_about")}</h2>
        <Row>
          <TitleInput
            errors={errors}
            setErrors={setErrors}
            t={t}
            titleMaxLength={titleMaxLength}
            name={name}
            setName={setName}
          />
          {/*<AiAssistToggle useAi={useAi} setUseAi={setUseAi} t={t}/>*/}
          <AiAssistantInputFields
            writingStyle={writingStyle}
            setWritingStyle={setWritingStyle}
            errors={errors}
            useAi={useAi}
            aiBrief={aiBrief}
            setAiBrief={setAiBrief}
            t={t}
          />
          <DescriptionSection
            setDescription={setDescription}
            description={description}
            t={t}
            errors={errors}
            triggerKey={triggerKey}
            setTyping={setTyping}
            typing={typing}
            setAiTextComplete={setAiTextComplete}
          />
          <CategorySelect
            category={category}
            t={t}
            setCategoryModalState={setCategoryModalState}
            categoryError={errors.category} // Pass the categoryError state as a prop
          />
        </Row>
      </CardBody>
    </Card>
  );
}
