import React from "react";
import { Footer } from "../components/layout/Footer";
import BackButton from "../components/common/BackButton";
import { useLocation, useNavigate } from "react-router-dom";
import { Row } from "reactstrap";

export const NotFoundPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const umbracoPaths = [
    "/contact-us",
    "/about-us",
    "/investors",
    "/how-it-works",
    "/press",
    "/terms-of-service",
    "/guarantee",
    "/partners",
    "/blog",
    "/car-share",
    "/cancellation-policy",
    "/rental-earnings-calculator",
    "/requests",
  ];

  // useEffect(() => {
  //     if (umbracoPaths.some((path) => location.pathname.startsWith(path))) {
  //         window.location.reload();
  //     }
  // }, [location.pathname]);

  return (
    <div className="content-layout auto-margin not-found-page">
      <Row>
        <div className="back-button-alignment">
          <BackButton onClick={() => navigate(-1)} />
        </div>
        <h1>404 - We're looking, but we can't find this page!</h1>
      </Row>
      <img
        className="centered-img-search"
        src="/assets/img/no_items_found_big.webp"
        alt="no results image"
      />
      <Footer />
    </div>
  );
};
