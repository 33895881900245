import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {useLocation, useParams} from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import {
  ChosenPlace,
  setSearchTerm,
  setSelectedPlace,
  setSortByOption,
} from "../../../redux/searchSlice";
import { SingleValue } from "react-select";
import { ItemOrderBy } from "../../../api/rentMyApi";
import { OptionType } from "../../../pages/SearchResultsPage";
import { WhatWhereSearchBarMobile } from "../../search/WhatWhereSearchBarMobile";
import { WhatWhereSearchBarDesktop } from "../../search/WhatWhereSearchBarDesktop";
import PlaceResult = google.maps.places.PlaceResult;
import {parsePath} from "../../../utils/url";



export default function NavSearchBar() {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const sortByOptions: { value: ItemOrderBy; label: string }[] = [
    { value: ItemOrderBy.Relevance, label: t("search_results_sort_relevance") },
    {
      value: ItemOrderBy.DailyPrice,
      label: t("search_results_sort_daily_price"),
    },
    // {
    //   value: ItemOrderBy.DownDailyPrice,
    //   label: t("search_results_sort_down_daily_price"),
    // },
    { value: ItemOrderBy.Distance, label: t("search_results_sort_distance") },
    { value: ItemOrderBy.Rating, label: t("search_results_sort_rating") },
    { value: ItemOrderBy.Newest, label: t("search_results_sort_newest") },
  ];

  const { what, where } = parsePath(pathname);

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [localSearchTerm, setLocalSearchTerm] = useState<string>(what || "");
  const [localSelectedPlace, setLocalSelectedPlace] = useState<
    PlaceResult | undefined
  >({
    formatted_address: where,
  });
  const [localSortByOption, setLocalSortByOption] = useState<{
    value: ItemOrderBy;
    label: string;
  }>({
    value: ItemOrderBy.Relevance,
    label: t("search_results_sort_relevance"),
  });
  const isMobile = width <= 768;

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    let query = "all";
    if (pathSegments[1] !== "search") return;
    else query = pathSegments[2].replaceAll("%20", " ") || "all";
    setLocalSearchTerm(query);
    dispatch(setSearchTerm(query));
  }, []);

  useEffect(() => {
    const fetchExistingInputValue = async () => {
      const pathSegments = location.pathname.split("/");
      if (pathSegments.length > 3) {
        const address = pathSegments[3];
        await geocodeAddress(decodeURIComponent(address));
      }
    };
    fetchExistingInputValue();
  }, []);

  const geocodeAddress = async (address: string) => {
    try {
      console.log("Geocoding address:", address);
      const geocoder = new google.maps.Geocoder();
      const { results } = await geocoder.geocode({ address: address });
      if (results && results.length > 0) {
        const result = results[0];
        setLocalSelectedPlace(result);
        dispatch(
          setSelectedPlace({
            formatted_address: result.formatted_address,
            place_id: result.place_id,
            lat: result.geometry.location.lat(),
            lng: result.geometry.location.lng(),
          })
        );
        return;
      } else {
        return;
      }
    } catch (error) {
      console.error("Error geocoding address:", error);
      return;
    }
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--WhatWhereSearchMinWidth",
      isMobile ? "120px" : "160px"
    );
    document.documentElement.style.setProperty(
      "--WhatWhereSearchMaxWidth",
      isMobile ? "120px" : "160px"
    );
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleSortByChange = (selectedOption: SingleValue<OptionType>) => {
    const newOptionSelected: { value: ItemOrderBy; label: string } = {
      value: selectedOption!.value,
      label: selectedOption!.label,
    };
    setLocalSortByOption(newOptionSelected);
  };

  const handleSubmit = async () => {
    // console.log('handleSubmit called');
    // console.log(localSearchTerm);
    const searchTerm = localSearchTerm ? localSearchTerm : "all";
    let basePath = `${window.location.origin}/rent/${searchTerm}`;
    // Check if selectedPlace is valid before attempting to access its properties
    const newSelectedPlace: ChosenPlace = {
      formatted_address: undefined,
      place_id: undefined,
      lat: undefined,
      lng: undefined,
    };
    console.log(newSelectedPlace);
    if (
      localSelectedPlace && localSelectedPlace.formatted_address) {
      newSelectedPlace.place_id = localSelectedPlace.place_id;
      newSelectedPlace.formatted_address = localSelectedPlace.formatted_address;
      basePath += `/${localSelectedPlace.formatted_address}`;
      if (localSelectedPlace.geometry && localSelectedPlace.geometry.location) {
        const lat = localSelectedPlace.geometry.location.lat();
        const lng = localSelectedPlace.geometry.location.lng();
        if (lat && lng) {
          newSelectedPlace.lng = lng;
          newSelectedPlace.lat = lat;
          basePath += `?lat=${lat}&lng=${lng}`;
        }
      }

    }
    // console.log('dispatching');
    dispatch(setSearchTerm(searchTerm));
    dispatch(setSelectedPlace(newSelectedPlace));
    dispatch(setSortByOption(localSortByOption));
    window.history.pushState({}, "", basePath);
    if (pathname.split("/")[1] !== "search") window.location.reload();
  };

  if (pathname === "/") return null;

  return (
    <>
      {isMobile ? (
        <WhatWhereSearchBarMobile
          handleSubmit={handleSubmit}
          localSelectedPlace={localSelectedPlace}
          setLocalSelectedPlace={setLocalSelectedPlace}
          localSearchTerm={localSearchTerm}
          setLocalSearchTerm={setLocalSearchTerm}
          localSortByOption={localSortByOption}
          handleSortByChange={handleSortByChange}
          sortByOptions={sortByOptions}
        />
      ) : (
        <WhatWhereSearchBarDesktop
          handleSubmit={handleSubmit}
          localSelectedPlace={localSelectedPlace}
          setLocalSelectedPlace={setLocalSelectedPlace}
          localSearchTerm={localSearchTerm}
          setLocalSearchTerm={setLocalSearchTerm}
        />
      )}
    </>
  );
}
