import React from "react";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import ModalWithImage from "../common/ModalWithImage";

interface SharersGuaranteeProps {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function SharersGuarantee({
  modalState,
  setModalState,
}: SharersGuaranteeProps) {
  const { t } = useTranslation();
  const sharersGuaranteeUrl = "https://www.rentmy.com/protectmy/";

  function body() {
    return (
      <>
        <div className="thumbs-up-section mt-3 mb-3">
          <div className="thumbs-up-wrapper mr-2">
            <FontAwesomeIcon icon={faThumbsUp} />
          </div>
          <p>{t("sharers_guarantee_one")}</p>
        </div>
        <div className="thumbs-up-section mb-3">
          <div className="thumbs-up-wrapper mr-2">
            <FontAwesomeIcon icon={faThumbsUp} />
          </div>
          <p>{t("sharers_guarantee_two")}</p>
        </div>
        <div className="thumbs-up-section">
          <div className="thumbs-up-wrapper mr-2">
            <FontAwesomeIcon icon={faThumbsUp} />
          </div>
          <p>{t("sharers_guarantee_three")}</p>
        </div>
      </>
    );
  }

  return (
    <ModalWithImage
      isOpen={modalState}
      onClose={() => setModalState(false)}
      isDefault={true}
      image={"assets/img/modal-placeholder-image.webp"}
      title={t("sharers_guarantee_title")}
      description={""}
      descriptionElement={body()}
      defaultButtonText={t("modal_close")}
      onDefaultButtonClick={() => setModalState(false)}
      primaryButtonText={""}
      onPrimaryButtonClick={() => undefined}
      urlOpenInNewTabButtonSetup={{
        text: t("find_out_more"),
        url: sharersGuaranteeUrl,
      }}
    />
  );
}
