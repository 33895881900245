import React from "react";
import NavbarDesktop from "./NavbarDesktop";
import NavbarMobile from "./NavbarMobile";

interface NavbarProps {
  hamburgerMenuOpen: boolean;
  setHamburgerMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isMobile: boolean;
  isSmallScreen: boolean;
}

const Navbar: React.FC<NavbarProps> = ({
  hamburgerMenuOpen,
  setHamburgerMenuOpen,
  isMobile,
  isSmallScreen,
}) => {
  return (
    <>
      {isMobile ? (
        <NavbarMobile
          hamburgerMenuOpen={hamburgerMenuOpen}
          setHamburgerMenuOpen={setHamburgerMenuOpen}
        />
      ) : (
        <NavbarDesktop
          hamburgerMenuOpen={hamburgerMenuOpen}
          setHamburgerMenuOpen={setHamburgerMenuOpen}
          isSmallScreen={isSmallScreen}
        />
      )}
    </>
  );
};

export default Navbar;
