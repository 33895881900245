import { faImages } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useState } from "react";
import { Col, Row } from "reactstrap";
import ImageCarouselModal from "./ImageCarouselModal";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

interface ImageGridProps {
  imageSrcs: string[];
}
export default function ImageGrid(props: ImageGridProps) {
  const numberOfImages: number = props.imageSrcs?.length ?? 0;
  const maxNumberOfVisibleImgs = 3;

  const [modalState, setModalState] = useState(false);

  const toggleModal = () => {
    setModalState(!modalState);
  };

  return (
    <>
      <ImageCarouselModal
        imageSrcs={props.imageSrcs}
        toggleModal={toggleModal}
        modalState={modalState}
      />

      <Row className="image-grid">
        {numberOfImages > 1 && (
          <div className="image-see-all" onClick={toggleModal}>
            <FontAwesomeIcon icon={faImages} /> + {numberOfImages}
          </div>
        )}
        {numberOfImages === 0 && (
          <Zoom zoomMargin={20}>
            <img
              className="single-image"
              src="/assets/img/placeholder.jpg"
              alt="placeholder"
            />
          </Zoom>
        )}
        {numberOfImages === 1 && (
          <Col md={12}>
            <Zoom zoomMargin={20}>
              <img
                className="single-image"
                src={props.imageSrcs[0]}
                alt="single image"
              />
            </Zoom>
          </Col>
        )}
        {numberOfImages > 1 && (
          <>
            <Col md={9}>
              <img
                className="single-image"
                src={props.imageSrcs[0]}
                onClick={toggleModal}
                alt="multiple image"
              />
            </Col>
            <Col md={3} className="many-imgs">
              {props.imageSrcs
                .slice(0, maxNumberOfVisibleImgs)
                .map((imageSrc, index) => {
                  return (
                    <img
                      key={"image_" + index}
                      src={imageSrc}
                      onClick={toggleModal}
                      alt="image grid"
                    />
                  );
                })}
            </Col>
          </>
        )}
      </Row>
    </>
  );
}
