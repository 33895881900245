import { getStandardPricePeriod, STANDARD_PRICE_PERIOD_NAME } from "../components/item/ItemListingConstantsAndDefaults";
import { DayPrice, PriceInfo, PricePeriod } from "../api/rentMyApi";
import { add, format } from "date-fns";
import cloneDeep from "lodash/cloneDeep";
import { isDateBetweenTwoDates } from "../components/common/DateFormatting";

export const defaultServiceFeePercentageDecimal: number = 0.2;

interface FrontEndDayPrice {
  date: string;
  price: number;
  when: Date;
  serviceFee: number;
}

const parseDate = (dateString: string): Date => {
  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6, 8);

  return new Date(`${year}-${month}-${day}`);
}

const roundNumber = (num: number): number => {
  return Math.round(num * 100) / 100;
};

export const getStandardPricePeriodPriceWithServiceFeeApplied = (pricePeriods: PricePeriod[], itemName: string): PricePeriod | undefined => {
  let dailyStandardPricePeriod: PricePeriod | undefined = getStandardPricePeriod(pricePeriods);

  if (!dailyStandardPricePeriod || !dailyStandardPricePeriod.price || !dailyStandardPricePeriod.price.daily) {
    // console.error("no 'standard' price period with daily price for item with name: " + itemName);
    return;
  }

  let dailyPriceServiceFee: number | undefined = dailyStandardPricePeriod?.price?.dailyServiceFee;

  if (!dailyStandardPricePeriod.price.dailyServiceFee) {
      // console.error("ItemHighlight component, dailyServiceFee not set on 'standard' price period, so " + (defaultServiceFeePercentageDecimal * 100) + "% being applied by default");
      dailyPriceServiceFee = dailyStandardPricePeriod!.price!.dailyServiceFee! || 0;
      //dailyPriceServiceFee = dailyStandardPricePeriod!.price!.daily! * defaultServiceFeePercentageDecimal;
  }

  let copy: PricePeriod = cloneDeep(dailyStandardPricePeriod);
  copy.price.daily = (copy.price!.daily! + dailyPriceServiceFee!);
  return copy;
};

export const getPriceDataFromPriceInfo = (priceInfo: PriceInfo) => {
  //const maxDateYearsFromNow = 2;

  const dayPrices: FrontEndDayPrice[] = [];
  const disabledDates: Date[] = [];

  const today = new Date();

  Object.keys(priceInfo.days! || []).forEach((key) => {
    let day: DayPrice = priceInfo!.days![key];
    let date = parseDate(key);

    if (day && date <= today) {

      if (day.daily) dayPrices.push({
        date: key,
        price: roundNumber(day.daily + day.dailyServiceFee! || 0),
        serviceFee: day.dailyServiceFee! || 0,
        when: date
      });

      else disabledDates.push(date);
    }
  });

  let standardPricePeriod = priceInfo.periods![0];
  let discounts = standardPricePeriod?.dailyMultiBookings || []; // limitation - can only show multi booking discounts for a single price period
  let deposit = standardPricePeriod?.price.deposit || 0;

  return ({
    allDayPrices: dayPrices,
    discounts: discounts,
    disabledDates: disabledDates,
    deposit: deposit
  });

  // OLD WAY - Keeping for posterity for the time being, in case there's any weird side effects

  ////const standardPricePeriod = pricePeriods.find(({ name }) => name === STANDARD_PRICE_PERIOD_NAME);
  //const standardPricePeriod = pricePeriods[0];
  ////DEVNOTE: At the moment, we are treating the "dailyServiceFee" in the "standard" price periods as the default service fee.
  //// This feild is found in "days" and other price periods but these are ignored for now
  //// at the moment you can only define one serivce charge fee that are applied to all days.
  //const deposit = standardPricePeriod?.price.deposit || 0;

  

  //for (let index = 0; index < maxDateYearsFromNow * 365; index++) {
  //  const focusedDate = add(today, { days: index });
  //  const dateStr = format(focusedDate, "yyyy-MM-dd");
  //  const dateDayOfWeek = format(focusedDate, "EEEE");

  //  // if not in date range of standard than it is not possible (so if no availability in april but a special price period has april as part of it.)
  //  const isAvailableInStandardPricePeriod = standardPricePeriod?.dateRanges?.some(({
  //                                                                                    start,
  //                                                                                    end
  //                                                                                  }) => isDateBetweenTwoDates(focusedDate, start, end));

  //  if (!isAvailableInStandardPricePeriod) {
  //    disabledDates.push(focusedDate);
  //    continue;
  //  }

  //  // check if there is a special price period, if so then use that.
  //  const specialPricePeriodThatIsApplicable = pricePeriods
  //    .filter(period =>
  //      //period.name !== STANDARD_PRICE_PERIOD_NAME &&
  //      period.dateRanges?.some(({ start, end }) => isDateBetweenTwoDates(focusedDate, start, end))
  //    )[0];

  //  let applicablePricePeriod: PricePeriod;
  //  if (specialPricePeriodThatIsApplicable) {
  //    applicablePricePeriod = specialPricePeriodThatIsApplicable;
  //  } else {
  //    applicablePricePeriod = standardPricePeriod!;
  //  }

  //  const overrideForCurrentDay = applicablePricePeriod.dayOverrides?.find(({ day }) => day === dateDayOfWeek);

  //  let price = 0;
  //  if (overrideForCurrentDay) {
  //    price = overrideForCurrentDay.price.daily || 0;
  //  } else {
  //    price = applicablePricePeriod.price.daily || 0;
  //  }

  //    const serviceFee = applicablePricePeriod.price.dailyServiceFee! || 0;

  //  dayPrices.push({
  //    date: dateStr,
  //    price: roundNumber(price + serviceFee),
  //    serviceFee,
  //    when: focusedDate
  //  });
  //}

  //return ({
  //  allDayPrices: dayPrices,
  //  discounts: standardPricePeriod?.dailyMultiBookings || [],
  //  disabledDates,
  //  deposit
  //});
};