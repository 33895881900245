import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardImg,
  CardText,
  Col,
  Container,
  Row,
} from "reactstrap";
import KebabMenu from "./Kebab";
import {
  faBan,
  faCircleCheck,
  faCreditCard,
  faFlag,
  faInfoCircle,
  faMessage,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import {
  differenceInCalendarDays,
  format,
  isAfter,
  isBefore,
  isSameMonth,
} from "date-fns";
import {
  BookingClient,
  BookingDetailedDto,
  BookingSimpleDto,
  BookingStatus,
  HandOverCreateDto,
  PaymentStatus,
  StripeCheckoutRedirects,
} from "../../api/rentMyApi";
import { useEnv } from "../../context/env.context";
import {
  gotoMessagesFromBookingDetailsPage,
  messageUrlConst,
} from "../common/MessageUrlFormatting";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface CardComponentProps {
  booking: BookingSimpleDto;
  isSharerView: boolean;
  bookingClient: BookingClient;
  onBookingDetailsClick: (x: string, y: string, z?: boolean) => void;
  openHandoverModal: (x: string) => void;
  handleCancellationRequest: (
    bookingId: string,
    title?: string,
    buttonText?: string
  ) => void;
}

interface CardComponentProps {
  booking: BookingSimpleDto;
  isSharerView: boolean;
  bookingClient: BookingClient;
  onBookingDetailsClick: (x: string, y: string, z?: boolean) => void;
  openHandoverModal: (x: string) => void;
  handleCancellationRequest: (
    bookingId: string,
    title?: string,
    buttonText?: string
  ) => void;
}

export const CustomBookingCard = ({
  booking,
  isSharerView,
  bookingClient,
  onBookingDetailsClick,
  openHandoverModal,
  handleCancellationRequest,
}: CardComponentProps) => {
  const { apiServerUrl } = useEnv();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const { item, bookingStatus, startDate, endDate, sharer, renter } = booking;
  const { name = "", images = [] } = item;
  const image = images[0];
  const src = image?.compressedPath
    ? image.compressedPath
    : "/assets/img/placeholder.jpg";

  const profileSource = (isSharerView ? renter : sharer)?.image
    ? (isSharerView ? renter : sharer).image.compressedPath
    : "/assets/img/profile-pic-placeholder.webp";

  const startOfBooking = new Date(startDate || "");
  const endOfBooking = new Date(endDate || "");
  const sameMonth = isSameMonth(startOfBooking, endOfBooking);
  const startDateFormat = sameMonth ? "dd" : "dd MMM";
  const endDateFormat = sameMonth ? "dd MMM, yy" : "dd MMM, yy";

  const isCompleted = bookingStatus === BookingStatus["Completed"];
  const wasCancelled = bookingStatus === BookingStatus["Cancelled"];

  const requiredPayment =
    booking.paymentStatus === PaymentStatus.Failed ||
    booking.paymentStatus === PaymentStatus.Unauthorised;

  const dateStr = `${format(startOfBooking, startDateFormat)} to ${format(
    endOfBooking,
    endDateFormat
  )}`;

  const handleMessageSharerClick = () => {
    navigate(
      `${messageUrlConst}/${gotoMessagesFromBookingDetailsPage(
        booking.item.name!,
        booking.id,
        booking.startDate!,
        booking.endDate!
      )}/${isSharerView ? booking.renter.id : booking.sharer.id}`
    );
  };

  const handleStatusChange = async (
    status,
    imageId?
  ): Promise<BookingDetailedDto> => {
    if (imageId) {
      await bookingClient.addImages(
        booking.id,
        [imageId],
        status === "Ongoing"
      );
    }

    const handOverCreateDto = new HandOverCreateDto({
      location: {
        name: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        city: "",
        country: "",
        county: "",
        postcode: "",
        latitude: 0,
        longitude: 0,
      },
      time: new Date(),
    });

    const response = await bookingClient.updateStatus(
      booking.id,
      BookingStatus[status],
      undefined,
      undefined,
      handOverCreateDto,
      undefined
    );
    console.log(response);
    return response;
  };

  //TODO Need to add reload and confirmation here
  const updateBookingStatus = async (status) => {
    try {
      setIsLoading(true);
      await handleStatusChange(status);
      toast.success("Booking updated");
      window.location.reload();
      setIsLoading(false);
    } catch (error) {
      toast.error("Something went wrong. Try again or contact support.");
      setIsLoading(true);
    }
  };

  const bookingPeriodHasStarted = isBefore(startOfBooking, new Date());
  const bookingPeriodHasEnded = isAfter(new Date(), endOfBooking);
  const bookingWasCancelled = bookingStatus === BookingStatus["Cancelled"];

  const bookingIsRequested = bookingStatus === BookingStatus["Request"];
  const bookingIsOngoing = bookingStatus === BookingStatus["Ongoing"];
  const bookingIsCompleted = bookingStatus === BookingStatus["Completed"];
  const bookingIsAccepted = bookingStatus === BookingStatus["BookingAccepted"];
  const bookingCanBeAccepted = bookingIsRequested && isSharerView;

  const canLeaveReview =
    !isSharerView && bookingIsCompleted && !booking.isReviewed;

  const canPay =
    !isSharerView &&
    !bookingWasCancelled &&
    !bookingIsRequested &&
    (booking.paymentStatus === PaymentStatus.Failed ||
      booking.paymentStatus === PaymentStatus.Unauthorised);

  const canMakeClaim = !!booking.insuranceBreakdown && !booking.hasClaim;

  const handleSharerInfoClick = (e) => {
    e.stopPropagation();
    navigate("/public/profile?id=" + (isSharerView ? renter : sharer)?.id);
  };

  const payForItem = () => {
    const rentalLocationUrl = "/account/my-rentals";
    const successResponse =
      window.location.origin + rentalLocationUrl + "?id=" + booking.id;
    const cancelResponse = window.location.href;
    const stripeCheckoutRedirects = new StripeCheckoutRedirects({
      cancel: cancelResponse,
      success: successResponse,
    });
    bookingClient
      .checkout(booking.id, undefined, stripeCheckoutRedirects)
      .then((response) => {
        window.location.href = response;
        setIsLoading(false);
      })
      .catch(async () => {
        setIsLoading(false);
        toast.error(
          "There was an error making this booking, please contact support"
        );
      });
  };

  const setBookingStatus = () => {
    const bookingCanBeAccepted = bookingIsRequested && isSharerView;

    if (bookingWasCancelled) return "Cancelled";

    if (bookingIsRequested) {
      if (isSharerView) {
        return "Accept or reject this request";
      } else {
        return "Awaiting response from sharer";
      }
    }

    if (
      booking.paymentStatus === PaymentStatus.Failed ||
      booking.paymentStatus === PaymentStatus.Unauthorised
    ) {
      if (isSharerView) {
        return "Awaiting payment";
      } else {
        return "Payment required";
      }
    }

    if (bookingIsCompleted) return "Completed";

    if (bookingCanBeAccepted) {
      if (isSharerView) {
        return "Accept or reject";
      } else {
        return "Awaiting response from sharer";
      }
    }

    if (bookingIsAccepted) {
      if (!bookingPeriodHasStarted) {
        const daysBeforeStart = differenceInCalendarDays(
          booking.startDate!,
          new Date()
        );
        if (daysBeforeStart)
          return `Starting in ${daysBeforeStart} day${
            daysBeforeStart > 1 ? "s" : ""
          }`;
      } else {
        if (isSharerView) {
          return "Awaiting pick-up.";
        } else {
          return "Pick-up required";
        }
      }
    }
    if (bookingIsOngoing) {
      const daysBeforeEnd = differenceInCalendarDays(
        booking.endDate!,
        new Date()
      );
      if (bookingPeriodHasEnded) {
        if (daysBeforeEnd === 0) {
          if (isSharerView) {
            return "Return required";
          } else {
            return "Return required";
          }
        } else {
          if (isSharerView) {
            return "Return required";
          } else {
            return "Awaiting return confirmation";
          }
        }
      } else {
        if (daysBeforeEnd === 0) {
          return "Ends today";
        } else {
          return `Ends in ${daysBeforeEnd} day${daysBeforeEnd > 1 ? "s" : ""}`;
        }
      }
    }
  };

  const bookingStatusString = setBookingStatus();

  const canPickUpItem = bookingStatusString === "Pick-up required";
  const canConfirmDropoff = isSharerView && bookingIsOngoing;

  const enableInsurance =
    process.env.REACT_APP_SHOW_INSURANCE &&
    /true/.test(process.env.REACT_APP_SHOW_INSURANCE);

  // "review"
  const menuItems = [
    {
      label: "View item",
      onClick: () => navigate("/item/" + booking.item.url),
      icon: faInfoCircle,
      notification: false,
      visible: true,
    },
    {
      label: "Leave a review",
      onClick: () => onBookingDetailsClick(booking.id, "review"),
      icon: faStar,
      notification: true,
      visible: canLeaveReview || false,
    },
    {
      label: "Pay",
      onClick: () => payForItem(),
      icon: faCreditCard,
      notification: true,
      visible: canPay || false,
    },
    {
      label: "Accept booking",
      onClick: () => updateBookingStatus("BookingAccepted"),
      icon: faCircleCheck,
      notification: true,
      visible: bookingCanBeAccepted || false,
    },
    {
      label: "Reject booking",
      onClick: () =>
        handleCancellationRequest(
          booking.id,
          "Confirm Booking Rejection",
          "Reject"
        ),
      icon: faBan,
      notification: true,
      visible: bookingCanBeAccepted || false,
    },
    {
      label: "Confirm pick-up",
      onClick: () => openHandoverModal(booking.id),
      icon: faCircleCheck,
      notification: true,
      visible: canPickUpItem || false,
    },
    {
      label: "Confirm drop-off",
      onClick: () => openHandoverModal(booking.id),
      icon: faCircleCheck,
      notification: true,
      visible: canConfirmDropoff || false,
    },
    {
      label: "Message user",
      onClick: () => handleMessageSharerClick(),
      icon: faMessage,
      notification: false,
      visible: true,
    },
    {
      label: "Cancel booking",
      onClick: () => handleCancellationRequest(booking.id),
      icon: faBan,
      notification: false,
      visible: bookingIsAccepted || false,
    },
    {
      label: "Cancel request",
      onClick: () =>
        handleCancellationRequest(
          booking.id,
          "Confirm Request Cancellation",
          "Confirm"
        ),
      icon: faBan,
      notification: false,
      visible: (!isSharerView && bookingIsRequested) || false,
    },
    {
      label: "Report an issue",
      onClick: () =>
        (window.location.href = "https://zen.rentmy.com/hc/en-gb/requests/new"),
      icon: faFlag,
      notification: false,
      visible: !enableInsurance || !canMakeClaim || false,
    },
    {
      label: "Make a claim",
      onClick: () => {
        navigate(`/insurance-claim/${booking.id}`);
      },
      icon: faFlag,
      notification: false,
      visible:
        (enableInsurance &&
          (bookingIsCompleted || bookingPeriodHasEnded) &&
          canMakeClaim &&
          isSharerView) ||
        false,
    },
    // {
    //     label: "Insurance Docs",
    //     onClick: () => {
    //         navigate("/insurance");
    //     },
    //     icon: faFileLines,
    //     notification: false,
    //     visible: (booking.paymentStatus === PaymentStatus["Authorised"] && booking.insuranceBreakdown && isSharerView) || false
    // }
  ];
  const bookingObject = {
    date: dateStr,
    itemName: name,
    profileImageSrc: profileSource,
    profileName: (isSharerView ? renter : sharer)?.name,
    status: bookingStatus,
    itemImage: src,
  };

  return (
    <Card className="booking-status-card">
      <CardBody>
        <div className="kebab-container">
          <KebabMenu menuItems={menuItems} id={booking.id} />
        </div>
        <Container
          fluid
          className={"booking-status-container clickable"}
          onClick={() => onBookingDetailsClick(booking.id, "booking")}
        >
          <Row>
            <Col xs="4">
              <CardImg
                className="booking-item-image"
                src={bookingObject.itemImage}
                alt="Item image"
              />
            </Col>
            <Col xs="8" className="padding-left-25">
              <Row>
                <CardText className="date-range">{bookingObject.date}</CardText>
              </Row>
              <Row>
                <CardText className="item-name">
                  {bookingObject.itemName}
                </CardText>
              </Row>
              <Row>
                <div
                  className="sharer-info clickable "
                  onClick={handleSharerInfoClick}
                >
                  <CardImg
                    className="booking-sharer-image"
                    src={bookingObject.profileImageSrc}
                    alt="Sharer image"
                  />
                  <CardText className="sharer-name ">
                    {bookingObject.profileName}
                  </CardText>
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </CardBody>
      <CardText className="booking-status-banner">
        {bookingStatusString}
      </CardText>
    </Card>
  );
};
