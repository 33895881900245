import React, { useEffect, useRef, useState } from "react";
import { Input } from "reactstrap";
import { useTranslation } from "react-i18next";

interface TypewriterProps {
  text?: string;
  typingSpeed?: number;
  onTextChange: (value: string) => void;
  errors: any;
  typing: boolean;
  setTyping: (value: boolean) => void;
  setTypingComplete: (value: boolean) => void;
  description: string;
  resetTyping: boolean;
}

const Typewriter: React.FC<TypewriterProps> = ({
  text = "",
  typingSpeed = 40,
  onTextChange,
  errors,
  typing,
  setTyping,
  setTypingComplete,
  description,
  resetTyping,
}) => {
  const { t } = useTranslation();
  const [displayText, setDisplayText] = useState(description);
  const [startedTying, setStartedTyping] = useState(false);
  const currentCharIndex = useRef(0);
  const typingTimeoutId = useRef<number | null>(null);
  const inputRef = useRef<any>(null);

  const resetTypingEffect = () => {
    setDisplayText("");
    currentCharIndex.current = 0;
    setTyping(true);
  };

  // useEffect(() => {
  //     if (resetTyping) {
  //         resetTypingEffect();
  //     }
  // }, [resetTyping]);

  useEffect(() => {
    setDisplayText(description);
  }, [description]);

  const resizeTextArea = () => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    if (typing && currentCharIndex.current < text.length) {
      setTypingComplete(false);
      setStartedTyping(true);
      typingTimeoutId.current = window.setTimeout(() => {
        const newText = displayText + text[currentCharIndex.current];
        setDisplayText(newText);
        if (!typing) {
          onTextChange(newText);
        }
        currentCharIndex.current += 1;
      }, typingSpeed);
    } else {
      setTyping(false);
      if (startedTying) setTypingComplete(true);
      // console.log("STOPPED TYPING")
    }

    resizeTextArea();

    return () => {
      if (typingTimeoutId.current !== null) {
        clearTimeout(typingTimeoutId.current);
      }
    };
  }, [typing, displayText, text, typingSpeed, onTextChange, setTyping]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!typing) {
      onTextChange(e.target.value);
      setDisplayText(e.target.value);
      resizeTextArea();
    }
  };

  return (
    <Input
      className="typewriter input-field"
      id="item-listing-description"
      invalid={errors.description}
      placeholder={t("item_listing_describe_placeholder")}
      value={displayText}
      onChange={handleChange}
      rows="5"
      type="textarea"
      required
      disabled={typing}
      innerRef={inputRef}
    />
  );
};

export default Typewriter;
